import React, { useContext, useEffect, useState } from "react";
import { alpha, makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import FormatPaintOutlinedIcon from "@material-ui/icons/FormatPaintOutlined";
import BookOutlinedIcon from "@material-ui/icons/BookOutlined";
import LocalHospitalOutlinedIcon from "@material-ui/icons/LocalHospitalOutlined";
import RateReviewOutlinedIcon from "@material-ui/icons/RateReviewOutlined";
import ScheduleOutlinedIcon from "@material-ui/icons/ScheduleOutlined";
import PaymentOutlinedIcon from "@material-ui/icons/PaymentOutlined";
import PageviewOutlinedIcon from "@material-ui/icons/PageviewOutlined";
import DomainOutlinedIcon from "@material-ui/icons/DomainOutlined";
import HomeIcon from "@material-ui/icons/Home";
import FormatPaintIcon from "@material-ui/icons/FormatPaint";
import BookIcon from "@material-ui/icons/Book";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import RateReviewIcon from "@material-ui/icons/RateReview";
import PaymentIcon from "@material-ui/icons/Payment";
import PageviewIcon from "@material-ui/icons/Pageview";
import DomainIcon from "@material-ui/icons/Domain";
import WatchLaterIcon from "@material-ui/icons/WatchLater";

import Header from "./Header";
import {
  API_ENDPOINT_ENTERPRISE,
  API_RESPONSE_UNAUTHORIZED_STATUS,
  API_SECTION_API,
  API_VERSION,
  DOCFYN_API_DOMAIN,
  GRAY_THREE,
  LIGHT_BLUE_COLOR,
  THEME_PRIMARY_COLOR,
  THEME_WEBSITE_BG_COLOR,
  THEME_WEBSITE_SECONDARY_BG_COLOR,
} from "../constants";
import {
  AppContext,
  ControlPanelContext,
  devConsoleLog,
  isEmptyObject,
} from "../utility";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, CircularProgress, Link, Typography } from "@material-ui/core";
import EnterpriseDetails from "./Commons/EnterpriseDetails";
import axios from "axios";
import ChangeEnterprise from "./Commons/ChangeEnterprise";
import useLocalStorage from "../hooks/useLocalStorage";

export const drawerWidth = 240;

const drawerListItems = [
  {
    name: "Home",
    icon: <HomeOutlinedIcon />,
    link: "/home",
    selectedIcon: <HomeIcon />,
    listName: "home",
  },
  {
    name: "Doctors",
    icon: <LocalHospitalOutlinedIcon />,
    link: "/doctors",
    selectedIcon: <LocalHospitalIcon />,
    listName: "doctors",
  },
  {
    name: "Appearance",
    icon: <FormatPaintOutlinedIcon />,
    link: "/appearance/themes",
    listName: "appearance",
    selectedIcon: <FormatPaintIcon />,
    subList: [
      {
        name: "Themes",
        link: "/appearance/themes",
        subListName: "themes",
      },
      {
        name: "Design",
        link: "/appearance/design",
        subListName: "design",
      },
      {
        name: "Gallery",
        link: "/appearance/gallery",
        subListName: "gallery",
      },
      {
        name: "Services",
        link: "/appearance/services",
        subListName: "services",
      },
      {
        name: "About us",
        link: "/appearance/about-us",
        subListName: "about-us",
      },
      {
        name: "Contact us",
        link: "/appearance/contact-us",
        subListName: "contact-us",
      },
      {
        name: "Faqs",
        link: "/appearance/faqs",
        subListName: "faqs",
      },
    ],
  },
  {
    name: "Blogs",
    icon: <BookOutlinedIcon />,
    link: "/blogs",
    selectedIcon: <BookIcon />,
    listName: "blogs",
  },
  {
    name: "Reviews",
    icon: <RateReviewOutlinedIcon />,
    link: "/reviews",
    selectedIcon: <RateReviewIcon />,
    listName: "reviews",
  },
  {
    name: "Appointments",
    icon: <ScheduleOutlinedIcon />,
    link: "/appointments",
    selectedIcon: <WatchLaterIcon />,
    listName: "appointments",
  },
  {
    name: "Payments",
    icon: <PaymentOutlinedIcon />,
    link: "/payments/coming-soon",
    selectedIcon: <PaymentIcon />,
    listName: "payments",
  },
  {
    name: "SEO",
    icon: <PageviewOutlinedIcon />,
    link: "/seo/coming-soon",
    selectedIcon: <PageviewIcon />,
    listName: "seo",
  },
  {
    name: "Marketplace",
    icon: <DomainOutlinedIcon />,
    link: "/marketplace",
    selectedIcon: <DomainIcon />,
    listName: "marketplace",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    backgroundColor: THEME_PRIMARY_COLOR,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: THEME_PRIMARY_COLOR,
    color: THEME_WEBSITE_BG_COLOR,
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: THEME_WEBSITE_SECONDARY_BG_COLOR,
    padding: "3rem",
    minHeight: "100vh",
    [theme.breakpoints.down("xs")]: {
      padding: "8px 1rem",
    },
  },
  mainContent: {
    backgroundColor: THEME_WEBSITE_SECONDARY_BG_COLOR,
  },
  enterpriseSelectionLayout: {
    backgroundColor: THEME_PRIMARY_COLOR,
    height: "100vh",
    padding: "200px 16px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    position: "relative",
  },
  enterpriseDialogList: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    marginTop: "8px",
    boxShadow: "0 2px 6px #1a181e0a",
  },
  enterpriseDialogListItem: {
    backgroundColor: alpha(THEME_WEBSITE_SECONDARY_BG_COLOR, 1),
    borderRadius: "4px",
    padding: "8px 4px",
    marginRight: 0,
    transition: "all .1s",
    "&:hover": {
      backgroundColor: alpha(LIGHT_BLUE_COLOR, 1),
      // color: "white",
    },
  },
  enterpriseDialogSelectedListItem: {
    backgroundColor: alpha(LIGHT_BLUE_COLOR, 1),
  },
  drawerListItem: {
    gap: "12px",
    borderRadius: "4px",
    padding: "6px 16px",
  },
}));

export const DrawerList = ({ anchorEl, setAnchorEl }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { mobileView } = useContext(AppContext);
  const pathnameArr = window.location.pathname.split("/");
  const listSelected = pathnameArr[1];
  const subListSelected = pathnameArr[2];

  const handleNavigation = (event, link) => {
    event.preventDefault();
    if (anchorEl) setAnchorEl(false);
    navigate(link);
  };

  return (
    <List
      style={{
        padding: "16px 8px",
        color: GRAY_THREE,
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }}
    >
      {drawerListItems.map((item, index) => {
        const {
          name = "",
          icon,
          link = "/home",
          subList = [],
          selectedIcon,
          listName = "",
        } = item || {};
        const isListSelected = listSelected === listName;
        return (
          <>
            <ListItem
              button
              key={name}
              // disabled={disabled}
              className={classes.drawerListItem}
              style={{
                backgroundColor: isListSelected ? "rgba(255,255,255,.1)" : "",
              }}
              onClick={(e) => handleNavigation(e, link)}
            >
              <ListItemIcon
                style={{ minWidth: "fit-content", color: GRAY_THREE }}
              >
                {isListSelected ? selectedIcon : icon}
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={name}
                style={{
                  fontSize: "15px",
                  fontWeight: "400",
                  marginRight: "8px",
                }}
              />
            </ListItem>
            {subList.length > 0 && isListSelected && (
              <Box
                style={{
                  padding: "4px 16px",
                  paddingLeft: "52px",
                  fontSize: "14px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "14px",
                }}
              >
                {subList.map((subItem, index) => {
                  const {
                    name = "",
                    link = "",
                    subListName = "",
                  } = subItem || {};
                  const isSubListSelected = subListSelected === subListName;
                  return (
                    <Link
                      key={name}
                      href={link}
                      style={{
                        color: isSubListSelected ? "#FFFFFF" : GRAY_THREE,
                        fontWeight: isSubListSelected ? "500" : "400",
                        textDecoration: isSubListSelected
                          ? "underline"
                          : "none",
                      }}
                      onClick={(event) => handleNavigation(event, link)}
                    >
                      {name || ""}
                    </Link>
                  );
                })}
              </Box>
            )}
          </>
        );
      })}
    </List>
  );
};

const ControlPanelLayout = ({ children }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { state = {} } = useLocation();
  const [userData, setUserData] = useLocalStorage("user", {});
  const { enterprise = {}, auth_tokens = {} } = userData || {};
  const isEnterprise = !isEmptyObject(enterprise);
  const { inviteCode = null, enterpriseCode = null } = state || {};
  const { handleRefreshToken, mobileView } = useContext(AppContext);
  const [enterprises, setEnterprises] = useState([]);
  const [isEnterpriseLoading, setIsEnterpriseLoading] = useState(false);
  const [headerHeading, setHeaderHeading] = useState("");
  const AuthorizationToken = `Bearer ${auth_tokens.AccessToken || ""}`;

  const contextValue = {
    setHeaderHeading,
    setEnterprises,
    isEnterprise,
    enterprise,
    userData,
    setUserData,
    AuthorizationToken,
  };

  const handleEnterpriseForInvitedUser = (enterpriseList = []) => {
    const inviteeEnterprise = enterpriseList.find((item) => {
      const { enterprise = {} } = item || {};
      const { code = null } = enterprise || {};
      return code === enterpriseCode;
    });
    setUserData({ ...userData, enterprise: inviteeEnterprise });
    // const flag = LocalStorage.setUser({
    //   ...userData,
    //   enterprise: inviteeEnterprise,
    // });
    // if (flag) {
    //   window.location.href = "/home";
    // }
  };

  const getEnterpriseList = async () => {
    setIsEnterpriseLoading(true);
    const url = `${DOCFYN_API_DOMAIN}${API_SECTION_API}${API_VERSION}/${API_ENDPOINT_ENTERPRISE}`;
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          source: "website",
          Authorization: AuthorizationToken,
        },
      });
      const { data = {}, error } = response || {};
      const { result = {} } = data || {};
      const { enterprises = [] } = result || {};
      setEnterprises(enterprises);
      if (inviteCode) {
        handleEnterpriseForInvitedUser(enterprises);
      } else if (isEnterprise) {
        const { enterprise: currentEnterprise = {} } = enterprise || {};
        const { code: currentEnterpriseCode = null } = currentEnterprise || {};
        const filteredEnterprise = enterprises.filter((item) => {
          const { enterprise = {} } = item || {};
          const { code = null } = enterprise || {};
          return code === currentEnterpriseCode;
        })[0];
        setUserData({ ...userData, enterprise: filteredEnterprise });
      }
    } catch (error) {
      const { response } = error || {};
      const { status = null } = response || {};
      if (status === API_RESPONSE_UNAUTHORIZED_STATUS) {
        handleRefreshToken();
      }
      // setViewSnackbarMain({
      //   message: "Something went wrong. Please try again !",
      //   type: "error",
      // });
    } finally {
      setIsEnterpriseLoading(false);
    }
  };

  const handleDefaultEnterpriseSelection = () => {
    setUserData({ ...userData, enterprise: enterprises[0] });
  };

  useEffect(() => {
    if (!isEnterprise && enterprises.length === 1)
      handleDefaultEnterpriseSelection();
  }, [enterprises]);

  useEffect(() => {
    getEnterpriseList();
  }, []);

  if (isEnterpriseLoading) {
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </div>
    );
  } else if (!isEnterprise && enterprises.length > 1)
    return (
      <EnterpriseDetails
        isEnterprise={isEnterprise}
        enterprises={enterprises}
      />
    );
  else
    return (
      <div className={classes.root}>
        <CssBaseline />
        {mobileView ? (
          <>
            <ControlPanelContext.Provider value={contextValue}>
              <Header headerHeading={headerHeading} enterprises={enterprises} />
              <main className={classes.content}>
                <div className={classes.toolbar} />
                <Typography variant="h5" style={{ margin: "16px 0" }}>
                  {headerHeading || ""}
                </Typography>
                {children}
              </main>
            </ControlPanelContext.Provider>
          </>
        ) : (
          <>
            <Header headerHeading={headerHeading} />
            <ControlPanelContext.Provider value={contextValue}>
              <Drawer
                variant="permanent"
                className={classes.drawer}
                classes={{
                  paper: classes.drawerPaper,
                }}
                anchor="left"
              >
                <div
                  className={classes.toolbar}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "16px",
                  }}
                >
                  <ChangeEnterprise enterprises={enterprises} />
                </div>
                <Divider />
                <DrawerList />
              </Drawer>
            </ControlPanelContext.Provider>
            <main className={classes.content}>
              <div className={classes.toolbar} />
              <ControlPanelContext.Provider value={contextValue}>
                {children}
              </ControlPanelContext.Provider>
            </main>
          </>
        )}
      </div>
    );
};

export default ControlPanelLayout;
