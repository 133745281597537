import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@material-ui/core";
import axios from "axios";

import MySnackBar from "./components/Commons/MySnackBar";
import Footer from "./components/Footer";
import NavBar from "./components/NavBar";
import {
  API_ENDPOINT_REFRESH,
  API_SECTION_API,
  API_SECTION_AUTHENTICATION,
  API_SECTION_PROFILE,
  API_SECTION_PUBLIC,
  API_VERSION,
  DOCFYN_API_DOMAIN,
  DOCFYN_AUTH_API_DOMAIN,
} from "./constants";
import LocalStorage from "./localStorage";
import RoutesComponent from "./routes";
import { AppContext } from "./utility";
import ScrollToTop from "./components/Commons/ScrollToTop";

const user = LocalStorage.getUser();
const { userInfo = {}, auth_tokens: authTokens = {}, enterprise = {} } = user || {};
const { entityCode = null } = userInfo || {};
const { RefreshToken = null } = authTokens || {};
const isLoggedIn = Boolean(user);

const Layout = () => {
  const navigate = useNavigate();
  const [userProfile, setUserProfile] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [viewSnackbar, setViewSnackbarMain] = useState({
    message: null,
    type: null,
  });
  const deskTopView = useMediaQuery((theme) => theme.breakpoints.up("lg"), {
    noSsr: true,
  });
  const mobileView = useMediaQuery((theme) => theme.breakpoints.down("sm"), {
    noSsr: true,
  });

  const getProfileData = async () => {
    if (!entityCode) return;
    setIsLoading(true);
    const url = `${DOCFYN_API_DOMAIN}${API_SECTION_API}${API_VERSION}/${API_SECTION_PUBLIC}${API_SECTION_PROFILE}${entityCode}`;
    try {
      const response = await axios.get(url);
      const { status = null, data = {} } = response || {};
      const { result = {} } = data || {};
      if (status >= 200 && status < 300) {
        setUserProfile({ ...result });
      }
    } catch (error) {
      setViewSnackbarMain({ message: "Something went wrong!", type: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const handleRefreshToken = async () => {
    if (!RefreshToken)
      navigate("/login", { state: { previousUrl: window.location.href } });
    
    // setViewSnackbarMain({ message: "Access Token Expired", type: "warning" });
    const url = `${DOCFYN_AUTH_API_DOMAIN}${API_SECTION_PUBLIC}${API_VERSION}/${API_SECTION_AUTHENTICATION}${API_ENDPOINT_REFRESH}`;
    const reqBody = {
      refresh_token: RefreshToken,
    };
    try {
      const response = await axios.post(url, reqBody, {
        headers: {
          "Content-Type": "application/json",
          source: "website",
        },
      });
      const { status = null, data = {} } = response || {};
      const { AccessToken = "" } = data || {};
      if (status >= 200 && status < 300) {
        const newAuthTokens = { ...authTokens, AccessToken: AccessToken };
        const flag = LocalStorage.setUser({
          ...user,
          auth_tokens: newAuthTokens,
        });
        if (flag) {
          window.location.reload();
        }
      }
    } catch (error) {
      const flag = LocalStorage.deleteUser();
      if (flag) window.location.href = "/login";
    }
  };

  // useEffect(() => {
  //   getProfileData();
  // }, []);

  const contextValue = {
    isLoggedIn,
    userProfile,
    setViewSnackbarMain,
    deskTopView,
    mobileView,
    handleRefreshToken,
    getProfileData,
  };

  return (
    <div style={{width: '100vw'}}>
      <ScrollToTop />
      <AppContext.Provider value={contextValue}>
        {/* <NavBar /> */}
        <RoutesComponent />
        {/* <Footer /> */}
        {Boolean(viewSnackbar.message) && (
          <MySnackBar
            variant={viewSnackbar.type}
            message={viewSnackbar.message}
            setViewSnackbar={setViewSnackbarMain}
          />
        )}
      </AppContext.Provider>
    </div>
  );
};

export default Layout;
