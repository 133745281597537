import React, { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { MuiOtpInput } from "mui-one-time-password-input";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import {
  API_ENDPOINT_VERIFY_OTP,
  API_SECTION_AUTHENTICATION,
  API_SECTION_PUBLIC,
  API_VERSION,
  DOCFYN_AUTH_API_DOMAIN,
  GRAY_ONE,
  THEME_WEBSITE_BG_COLOR,
} from "../../constants";
import { AppContext, devConsoleLog } from "../../utility";
import LocalStorage from "../../localStorage";
import { CircularProgress } from "@material-ui/core";
import useStyles from "./styles";

const VerifyOTP = ({loginDetails = {}, setIsVerifyOTP}) => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const { setViewSnackbarMain, deskTopView } = useContext(AppContext);
  const {
    session = null,
    dialCode = null,
    phone = null,
    inviteCode = null,
    enterpriseCode = null
  } = loginDetails || {};
  const [otp, setOtp] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleOtpChange = (newValue) => {
    setOtp(newValue);
  };

  const handleVerifyOtp = async () => {
    setIsLoading(true);
    const url = `${DOCFYN_AUTH_API_DOMAIN}${API_SECTION_PUBLIC}${API_VERSION}/${API_SECTION_AUTHENTICATION}${API_ENDPOINT_VERIFY_OTP}`;
    try {
      const reqBody = {
        dialCode,
        phone,
        otp,
        session,
      };
      if (inviteCode && enterpriseCode) {
        reqBody['inviteCode'] = inviteCode;
        reqBody['enterpriseCode'] = enterpriseCode;
      }
      const response = await axios.post(url, reqBody, {
        headers: {
          "Content-Type": "application/json",
          source: "website",
        },
      });
      const { status = null, data = {} } = response || {};
      const {
        AccessToken = null,
        RefreshToken = null,
        EntityCode = null,
      } = data || {};
      if (status >= 200 && status < 300) {
        const data = {
          auth_tokens: { AccessToken, RefreshToken },
          userInfo: { entityCode: EntityCode },
        };
        const flag = LocalStorage.setUser(data);
        if (flag) {
          setViewSnackbarMain({
            message: "Successfully logged in!",
            type: "success",
          });
          navigate("/home", { state: {inviteCode, enterpriseCode}, replace: true })
        }
      }
    } catch (err) {
      setViewSnackbarMain({ message: "Something went wrong!", type: "error" });
      setIsVerifyOTP((prev) => ({...prev, 'open': false}));

    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box className={classes.loginSignUpBox}>
      {/* <IconButton
        style={{ width: "fit-content", position: "absolute" }}
        onClick={() => navigate("/login")}
      >
        <KeyboardBackspaceIcon style={{ fontSize: "2rem" }} />
      </IconButton> */}
      <Box style={{ display: "flex", justifyContent: "center" }}>
        <img alt="logo" src="/logo.svg" style={{ width: "8rem" }} />
      </Box>
      <Box style={{ display: "flex", flexDirection: "column", gap: "60px" }}>
        <Box>
          <Typography
            align="center"
            variant="h6"
            className={classes.loginSignUpHeading}
          >
            Verification
          </Typography>
          <Typography
            align="center"
            variant="h6"
            style={{ fontSize: "16px", fontWeight: "400", color: GRAY_ONE }}
          >
            You will get an <span style={{ fontWeight: "800" }}>OTP</span> via
            E-mail
          </Typography>
        </Box>
        <Box style={{ display: "flex", justifyContent: "center" }}>
          <MuiOtpInput
            // style={{ width: deskTopView ? "75%" : "100%" }}
            length={6}
            value={otp}
            autoFocus
            onChange={handleOtpChange}
            // onComplete={handleVerifyOtp}
            TextFieldsProps={{
              style: { backgroundColor: THEME_WEBSITE_BG_COLOR },
              type: "tel",
            }}
          />
        </Box>
        <Button
          fullWidth
          className={classes.loginSignUpBotton}
          variant="contained"
          color="primary"
          onClick={handleVerifyOtp}
          disabled={isLoading}
        >
          {isLoading ? <CircularProgress size={28} /> : "Verify"}
        </Button>
      </Box>
    </Box>
  );
};

export default VerifyOTP;
