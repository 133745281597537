import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import {
  Button,
  CircularProgress,
  Divider,
  InputAdornment,
  TextField,
  Typography,
  useTheme,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

import {
  AppContext,
  ControlPanelContext,
  devConsoleLog,
  isEmptyObject,
} from "../../utility";
import useStyles from "./styles";
import ToggleDays from "../Commons/ToggleDays";
import {
  DOCFYN_API_DOMAIN,
  API_SECTION_API,
  API_VERSION,
  API_ENDPOINT_ENTERPRISE,
  API_RESPONSE_UNAUTHORIZED_STATUS,
  API_ENDPOINT_PRACTICE_TIMINGS,
  THEME_PRIMARY_COLOR,
} from "../../constants";

import SlotsChangeDialog from "./SlotsChangeDialog";

const EditSchedule = () => {
  const classes = useStyles();
  const { handleRefreshToken, setViewSnackbarMain } = useContext(AppContext);
  const { setHeaderHeading, enterprise, AuthorizationToken } =
    useContext(ControlPanelContext);
  const navigate = useNavigate();
  const theme = useTheme();
  const { id: doctorCode = null, doctorName = null } = useParams();
  const [doctorPracticeInfo, setDoctorPracticeInfo] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [isPracticeTimingsPresent, setIsPracticeTimingsPresent] =
    useState(false);
  const { enterprise: currentEnterprise = {} } = enterprise || {};
  const { code: enterpriseCode = null } = currentEnterprise || {};

  const getPracticeTimings = async () => {
    if (!doctorCode) {
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    const url = `${DOCFYN_API_DOMAIN}${API_SECTION_API}${API_VERSION}/${API_ENDPOINT_ENTERPRISE}${enterpriseCode}/${API_ENDPOINT_PRACTICE_TIMINGS}?code=${doctorCode}`;
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          source: "website",
          Authorization: AuthorizationToken,
        },
      });
      const { status = null, data = {} } = response || {};
      const { result = {} } = data || {};
      if (status >= 200 && status < 300) {
        if (!isEmptyObject(result)) setIsPracticeTimingsPresent(true);
        setDoctorPracticeInfo(result);
      }
    } catch (error) {
      const { response } = error || {};
      const { status } = response || {};
      if (status === API_RESPONSE_UNAUTHORIZED_STATUS) {
        handleRefreshToken();
      } else
        setViewSnackbarMain({
          message: "Something went wrong!",
          type: "error",
        });
    } finally {
      setIsLoading(false);
    }
  };

  const updatePracticeTimings = async () => {
    if (!doctorCode) return;
    const url = `${DOCFYN_API_DOMAIN}${API_SECTION_API}${API_VERSION}/${API_ENDPOINT_ENTERPRISE}${enterpriseCode}/${API_ENDPOINT_PRACTICE_TIMINGS}?code=${doctorCode}`;
    try {
      const response = await axios.post(url, doctorPracticeInfo, {
        headers: {
          "Content-Type": "application/json",
          source: "website",
          Authorization: AuthorizationToken,
        },
      });
      const { status = null, data = {} } = response || {};
      const { result = {} } = data || {};
      if (status >= 200 && status < 300) {
        setOpenConfirmationDialog(false);
        getPracticeTimings();
        setViewSnackbarMain({
          message: "Consultation Details Updated!",
          type: "success",
        });
      }
    } catch (error) {
      const { response } = error || {};
      const { status } = response || {};
      if (status === API_RESPONSE_UNAUTHORIZED_STATUS) {
        handleRefreshToken();
      } else
        setViewSnackbarMain({
          message: "Something went wrong!",
          type: "error",
        });
    }
  };

  const handlePracticeTimings = () => {
    if (isPracticeTimingsPresent) setOpenConfirmationDialog(true);
    else updatePracticeTimings();
  };

  const getSelectedDays = (entries = []) => {
    const arr =
      entries.filter((entry) => {
        if (entry["isActive"] !== false) return entry;
        return null;
      }) || [];
    return arr.map((entry) => entry.day);
  };

  const checkIsDeleted = (entries = []) => {
    let isDeleted = true;
    if (entries.length === 0) return false;
    for (let i = 0; i < entries.length; i++) {
      if (entries[i]["code"]) {
        if (entries[i].hasOwnProperty("isActive")) {
          if (entries[i]["isActive"]) {
            isDeleted = false;
            break;
          }
        } else {
          isDeleted = false;
          break;
        }
      }
    }
    return isDeleted;
  };

  const handleTimeChange = (time, key, index) => {
    const copyOfDoctorPracticeInfo =
      JSON.parse(JSON.stringify(doctorPracticeInfo)) || {};
    if (isEmptyObject(copyOfDoctorPracticeInfo))
      copyOfDoctorPracticeInfo["availability"] = [];
    const slotObj = copyOfDoctorPracticeInfo["availability"][index] || {};
    if (isEmptyObject(slotObj))
      copyOfDoctorPracticeInfo["availability"][index] = {};
    copyOfDoctorPracticeInfo["availability"][index][key] = time.format("HH:mm");
    setDoctorPracticeInfo(copyOfDoctorPracticeInfo);
  };

  const handleDaysChange = (index, days) => {
    const copyOfDoctorPracticeInfo =
      JSON.parse(JSON.stringify(doctorPracticeInfo)) || {};
    if (isEmptyObject(copyOfDoctorPracticeInfo))
      copyOfDoctorPracticeInfo["availability"] = [];
    const slotObj = copyOfDoctorPracticeInfo["availability"][index] || {};
    if (isEmptyObject(slotObj))
      copyOfDoctorPracticeInfo["availability"][index] = {};
    let entriesArr =
      copyOfDoctorPracticeInfo["availability"][index]["entries"] || [];
    if (entriesArr.length === 0)
      copyOfDoctorPracticeInfo["availability"][index]["entries"] = entriesArr;
    const entriesObj = {};
    entriesArr.forEach((entry) => {
      const { day = null } = entry || {};
      entriesObj[day] = { ...entry };
    });
    const daysObj = {};
    days.forEach((day) => {
      if (entriesObj[day]) daysObj[day] = entriesObj[day];
      else daysObj[day] = { day };
    });
    for (let i in entriesObj) {
      if (!daysObj[i]) daysObj[i] = { ...entriesObj[i], isActive: false };
      else if (daysObj[i]["isActive"] === false) daysObj[i]["isActive"] = true;
    }
    entriesArr = Object.values(daysObj);
    copyOfDoctorPracticeInfo["availability"][index]["entries"] = entriesArr;
    setDoctorPracticeInfo(copyOfDoctorPracticeInfo);
  };

  const handleConsultationChanges = (event) => {
    const copyOfDoctorPracticeInfo =
      JSON.parse(JSON.stringify(doctorPracticeInfo)) || {};
    copyOfDoctorPracticeInfo[event.target.name] = Number(event.target.value);
    setDoctorPracticeInfo(copyOfDoctorPracticeInfo);
  };

  const addNewSlot = () => {
    const copyOfDoctorPracticeInfo =
      JSON.parse(JSON.stringify(doctorPracticeInfo)) || {};
    if (isEmptyObject(copyOfDoctorPracticeInfo))
      copyOfDoctorPracticeInfo["availability"] = [];
    copyOfDoctorPracticeInfo["availability"].push({});
    setDoctorPracticeInfo(copyOfDoctorPracticeInfo);
  };

  const handleDeleteSlot = (index) => {
    devConsoleLog("handleDeleteSlot", doctorPracticeInfo);
    const copyOfDoctorPracticeInfo =
      JSON.parse(JSON.stringify(doctorPracticeInfo)) || {};
    let entries =
      copyOfDoctorPracticeInfo["availability"][index]["entries"] || [];
    // Case 1 Newly added slot
    let isCode = false;
    for (let i = 0; i < entries.length; i++) {
      if (entries[i]["code"]) {
        isCode = true;
        break;
      }
    }
    if (!isCode) {
      const arr = copyOfDoctorPracticeInfo["availability"].slice(0, index);
      copyOfDoctorPracticeInfo["availability"] = arr;
    } else {
      // Case 2 Previous slot delete
      entries = entries.map((entry) => {
        const obj = { ...entry };
        obj["isActive"] = false;
        return obj;
      });
      copyOfDoctorPracticeInfo["availability"][index]["entries"] = entries;
    }
    setDoctorPracticeInfo(copyOfDoctorPracticeInfo);
  };

  const createInitialSlot = () => {
    const obj = {
      consultationFee: null,
      consultationDuration: null,
      availability: [{}],
    };
    setDoctorPracticeInfo((prev) => ({ ...prev, ...obj }));
  };

  useEffect(() => {
    setHeaderHeading(
      <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <KeyboardBackspaceIcon
          style={{ cursor: "pointer" }}
          onClick={() => navigate(-1)}
        />{" "}
        Edit Timings
      </div>
    );
  }, []);

  useEffect(() => {
    if (enterpriseCode) getPracticeTimings();
  }, [enterpriseCode]);

  useEffect(() => {
    if (isEmptyObject(doctorPracticeInfo)) createInitialSlot();
  }, [doctorPracticeInfo]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {isLoading ? (
        <CircularProgress color="primary" />
      ) : (
        <div style={{}} className={classes.layoutBox}>
          <Typography
            variant="h4"
            style={{ fontWeight: "500", fontSize: "20px" }}
          >
            Consultation Details for{" "}
            <span style={{ color: THEME_PRIMARY_COLOR, fontWeight: "500" }}>
              {doctorName || ""}
            </span>
          </Typography>
          <div className={classes.layoutSubBox}>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: "48px",
              }}
            >
              <TextField
                label="Consultation Duration"
                placeholder="Ex - 15 mins"
                name="consultationDuration"
                //   variant="outlined"
                value={doctorPracticeInfo["consultationDuration"] || null}
                onChange={handleConsultationChanges}
                required
                style={{ flex: 1 }}
                type="number"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">mins</InputAdornment>
                  ),
                }}
              />
              <TextField
                label="Consultation Fees"
                placeholder="Ex - Rs 1500"
                name="consultationFee"
                //   variant="outlined"
                value={doctorPracticeInfo["consultationFee"] || null}
                onChange={handleConsultationChanges}
                required
                type="number"
                style={{ flex: 1 }}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">&#8377;</InputAdornment>
                  ),
                }}
              />
            </div>
            {(doctorPracticeInfo["availability"] || []).map((info, index) => {
              const {
                slotStartTime = null,
                slotEndTime = null,
                entries = [],
              } = info || {};
              const selectedDays = getSelectedDays(entries);
              const isDeleted = checkIsDeleted(entries);
              devConsoleLog(`isDeleted${index}`, isDeleted);
              if (selectedDays.length === 0 && isDeleted && index > 0)
                return <></>;
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: "32px",
                    }}
                  >
                    <ToggleDays
                      selectedDays={selectedDays}
                      index={index}
                      handleDaysChange={handleDaysChange}
                    />
                    {index > 0 && (
                      <DeleteIcon
                        className={classes.deleteSlotIcon}
                        onClick={() => handleDeleteSlot(index)}
                      />
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "16px",
                    }}
                  >
                    <div className={classes.sessionGrid}>
                      <div
                        style={{
                          display: "flex",
                          gap: "20px",
                          alignItems: "center",
                        }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={["TimePicker"]}>
                            <div
                              style={{
                                display: "flex",
                                gap: "20px",
                                alignItems: "center",
                              }}
                            >
                              <TimePicker
                                label="From"
                                // inputFormat="H:m"
                                slotProps={{
                                  textField: {
                                    size: "small",
                                  },
                                }}
                                timeSteps={{ minutes: 1 }}
                                value={
                                  slotStartTime
                                    ? dayjs(slotStartTime, "HH:mm")
                                    : null
                                }
                                onChange={(value) =>
                                  handleTimeChange(
                                    value,
                                    "slotStartTime",
                                    index
                                  )
                                }
                                error="Error"
                              />
                              <TimePicker
                                label="To"
                                value={
                                  slotEndTime
                                    ? dayjs(slotEndTime, "HH:mm")
                                    : null
                                }
                                onChange={(value) =>
                                  handleTimeChange(value, "slotEndTime", index)
                                }
                                slotProps={{
                                  textField: {
                                    size: "small",
                                  },
                                }}
                              />
                            </div>
                          </DemoContainer>
                        </LocalizationProvider>
                      </div>
                    </div>
                  </div>
                  <Divider />
                </div>
              );
            })}
            <Button
              color="primary"
              style={{ width: "fit-content", fontWeight: "500" }}
              onClick={addNewSlot}
            >
              Add timing for remaining days
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              gap: "32px",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              style={{
                width: "fit-content",
                fontWeight: "500",
                textTransform: "none",
              }}
              onClick={() => navigate(-1)}
            >
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{
                width: "fit-content",
                fontWeight: "500",
                textTransform: "none",
              }}
              onClick={handlePracticeTimings}
            >
              Save
            </Button>
          </div>
        </div>
      )}
      {isPracticeTimingsPresent && (
        <SlotsChangeDialog
          isOpen={openConfirmationDialog}
          setOpenConfirmationDialog={setOpenConfirmationDialog}
          updatePracticeTimings={updatePracticeTimings}
        />
      )}
    </div>
  );
};

export default EditSchedule;
