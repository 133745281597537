import { makeStyles } from "@material-ui/core/styles";
import {
  THEME_PRIMARY_COLOR,
  THEME_WEBSITE_BG_COLOR,
  windowHeight,
} from "../../constants";

const useStyles = makeStyles((theme) => ({
  labelRoot: {
    fontSize: 16,
    // "&$labelFocused": {
    //   color: "purple",
    // },
    // top: "50%",
    // left: "10px",
    // transform: "translateY(-50%)",
    // padding: "0",
  },
  signUpSection: {
    display: "flex",
    justifyContent: "center",
    backgroundImage: "url(/wave.svg)",
    backgroundPosition: "bottom",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    minHeight: windowHeight,
    padding: "100px 0",
    [theme.breakpoints.down("sm")]: {
      padding: "40px 16px",
    },
  },
  signUpSectionContent: {
    // display: "flex",
    // flexDirection: "column",
    // width: "30%",
    // [theme.breakpoints.down("md")]: {
    //   width: "50%",
    // },
    // [theme.breakpoints.down("sm")]: {
    //   width: "100%",
    // },
  },
  listPracticeSection: {
    display: "flex",
    justifyContent: "center",
    backgroundImage: "url(/wave.svg)",
    backgroundPosition: "bottom",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    minHeight: windowHeight,
    padding: "100px 0",
    [theme.breakpoints.down("sm")]: {
      padding: "40px 60px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "40px 16px",
    },
  },
  listPracticeSectionContent: {
    // display: "flex",
    // flexDirection: "column",
    // width: "30%",
    // [theme.breakpoints.down("md")]: {
    //   width: "50%",
    // },
    // [theme.breakpoints.down("sm")]: {
    //   width: "100%",
    // },
  },
  verifyOtpSection: {
    padding: "64px 200px",
    [theme.breakpoints.down("sm")]: {
      padding: "64px 40px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "40px 16px",
    },
  },
  loginSection: {
    // backgroundImage: "url(/wave.svg)",
    // backgroundPosition: "bottom",
    // backgroundSize: "contain",
    // backgroundRepeat: "no-repeat",
    // minHeight: windowHeight,
    backgroundColor: THEME_PRIMARY_COLOR,
    height: "100vh",
    padding: "200px 16px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    overflow: 'hidden'
  },
  loginSectionInputBox: {
    display: "flex",
    gap: "16px",
    [theme.breakpoints.down("xs")]: {
      gap: "8px",
    },
  },
  loginSignUpBox: {
    width: "30rem",
    backgroundColor: THEME_WEBSITE_BG_COLOR,
    padding: "3rem",
    borderRadius: ".5rem",
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
  },
  loginSignUpHeading: {
    fontSize: "1.75rem",
    fontWeight: "500",
    letterSpacing: '1px'
  },
  loginSignUpBotton: {
    textTransform: "none",
    padding: "8px",
    fontSize: "1.2rem",
    letterSpacing: "1px",
  },
}));

export default useStyles;
