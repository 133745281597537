import axios from "axios";
import React from "react";
import Auth from "./authenticate";
import {
  API_ENDPOINT_UPLOAD,
  API_SECTION_API,
  API_SECTION_DOCS,
  API_VERSION,
  BUCKET_NAME,
  DOCFYN_DOCS_DOMAIN,
  DOCSITE_DOMAIN_NAME,
  monthNames,
} from "./constants";
import LocalStorage from "./localStorage";

export const devConsoleLog = (val, ...others) => {
  if (val && process.env.NODE_ENV === "development")
    console.log(val, ...others);
};

export const isEmptyObject = (obj) => {
  for (let name in obj) {
    if (obj.hasOwnProperty(name)) {
      return false;
    }
  }
  return true;
};

export const getClinicDomainName = () => {
  const loggedInUser = LocalStorage.getUser() || {};
  const { enterprise = {} } = loggedInUser || {};
  const { enterprise: currentEnterprise = {} } = enterprise || {};
  const { isCustomDomainEnabled = false, domainName = null } =
    currentEnterprise || {};
  if (isCustomDomainEnabled) return `https://${domainName}`;
  else return `${DOCSITE_DOMAIN_NAME}${domainName}`;
};

export const LoadingComponent = (props) => {
  if (props.error) {
    // When the loader has errored
    return (
      <div>
        Error! <button onClick={props.retry}>Retry</button>
      </div>
    );
  } else if (props.timedOut) {
    // When the loader has taken longer than the timeout
    return (
      <div>
        Taking a long time... <button onClick={props.retry}>Retry</button>
      </div>
    );
  } else if (props.pastDelay) {
    // When the loader has taken longer than the delay
    return <div>Loading...</div>;
  } else {
    // When the loader has just started
    return null;
  }
};

export const AppContext = React.createContext();
export const ControlPanelContext = React.createContext();
export const isLoggedIn = Auth.isAuthenticated();
const loggedInUser = isLoggedIn ? LocalStorage.getUser() : {};
const { auth_tokens = {} } = loggedInUser || {};
export const _authorization = `Bearer ${auth_tokens.AccessToken || ""}`;

export const handleImageUpload = async (event) => {
  const file = event.target.files[0];
  const url = `${DOCFYN_DOCS_DOMAIN}${API_SECTION_API}${API_VERSION}/${API_SECTION_DOCS}${API_ENDPOINT_UPLOAD}`;
  try {
    if (file) {
      let fileData = new FormData();
      fileData.append("file", file);
      fileData.append("bucket_name", BUCKET_NAME);
      const response = await axios.post(url, fileData, {
        headers: {
          "Content-Type": "multipart/form-data",
          source: "website",
          Authorization: _authorization,
        },
      });
      const { status = null, data = {} } = response || {};
      if (status >= 200 && status < 300) {
        return data;
      }
    }
  } catch (error) {
    return { error };
  }
};

export const getThumborUrl = (imageUrl, height, width) => {
  devConsoleLog("getThumborUrl", `${imageUrl}`);
  return eval("`" + imageUrl + "`");
};

export const getFormattedDate = (
  data,
  format = "dd-mm-yyyy",
  monthInAlphabets = false
) => {
  if (data) {
    let newDate = data.indexOf("T") > 0 ? data.split("T") : null;
    if (newDate && newDate.length > 0 && format == "yyyy-mm-dd") {
      // date is js date time string
      newDate = newDate[0];
    } else {
      try {
        newDate = new Date(data);
        let dateData = {};
        dateData["yyyy"] = newDate.getFullYear();
        if (monthInAlphabets) {
          dateData["mm"] = monthNames[newDate.getMonth()];
        } else
          dateData["mm"] =
            newDate.getMonth() + 1 < 10
              ? parseInt(`0${newDate.getMonth() + 1}`)
              : newDate.getMonth() + 1;
        dateData["dd"] =
          newDate.getDate() < 10
            ? parseInt(`0${newDate.getDate()}`)
            : newDate.getDate();
        let formatArr = format.split("-");
        if (formatArr.length > 0) {
          newDate = "";
          formatArr.forEach((item, index) => {
            newDate += `${dateData[item]}`;
            if (index < formatArr.length - 1) newDate += "-";
          });
        }
      } catch (e) {
        return data;
      }
    }
    return newDate;
  }
};

export const getInitials = (name) => {
  var initials = name.match(/\b\w/g) || [];
  initials = ((initials.shift() || "") + (initials.pop() || "")).toUpperCase();
  console.log(initials);
  return initials;
};
