import { makeStyles } from "@material-ui/core/styles";
import {
  GRAY_ONE,
  LIGHT_BLUE_COLOR,
  THEME_PRIMARY_COLOR,
  THEME_SECONDARY_COLOR,
  THEME_WEBSITE_BG_COLOR,
  THEME_WEBSITE_SECONDARY_BG_COLOR,
} from "../../constants";

const useStyles = makeStyles((theme) => ({
  homepageSearchSection: {
    padding: "80px 200px",
    backgroundColor: THEME_WEBSITE_SECONDARY_BG_COLOR,
  },
  homePageMWebSearchSection: {
    padding: "80px 40px",
    [theme.breakpoints.down("sm")]: {
      padding: "40px 16px",
    },
  },
  homepageSearchSectionHeadingBox: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "48px",
    flexDirection: "column",
    alignItems: "center",
    gap: "24px",
  },
  homepageSearchSectionHeading: {
    width: "70%",
    wordBreak: "break-word",
  },
  mWebHomepageSearchSectionHeading: {
    width: "100%",
    fontSize: "64px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "32px",
    },
  },
  specialityCard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",
    backgroundColor: THEME_WEBSITE_BG_COLOR,
    padding: "16px",
    borderRadius: "16px",
    boxShadow: "0 0 5px rgba(0,0,0,.2)",
    position: "relative",
    transition: ".5s",
    "&::before": {
      content: "",
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      // border-radius: 50%,
      background: LIGHT_BLUE_COLOR,
      transition: ".5s",
      transform: "scale(0.9)",
      zIindex: "-1",
    },
    // "&:hover": {
    //   transform: "scale(1.2)",
    // },
    "&:hover::before": {
      transform: "scale(1.05)",
      boxShadow: `0 0 30px ${LIGHT_BLUE_COLOR}`,
    },
    "&:hover": {
      // color: "#ffee10",
      boxShadow: `0 0 30px ${LIGHT_BLUE_COLOR}`,
      transform: "scale(1.05)",
      // textShadow: "0 0 5px #ffee10",
    },
  },
  homepageSearchSectionSubHeading: {
    width: "70%",
    color: GRAY_ONE,
    fontWeight: "500",
  },
  mWebHomepageSearchSectionSubHeading: {
    width: "100%",
    fontSize: "32px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
    textAlign: "center",
  },
  doctorsSearchBarBox: {
    display: "flex",
    justifyContent: "center",
  },
  doctorsSearchBarInputBox: {
    display: "flex",
    justifyContent: "center",
    width: "70%",
    borderRadius: "50px",
    boxShadow: "0 0 16px rgba(189, 189, 189, .2)",
  },
  doctorsSearchBarInput: {
    display: "flex",
    alignItems: "center",
    padding: "16px 0",
    borderRight: "none",
    flex: 1,
    borderRadius: "50px 0 0 50px",
    backgroundColor: THEME_WEBSITE_BG_COLOR,
  },
  specialityAutocomplete: {
    flex: 1,
    padding: "0 24px",
  },
  catgeoriesSection: {
    padding: "80px 200px",
    backgroundColor: THEME_PRIMARY_COLOR,
  },
  mWebCategoriesSection: {
    padding: "80px 40px",
    [theme.breakpoints.down("sm")]: {
      padding: "40px 16px",
    },
  },
  categoriesSectionHeading: {
    textAlign: "center",
    fontSize: "32px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "24px",
    },
  },
  categoriesSectionContent: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    rowGap: "48px",
    columnGap: "24px",
  },
  mWebCategoriesSectionContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "start",
    gap: "48px",
  },
  listPracticeSection: {
    padding: "80px 200px",
    backgroundColor: THEME_WEBSITE_BG_COLOR,
    display: "flex",
    gap: "48px",
  },
  mWebListPracticeSection: {
    flexDirection: "column",
    padding: "60px 40px",
    [theme.breakpoints.down("xs")]: {
      padding: "40px 16px",
    },
  },
  listPracticeButton: {
    width: "50%",
    padding: "8px 0",
    fontSize: "16px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
}));

export default useStyles;
