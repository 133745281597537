import { Box, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import {
  THEME_WEBSITE_BG_COLOR,
} from "../../constants";
import { AppContext, ControlPanelContext } from "../../utility";
import useStyles from "./styles";
import VerticalStepper from "./VerticalStepper";

const Home = () => {
  const classes = useStyles();
  const { mobileView } = useContext(AppContext);
  const { setHeaderHeading } = useContext(ControlPanelContext);
  const [activeStep, setActiveStep] = useState(null);

  // const handleCreateEnterprise = async () => {
  //   const url = `${DOCFYN_API_DOMAIN}${API_SECTION_API}${API_VERSION}/${API_ENDPOINT_ENTERPRISE}`;
  //   const reqBody = { name: enterpriseName };
  //   try {
  //     const response = await axios.post(url, reqBody, {
  //       headers: {
  //         "Content-Type": "application/json",
  //         source: "website",
  //         Authorization: _authorization,
  //       },
  //     });
  //     const { data = {}, error } = response || {};
  //     const { result = {} } = data || {};
  //     const { enterprise = {} } = result || {};
  //     const loggedInUser = LocalStorage.getUser() || {};
  //     const flag = LocalStorage.setUser({ ...loggedInUser, enterprise });
  //     if (flag) {
  //       window.location.href = "/home";
  //     }
  //   } catch (error) {
  //     const { response } = error || {};
  //     const { status = null } = response || {};
  //     if (status === API_RESPONSE_UNAUTHORIZED_STATUS) {
  //       handleRefreshToken();
  //     } else
  //       setViewSnackbarMain({
  //         message: "Something went wrong. Please try again !",
  //         type: "error",
  //       });
  //   }
  // };

  useEffect(() => {
    setHeaderHeading("Home");
  }, []);

  return (
    <Box
      style={{
        height: mobileView ? "calc(100% - 120px)" : "calc(100% - 64px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        className={classes.clinicSetupBox}
      >
        <Box style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          <Typography
            variant="h5"
            style={{ fontSize: "28px", fontWeight: "500", lineHeight: "36px" }}
          >
            {activeStep === 0 ? 'Complete your clinic setup...' : "Your clinic is ready!"}
          </Typography>
          <Typography
            variant="subtitle1"
            style={{ fontSize: "16px", fontWeight: "300", lineHeight: "24px" }}
          >
            Seamlessly Transform Your Practice into a Digital Hub of Health and
            Wellness
          </Typography>
        </Box>
        <VerticalStepper activeStep={activeStep} setActiveStep={setActiveStep} />
      </Box>
    </Box>
  );
};

export default Home;
