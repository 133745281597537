import { makeStyles } from "@material-ui/core/styles";
import { BOX_SHADOW } from "../../constants";

const useStyles = makeStyles((theme) => ({
  marketplace: {
    display: "flex",
    flexDirection: "column",
    gap: "32px",
  },
  marketplaceBannerDiv: {
    backgroundColor: "#141571",
    borderRadius: "8px",
    padding: "16px 24px",
    color: "#fff",
    display: "flex",
    alignItems: "center",
    gap: "48px",
    justifyContent: "space-between",
  },
  marketplacePluginsDiv: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
  },
  marketplacePluginsGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: "24px",
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
    },
  },
  marketplacePluginGridItem: {
    padding: "20px",
    boxShadow: BOX_SHADOW,
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    borderRadius: "4px",
  },
  marketplaceGridItemFirstDiv: {
    display: "flex",
    alignItems: "center",
    gap: "24px",
    justifyContent: "space-between",
  },
  marketplaceImg: {
    maxHeight: "164px",
  },
}));

export default useStyles;
