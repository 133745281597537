import React, { useContext, useEffect, useState } from "react";

import {
  alpha,
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputAdornment,
  InputBase,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import AddIcon from "@material-ui/icons/Add";

import {
  AppContext,
  ControlPanelContext,
  devConsoleLog,
  _authorization,
} from "../../utility";
import {
  API_ENDPOINT_DOCTORS,
  API_ENDPOINT_ENTERPRISE,
  API_RESPONSE_UNAUTHORIZED_STATUS,
  API_SECTION_API,
  API_VERSION,
  DOCFYN_API_DOMAIN,
  GRAY_ONE,
} from "../../constants";
import useStyles from "./styles";
import AddDoctorDialog from "./AddDoctorDialog";
import axios from "axios";
import DoctorsList from "./DoctorsList";
import InvitedDoctors from "./InvitedDoctors";
import VerticalTabs from "../Commons/VerticalTabs";
import HorizontalTabs from "../Commons/HorizontalTabs";

const tabs = [
  {
    name: "Active Doctors",
    Component: DoctorsList,
  },
  {
    name: "Invited Doctors",
    Component: InvitedDoctors,
  },
];

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.common.white,
    border: "1px solid #ced4da",
    fontSize: 16,
    fontWeight: "300",
    padding: "14px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}))(InputBase);

const Doctors = () => {
  const classes = useStyles();
  const { setHeaderHeading, enterprise, isEnterprise, AuthorizationToken } =
    useContext(ControlPanelContext);
  const { setViewSnackbarMain, handleRefreshToken, mobileView, deskTopView } =
    useContext(AppContext);
  const [isAddDoctorDialogOpen, setIsAddDoctorDialogOpen] = useState(false);
  const [doctorsList, setDoctorsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { enterprise: currentEnterprise = {} } = enterprise || {};
  const { code: enterpriseCode = null } = currentEnterprise || {};
  const [currentTab, setCurrentTab] = useState(0);
  const TabComponent = tabs[currentTab].Component;

  const handleAddNewDoctor = () => {
    setIsAddDoctorDialogOpen(true);
  };

  const getDoctors = async () => {
    if (!enterpriseCode) return;
    setIsLoading(true);
    const url = `${DOCFYN_API_DOMAIN}${API_SECTION_API}${API_VERSION}/${API_ENDPOINT_ENTERPRISE}${API_ENDPOINT_DOCTORS}?code=${enterpriseCode}`;
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          source: "website",
          Authorization: AuthorizationToken,
        },
      });
      const { status = null, data = {} } = response || {};
      const { result = {} } = data || {};
      const { doctors = [] } = result || {};
      if (status >= 200 && status < 300) {
        setDoctorsList(doctors);
      }
    } catch (error) {
      const { response } = error || {};
      const { status } = response || {};
      if (status === API_RESPONSE_UNAUTHORIZED_STATUS) {
        handleRefreshToken();
      } else
        setViewSnackbarMain({
          message: "Something went wrong!",
          type: "error",
        });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setHeaderHeading("Doctors");
  }, []);

  useEffect(() => {
    if (enterpriseCode) getDoctors();
  }, [enterpriseCode]);

  return (
    <Box style={{ minHeight: mobileView ? "calc(100% - 120px)" : "calc(100% - 64px)" }}>
      <Box style={{ display: "flex", justifyContent: "end" }}>
        <Button
          variant="contained"
          color="primary"
          style={{ textTransform: "none", fontSize: "16px" }}
          onClick={handleAddNewDoctor}
          disabled={!isEnterprise}
        >
          <AddIcon style={{ marginRight: "8px" }} /> Add New Doctor
        </Button>
      </Box>
      {doctorsList.length === 0 ? (
        <Box
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Box
              style={{ display: "flex", flexDirection: "column", gap: "24px" }}
            >
              <Box className={classes.addDoctorSvg}></Box>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "24px",
                }}
              >
                <Box>
                  <Typography
                    variant="subtitle1"
                    style={{ fontSize: "20px", fontWeight: "500" }}
                    align="center"
                  >
                    Invite your consultant doctors to your online clinic
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    align="center"
                    style={{
                      color: GRAY_ONE,
                      fontSize: "16px",
                      fontWeight: "400",
                    }}
                  >
                    It takes only few seconds to add doctors and start online
                    appointments
                  </Typography>
                </Box>
                <Box style={{ textAlign: "center" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ textTransform: "none", fontSize: "16px" }}
                    onClick={handleAddNewDoctor}
                    disabled={!isEnterprise}
                  >
                    <AddIcon style={{ marginRight: "8px" }} /> Add New Doctor
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      ) : !deskTopView ? (
        <Box style={{ marginTop: "1rem" }}>
          <div style={{ display: "flex", gap: "1rem", flexDirection: 'column'}}>
            <HorizontalTabs
              tabs={tabs}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
            />
            <TabComponent doctorsList={doctorsList} />
          </div>
        </Box>
      ) : (
        <Box style={{ marginTop: "4rem" }}>
          <div style={{ display: "flex", gap: "32px" }}>
            <VerticalTabs
              tabs={tabs}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
            />
            <TabComponent doctorsList={doctorsList} />
          </div>
        </Box>
      )}
      <AddDoctorDialog
        isOpen={isAddDoctorDialogOpen}
        setIsAddDoctorDialogOpen={setIsAddDoctorDialogOpen}
      />
    </Box>
  );
};

export default Doctors;
