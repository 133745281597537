import React from "react";
import Button from "@material-ui/core/Button";

const PrimaryButton = ({ children, ...props }) => {
  return (
    <Button
      variant="contained"
      color="primary"
      style={{ boxShadow: "none", textTransform: "none" }}
      {...props}
    >
      {children}
    </Button>
  );
};

export default PrimaryButton;
