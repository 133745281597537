import React, { useContext, useState } from "react";

import {
  AppBar,
  Box,
  Divider,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import MenuOutlinedIcon from "@material-ui/icons/MenuOutlined";
import PersonIcon from "@material-ui/icons/Person";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import {
  GRAY_FIVE,
  GRAY_FOUR,
  GRAY_THREE,
  LIGHT_BLUE_COLOR,
  PRIMARY_TEXT_COLOR,
  THEME_PRIMARY_COLOR,
  THEME_WEBSITE_BG_COLOR,
} from "../constants";
import { drawerWidth } from "./ControlPanelLayout";
import { useNavigate } from "react-router-dom";
import LocalStorage from "../localStorage";
import { AppContext } from "../utility";
import ChangeEnterprise from "./Commons/ChangeEnterprise";

const useStyles = makeStyles((theme) => ({
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    backgroundColor: THEME_WEBSITE_BG_COLOR,
    padding: ".5rem 2rem",
    borderBottom: `1px solid ${GRAY_FOUR}`,
    boxShadow: "none",
  },
  mWebAppBar: {
    width: "100%",
    backgroundColor: THEME_WEBSITE_BG_COLOR,
    padding: "12px 1rem",
    borderBottom: `1px solid ${GRAY_FOUR}`,
    boxShadow: "none",
  },
  headerMenu: {
    width: "220px",
    padding: "0 8px",
  },
}));

const Header = ({ headerHeading = "", enterprises = [] }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { mobileView } = useContext(AppContext);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleRedirection = (url) => {
    setAnchorEl(null);
    navigate(url);
  };

  const handleLogout = () => {
    const isDeleted = LocalStorage.deleteUser();
    if (isDeleted) window.location.href = "/login";
  };

  return (
    <AppBar
      position="fixed"
      className={mobileView ? classes.mWebAppBar : classes.appBar}
      style={{zIndex: '1200'}}
    >
      {mobileView ? (
        <ChangeEnterprise enterprises={enterprises} />
      ) : (
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h3"
            style={{
              color: PRIMARY_TEXT_COLOR,
              fontSize: "1.5rem",
              display: "inline-block",
            }}
          >
            {headerHeading || ""}
          </Typography>
          <Box>
            <IconButton
              style={{
                backgroundColor: Boolean(anchorEl)
                  ? LIGHT_BLUE_COLOR
                  : GRAY_THREE,
              }}
              onClick={handleMenuClick}
            >
              <MenuOutlinedIcon
                style={{
                  color: Boolean(anchorEl) ? THEME_PRIMARY_COLOR : GRAY_FIVE,
                  fontSize: "1.6rem",
                }}
              />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
              transformOrigin={{
                vertical: "top",
                // horizontal: "center",
              }}
              style={{ top: "3rem" }}
              classes={{ paper: classes.headerMenu }}
            >
              <MenuItem
                style={{ display: "flex", gap: "1rem", padding: "8px" }}
                onClick={() => handleRedirection("/my-profile")}
              >
                <Box
                  style={{
                    height: "2rem",
                    width: "2rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: GRAY_FOUR,
                    borderRadius: "50px",
                  }}
                >
                  <PersonIcon style={{ color: GRAY_FIVE }} />
                </Box>
                <Typography variant="subtitle2" style={{ color: GRAY_FIVE }}>
                  My Profile
                </Typography>
              </MenuItem>
              <Divider style={{ margin: "4px 0" }} />
              <MenuItem
                style={{ display: "flex", gap: "1rem", padding: "8px" }}
                onClick={handleLogout}
              >
                <Box
                  style={{
                    height: "2rem",
                    width: "2rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: GRAY_FOUR,
                    borderRadius: "50px",
                  }}
                >
                  <ExitToAppIcon style={{ color: GRAY_FIVE }} />
                </Box>
                <Typography variant="subtitle2" style={{ color: GRAY_FIVE }}>
                  Logout
                </Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      )}
    </AppBar>
  );
};

export default Header;
