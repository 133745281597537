import { makeStyles } from "@material-ui/core/styles";
import { THEME_SECONDARY_COLOR, THEME_WEBSITE_BG_COLOR } from "../../constants";

const useStyles = makeStyles((theme) => ({
  starsContainer: {
    gap: "4px",
  },
  starBox: {
    // padding: '2px',
    backgroundColor: THEME_SECONDARY_COLOR,
    color: "white",
    fontSize: "1rem",
  },
  emptyStar: {
    color: "#862B0D",
    opacity: ".15",
  },
  individualDoctorBox: {
    padding: "32px",
    backgroundColor: THEME_WEBSITE_BG_COLOR,
    // width: "50%",
    borderRadius: "16px",
    cursor: "pointer",
    boxShadow: "0 0 10px rgba(0, 0, 0, .2)",
    "&:hover": {
      transform: "scale(1.01)",
      boxShadow: "0 0 10px rgba(250, 213, 7, .2)",
    },
  },
  doctorProfileSubInfoHeading: {
    fontSize: "16px",
    fontWeight: "600",
  },
  doctorProfileSection: {
    padding: "64px 200px",
  },
  mWebDoctorProfileSection: {
    padding: "64px 40px",
    [theme.breakpoints.down("sm")]: {
      padding: "40px 16px",
    },
  },
  doctorProfileSectionBox: {
    display: "flex",
    alignContent: "baseline",
    gap: "64px",
  },
  mWebDoctorProfileSectionBox: {
    display: "flex",
    flexDirection: "column",
    gap: "64px",
    alignItems: "center",
  },
  doctorProfileBox: {
    // width: "30%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  doctorProfileSubSection1: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    position: "sticky",
    top: "139px",
    [theme.breakpoints.down("sm")]: {
      position: "normal",
      alignItems: "center",
    },
  },
  doctorProfileSubSection2: {
    flex: 1,
    // padding: "64px",
    // backgroundColor: THEME_WEBSITE_BG_COLOR,
    // borderRadius: "32px",
    // boxShadow: "0 0 5px rgba(0, 0, 0, .2)",
    display: "flex",
    flexDirection: "column",
    gap: "48px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  doctorProfileSubSection2Common: {
    padding: "16px",
    backgroundColor: THEME_WEBSITE_BG_COLOR,
    borderRadius: "8px",
  },
  doctorProfileDescription: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  addCommentRoot: {
    height: "40px",
  },
  addCommentInputBox: {
    height: "inherit",
  },
  myProfileSubSection: {
    padding: "24px",
    backgroundColor: THEME_WEBSITE_BG_COLOR,
    borderRadius: ".5rem",
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    boxShadow: "0 2px 6px #1a181e0a",
  },
  myProfileSubSectionHeading: {
    fontSize: "16px",
    fontWeight: "500",
    letterSpacing: ".5px",
  },
  myProfileInformationInputRowBox: {
    display: "flex",
    gap: "32px",
  },
  myProfileBox: {
    width: "800px",
    display: "flex",
    flexDirection: "column",
    gap: "32px",
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
}));

export default useStyles;
