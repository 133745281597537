import React from "react";
import Footer from "./Footer";
import NavBar from "./NavBar";

import useStyles from "./styles";

const AppLayout = ({ children, showNavbar = true, showFooter = true }) => {
  const classes = useStyles();
  return (
    <>
      {showNavbar && <NavBar />}
      <div
        className={showNavbar ? classes.appLayout : classes.noNavbarAppLayout}
      >
        {children}
      </div>
      {showFooter && <Footer />}
    </>
  );
};

export default AppLayout;
