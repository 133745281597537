import { makeStyles } from "@material-ui/core/styles";
import { THEME_WEBSITE_BG_COLOR } from "../../constants";

const useStyles = makeStyles((theme) => ({
  clinicSetupBox: {
    maxWidth: "760px",
    backgroundColor: THEME_WEBSITE_BG_COLOR,
    boxShadow: "0 2px 6px #1a181e0a",
    padding: "48px",
    borderRadius: "8px",
    [theme.breakpoints.down('sm')]: {
        padding: '20px'
    }
  },
}));

export default useStyles;
