import { alpha, makeStyles } from "@material-ui/core/styles";
import {
  BOX_SHADOW,
  GRAY_FIVE,
  GRAY_ONE,
  THEME_WEBSITE_BG_COLOR,
} from "../../constants";

const useApperanceStyles = makeStyles((theme) => ({
  designBox: {
    padding: "24px",
    boxShadow: BOX_SHADOW,
    backgroundColor: THEME_WEBSITE_BG_COLOR,
    borderRadius: "4px",
    display: "flex",
    flexDirection: "column",
    gap: "24px",
  },
  designBoxHeading: {
    fontSize: "16px",
    fontWeight: "500",
  },
  designBoxSubHeading: {
    fontSize: "14px",
    fontWeight: "400",
    color: GRAY_ONE,
  },
  tabContentBox: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    gap: "32px",
  },
  serviceItemSubHeading: {
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 3,
    wordBreak: "break-all",
    overflow: "hidden",
  },
  twitterIcon: {
    backgroundColor: GRAY_FIVE,
    mask: "url(/twitter-icon.svg) no-repeat center / contain",
    // width: "100%",
    height: "35px",
    width: "35px",
  },
  themesBox: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: "48px",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr 1fr",      
    },
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gap: "32px",
    },
  },
  servicesGridLayout: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: "32px",
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "1fr 1fr",
    },
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
    },
  },
  contactLayoutBox: {
    width: "800px",
    display: "flex",
    flexDirection: "column",
    gap: "32px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  faqsLayoutBox: {
    width: "800px",
    display: "flex",
    flexDirection: "column",
    gap: "32px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

export default useApperanceStyles;
