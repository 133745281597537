import React from "react";
import { Editor } from "@tinymce/tinymce-react";

import { TINYMCE_API_KEY } from "../../constants";

const WysiwygEditor = ({ value, setValue }) => {
  return (
    <Editor
      apiKey={TINYMCE_API_KEY}
      init={{
        menubar: false,
        plugins:
          "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount",
        toolbar:
          "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap | removeformat",
        tinycomments_mode: "embedded",
        tinycomments_author: "Author name",
        mergetags_list: [
          { value: "First.Name", title: "First Name" },
          { value: "Email", title: "Email" },
        ],
        ai_request: (request, respondWith) =>
          respondWith.string(() =>
            Promise.reject("See docs to implement AI Assistant")
          ),
      }}
      value={value}
      onEditorChange={(newText) => setValue(newText)}
    />
  );
};

export default WysiwygEditor;
