import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Divider,
  FormControl,
  InputAdornment,
  InputLabel,
  Slide,
  TextField,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import {
    API_ENDPOINT_INVITE_DOCTOR,
  API_ENDPOINT_SPECIALITIES,
  API_RESPONSE_UNAUTHORIZED_STATUS,
  API_SECTION_API,
  API_SECTION_ENTITY,
  API_SECTION_PUBLIC,
  API_VERSION,
  COUNTRIES,
  DOCFYN_API_DOMAIN,
  THEME_WEBSITE_SECONDARY_BG_COLOR,
} from "../../constants";
import { AppContext, ControlPanelContext } from "../../utility";
import BootstrapInput from "../Commons/BootstrapInput";
import useStyles from "./styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddDoctorDialog = ({ isOpen = false, setIsAddDoctorDialogOpen }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { setViewSnackbarMain, handleRefreshToken } = useContext(AppContext);
  const { enterprise, isEnterprise, AuthorizationToken } = useContext(ControlPanelContext);
  const [specialities, setSpecialities] = useState([]);
  const [input, setInput] = useState({});
  const { enterprise: currentEnterprise = {} } = enterprise || {};
  const { code: enterpriseCode = null } = currentEnterprise || {};

  const handleClose = () => {
    setOpen(false);
    setIsAddDoctorDialogOpen(false);
  };

  const handleSpecialitySelection = (e, value) => {
    const { code } = value || {};
    setInput((prev) => ({ ...prev, medicalSpecialityCode: code }));
  };

  const getDoctorSpecialities = async () => {
    const url = `${DOCFYN_API_DOMAIN}${API_SECTION_API}${API_VERSION}/${API_SECTION_PUBLIC}${API_ENDPOINT_SPECIALITIES}`;
    try {
      const response = await axios.get(url);
      const { status = null, data = {} } = response || {};
      const { error = false, result = [] } = data || {};
      if (status >= 200 && status < 300) {
        setSpecialities(result);
      }
    } catch (err) {
      setViewSnackbarMain({ message: "Something went wrong!", type: "error" });
    }
  };

  const handleInputChange = (e) => {
    setInput((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleDialCode = (e, value) => {
    const { value: dialValue = "" } = value || {};
    setInput((prev) => ({ ...prev, dialCode: dialValue }));
  };

  const handleInviteDoctor = async () => {
    const url = `${DOCFYN_API_DOMAIN}${API_SECTION_API}${API_VERSION}/${API_SECTION_ENTITY}/${API_ENDPOINT_INVITE_DOCTOR}`;
    const reqBody = {...input, enterpriseCode};
    try {
      const response = await axios.post(url, reqBody, {
        headers: {
          "Content-Type": "application/json",
          source: "website",
          Authorization: AuthorizationToken,
        },
      });
      const { status = null, data = {} } = response || {};
      const { result = {} } = data || {};
      if (status >= 200 && status < 300) {
        setInput({});
        setIsAddDoctorDialogOpen(false);
        setViewSnackbarMain({
          message: "Invite Sent Successfully",
          type: "success",
        });
      }
    } catch (error) {
      const { response } = error || {};
      const { status } = response || {};
      if (status === API_RESPONSE_UNAUTHORIZED_STATUS) {
        handleRefreshToken();
      } else
        setViewSnackbarMain({
          message: "Something went wrong!",
          type: "error",
        });
    }
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    getDoctorSpecialities();
  }, []);

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      TransitionComponent={Transition}
      classes={{ paper: classes.dialogBox }}
    >
      <DialogTitle
        disableTypography
        id="simple-dialog-title"
        style={{ padding: "0", fontSize: "18px", fontWeight: "500" }}
      >
        Add Doctor
      </DialogTitle>
      <Divider
        style={{
          backgroundColor: THEME_WEBSITE_SECONDARY_BG_COLOR,
          marginTop: "8px",
        }}
      />
      <Box
        style={{
          marginTop: "24px",
          display: "flex",
          flexDirection: "column",
          gap: "24px",
        }}
      >
        <Box style={{ display: "flex", gap: "24px" }}>
          <Autocomplete
            id="dialCode"
            options={COUNTRIES}
            getOptionLabel={(option) => option?.dial_code}
            getOptionSelected={(option, value) => option.dial_code === value}
            onChange={handleDialCode}
            value={input["dialCode"]}
            style={{ flex: 1 }}
            renderInput={(params) => (
              <div ref={params.InputProps.ref}>
                <FormControl style={{ width: "100%" }}>
                  <InputLabel shrink htmlFor="dialCode">
                    Dial Code
                  </InputLabel>
                  <BootstrapInput
                    id="dialCode"
                    variant="outlined"
                    placeholder="Eg +91"
                    endAdornment={
                      <InputAdornment
                        style={{ position: "absolute", right: "1rem" }}
                        position="end"
                      >
                        <ArrowDropDownIcon />
                      </InputAdornment>
                    }
                    {...params}
                  />
                </FormControl>
              </div>
            )}
          />
          <FormControl style={{ flex: 2 }}>
            <InputLabel shrink htmlFor="phone">
              Phone Number
            </InputLabel>
            <BootstrapInput
              id="phone"
              name="phone"
              placeholder="Enter phone number"
              onChange={handleInputChange}
              value={input["phone"] || ""}
            />
          </FormControl>
        </Box>
        <FormControl style={{ flex: 1 }}>
          <InputLabel shrink htmlFor="name">
            Full Name
          </InputLabel>
          <BootstrapInput
            id="name"
            name="name"
            placeholder="Enter full name"
            onChange={handleInputChange}
            value={input["name"] || ""}
          />
        </FormControl>
        <FormControl style={{ flex: 1 }}>
          <InputLabel shrink htmlFor="email">
            Email
          </InputLabel>
          <BootstrapInput
            id="email"
            name="email"
            placeholder="Enter email"
            onChange={handleInputChange}
            value={input["email"] || ""}
          />
        </FormControl>

        <Autocomplete
          id="speciality"
          name="speciality"
          options={specialities}
          getOptionLabel={(option) => option?.type}
          getOptionSelected={(option, value) => option.code === value}
          onChange={handleSpecialitySelection}
          value={input["medicalSpecialityCode"]}
          style={{ width: "100%" }}
          renderInput={(params) => (
            <div ref={params.InputProps.ref}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel shrink htmlFor="speciality">
                  Medical Speciality
                </InputLabel>
                <BootstrapInput
                  id="speciality"
                  variant="outlined"
                  placeholder="Select speciality"
                  endAdornment={
                    <InputAdornment
                      style={{ position: "absolute", right: "1rem" }}
                      position="end"
                    >
                      <ArrowDropDownIcon />
                    </InputAdornment>
                  }
                  {...params}
                />
              </FormControl>
            </div>
          )}
        />
      </Box>
      <Box style={{ textAlign: "center", marginTop: "48px" }}>
        <Button
          variant="contained"
          color="primary"
          style={{
            textTransform: "none",
            padding: "6px 24px",
            fontSize: "16px",
          }}
          disabled={!isEnterprise}
          onClick={handleInviteDoctor}
        >
          Send Invite
        </Button>
      </Box>
    </Dialog>
  );
};

export default AddDoctorDialog;
