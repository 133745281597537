import React, { useContext, useState } from "react";
import axios from "axios";

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  FormControl,
  InputLabel,
  Typography,
} from "@material-ui/core";

import {
  API_ENDPOINT_ENTERPRISE,
  API_RESPONSE_UNAUTHORIZED_STATUS,
  API_SECTION_API,
  API_VERSION,
  DOCFYN_API_DOMAIN,
  GRAY_ONE,
  THEME_WEBSITE_BG_COLOR,
} from "../../constants";
import LocalStorage from "../../localStorage";
import {
  AppContext,
  ControlPanelContext,
  devConsoleLog,
  _authorization,
} from "../../utility";
import BootstrapInput from "./BootstrapInput";
import useStyles from "./styles";

const CreateEnterpriseDialog = ({ setIsCreateEnterpriseOpen }) => {
  const classes = useStyles();
  const { handleRefreshToken, setViewSnackbarMain } = useContext(AppContext);
  const { setEnterprises, userData, setUserData, AuthorizationToken } =
    useContext(ControlPanelContext);
  const [open, setOpen] = useState(true);
  const [enterpriseName, setEnterpriseName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    setIsCreateEnterpriseOpen(false);
  };

  const handleEnterpriseNameChange = (event) => {
    setEnterpriseName(event.target.value);
  };

  const handleCreateClinic = async () => {
    setIsLoading(true);
    const url = `${DOCFYN_API_DOMAIN}${API_SECTION_API}${API_VERSION}/${API_ENDPOINT_ENTERPRISE}`;
    const reqBody = { name: enterpriseName };
    try {
      const response = await axios.post(url, reqBody, {
        headers: {
          "Content-Type": "application/json",
          source: "website",
          Authorization: AuthorizationToken,
        },
      });
      const { data = {}, error } = response || {};
      const { result = {} } = data || {};
      setUserData({ ...userData, enterprise: result });
      // const loggedInUser = LocalStorage.getUser() || {};
      // const flag = LocalStorage.setUser({ ...loggedInUser, enterprise: result });

      // setEnterprises((prev) => [...prev, result]);
      // handleClose();
      window.location.reload()
    } catch (error) {
      const { response } = error || {};
      const { status = null } = response || {};
      if (status === API_RESPONSE_UNAUTHORIZED_STATUS) {
        handleRefreshToken();
      } else
        setViewSnackbarMain({
          message: "Something went wrong. Please try again !",
          type: "error",
        });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      classes={{ paper: classes.dialogBox }}
    >
      <Box
        style={{
          backgroundColor: THEME_WEBSITE_BG_COLOR,
          borderRadius: "8px",
          boxShadow: "0 2px 6px #1a181e0a",
          padding: "16px",
          display: "flex",
          flexDirection: "column",
          //   gap: "24px",
        }}
      >
        <Typography
          variant="h6"
          style={{ fontSize: "20px", fontWeight: "500" }}
        >
          Create your E-Clinic
        </Typography>
        <Typography
          variant="subtitle1"
          style={{ color: GRAY_ONE, fontSize: "14px" }}
        >
          Craft a Distinct Identity for Your E-Clinic with a Unique Name
        </Typography>
        <FormControl style={{ width: "100%", marginTop: "24px" }}>
          <InputLabel
            shrink
            htmlFor="enterpriseName"
            // style={{ fontSize: "18px", color: "#333333" }}
          >
            Clinic Name
          </InputLabel>
          <BootstrapInput
            id="enterpriseName"
            value={enterpriseName}
            onChange={handleEnterpriseNameChange}
          />
        </FormControl>
        <Box style={{ textAlign: "end", marginTop: "24px" }}>
          <Button
            variant="outlined"
            color="primary"
            // disabled={isEmptyObject(selectedEnterprise)}
            style={{
              textTransform: "none",
              fontSize: "14px",
              marginRight: "24px",
              width: "100px",
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={!Boolean(enterpriseName)}
            style={{ textTransform: "none", fontSize: "14px", width: "100px" }}
            onClick={handleCreateClinic}
          >
            {isLoading ? <CircularProgress size="24" /> : "Create"}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default CreateEnterpriseDialog;
