import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { format, parse } from "date-fns";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { SUCCESS_TEXT_COLOR } from "../../constants";
import useStyles from "./styles";
import { devConsoleLog } from "../../utility";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

// const useStyles = makeStyles((theme) => ({
//   root: {
//     flexGrow: 1,
//     width: '100%',
//     backgroundColor: theme.palette.background.paper,
//   },
// }));

const getTabLabel = (date, noOfSlots = 0) => {
  return (
    <div>
      <Typography variant="subtitle1" style={{}}>
        {date}
      </Typography>
      <Typography
        variant="subtitle1"
        style={{ color: SUCCESS_TEXT_COLOR, fontSize: "12px" }}
      >
        {noOfSlots === 0 ? "No" : noOfSlots} Slots Available
      </Typography>
    </div>
  );
};

const SlotsPanel = ({ slots = [], input = {}, setInput = {}, dateTimeClicked = null }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(dateTimeClicked);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSelectTimings = (slot) => {
    const { ts = "" } = slot || {};
    const splittedTimeDate = ts.split(" ");
    const selectedSlotDate = splittedTimeDate[0];
    const selectedSlotTime = splittedTimeDate[1];
    setInput((prev) => ({
      ...prev,
      slotDate: selectedSlotDate,
      slotTime: selectedSlotTime,
    }));
  };

  const divideSlots = (arr, type) => {
    let finalSlots = [];
    finalSlots = arr.filter((item) => {
      const { st: startTime = "" } = item || {};
      const time = parseInt(startTime.split(":")[0], 10);
      if (type === "morning" && time >= 0 && time < 12) {
        return item;
      } else if (type === "afternoon" && time >= 12 && time < 17) {
        return item;
      } else if (type === "evening" && time >= 17 && time < 24) return item;
      return null;
    });
    return finalSlots;
  };

  useEffect(() => {
    if (slots.length > 0) {

    }
  }, [slots]);

  const handleDateSelection = (index) => {
    setValue(index);
  }

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        color="default"
        style={{ boxShadow: "none", background: "transparent" }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          {slots.map((slot, index) => {
            const { day = "", date = "", sessions = [] } = slot || {};
            let allSlots = [];
            sessions.forEach((session) => {
              const { timeSlots = [] } = session || {};
              allSlots = [...allSlots, ...timeSlots];
            });
            
            const dateObject = new Date(date);
            const formattedDate = `${day.slice(0, 3)}, ${format(
              dateObject,
              "d MMM yy"
            )}`;
            return (
              <Tab
                style={{ textTransform: "none" }}
                label={getTabLabel(formattedDate, allSlots.length)}
                {...a11yProps(index)}
                value={date}
              />
            );
          })}
        </Tabs>
      </AppBar>
      {slots.map((slot, index) => {
        const { day = "", date = "", sessions = [] } = slot || {};
        let allSlots = [];
        sessions.forEach((session) => {
          const { timeSlots = [] } = session || {};
          allSlots = [...allSlots, ...timeSlots];
        });
        const morningSlots = divideSlots(allSlots, "morning");
        const afternoonSlots = divideSlots(allSlots, "afternoon");
        const eveningSlots = divideSlots(allSlots, "evening");
        const dateObject = new Date(date);
        const formattedDate = `${day.slice(0, 3)}, ${format(
          dateObject,
          "d MMM yy"
        )}`;
        const isSlotAvailable =
          morningSlots.length > 0 ||
          afternoonSlots.length > 0 ||
          eveningSlots.length > 0;
        return (
          <TabPanel key={index} value={value} index={date}>
            {!isSlotAvailable ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <img alt="no-slot" src="/no-slot.svg" />
                <Typography
                  variant="body1"
                  style={{ color: theme.palette.text.secondary }}
                >{`No slots available for ${formattedDate}`}</Typography>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "32px",
                }}
              >
                {morningSlots.length > 0 && (
                  <div className={classes.slotTimingGridBox}>
                    <div className={classes.timeOfTheDayIconBox}>
                      <img alt="morning" src="/morning.png" />
                      <Typography variant="body1">Morning</Typography>
                    </div>
                    <div className={classes.slotTimingSlotsGrid}>
                      {morningSlots.map((slot, index) => {
                        const { st: sessionStartTime = "", ts = "" } = slot || {};
                        const isSelected = ts === `${input["slotDate"]} ${input["slotTime"]}`;
                        const parsedTime = parse(
                          sessionStartTime,
                          "HH:mm",
                          new Date()
                        );
                        const formattedStartTime = format(
                          parsedTime,
                          "h:mm aa"
                        );
                        return (
                          <div
                            className={`${classes.appointmentTimeBox} ${isSelected && classes.selectedTimeBox}`}
                            onClick={() => handleSelectTimings(slot)}
                          >
                            {formattedStartTime || ""}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                {afternoonSlots.length > 0 && (
                  <div className={classes.slotTimingGridBox}>
                    <div className={classes.timeOfTheDayIconBox}>
                      <img alt="afternoon" src="/afternoon.png" />
                      <Typography variant="body1">Afternoon</Typography>
                    </div>
                    <div className={classes.slotTimingSlotsGrid}>
                      {afternoonSlots.map((slot, index) => {
                        const { st: sessionStartTime = "", ts = "" } = slot || {};
                        const isSelected = ts === `${input["slotDate"]} ${input["slotTime"]}`;
                        const parsedTime = parse(
                          sessionStartTime,
                          "HH:mm",
                          new Date()
                        );
                        const formattedStartTime = format(
                          parsedTime,
                          "h:mm aa"
                        );
                        return (
                          <div
                            className={`${classes.appointmentTimeBox} ${isSelected && classes.selectedTimeBox}`}
                            onClick={() => handleSelectTimings(slot)}
                          >
                            {formattedStartTime || ""}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                {eveningSlots.length > 0 && (
                  <div className={classes.slotTimingGridBox}>
                    <div className={classes.timeOfTheDayIconBox}>
                      <img alt="evening" src="/evening.png" />
                      <Typography variant="body1">Evening</Typography>
                    </div>
                    <div className={classes.slotTimingSlotsGrid}>
                      {eveningSlots.map((slot, index) => {
                        const { st: sessionStartTime = "", ts = "" } = slot || {};
                        const isSelected = ts === `${input["slotDate"]} ${input["slotTime"]}`;
                        const parsedTime = parse(
                          sessionStartTime,
                          "HH:mm",
                          new Date()
                        );
                        const formattedStartTime = format(
                          parsedTime,
                          "h:mm aa"
                        );
                        return (
                          <div
                            className={`${classes.appointmentTimeBox} ${isSelected && classes.selectedTimeBox}`}
                            onClick={() => handleSelectTimings(slot)}
                          >
                            {formattedStartTime || ""}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            )}
          </TabPanel>
        );
      })}
    </div>
  );
};

export default React.memo(SlotsPanel);
