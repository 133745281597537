import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Box, Button, CircularProgress, Typography } from "@material-ui/core";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";

import {
  API_ENDPOINT_ENTERPRISE,
  API_RESPONSE_UNAUTHORIZED_STATUS,
  API_SECTION_API,
  API_SECTION_PUBLIC,
  API_SECTION_WEBSITE,
  API_VERSION,
  GRAY_FOUR,
  GRAY_TWO,
  HARBOR_API_DOMAIN,
} from "../../../constants";
import useStyles from "./styles";
import useApperanceStyles from "../styles";
import {
  AppContext,
  ControlPanelContext,
  getThumborUrl,
  handleImageUpload,
} from "../../../utility";

const Favicon = () => {
  const classes = useStyles();
  const appearanceClasses = useApperanceStyles();
  const { setViewSnackbarMain, handleRefreshToken } = useContext(AppContext);
  const { AuthorizationToken, enterprise = {} } =
    useContext(ControlPanelContext);
  const { enterprise: selectedEnterprise = {} } = enterprise || {};
  const { domainName = null, code: enterpriseCode = null } =
    selectedEnterprise || {};
  const [input, setInput] = useState({});
  const [isImageLoading, setIsImageLoading] = useState({
    logo: false,
    favicon: false,
  });
  const [hasInputChanged, setHasInputChanged] = useState(false);

  const getWebsiteData = async () => {
    const url = `${HARBOR_API_DOMAIN}${API_SECTION_API}${API_VERSION}/${API_SECTION_PUBLIC}${API_SECTION_WEBSITE}?domainName=${domainName}`;
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          source: "website",
          Authorization: AuthorizationToken,
        },
      });
      const { data = {}, status = null } = response || {};
      const { result = {} } = data || {};
      const { logo_url: logoUrl = "", favicon_url: faviconUrl = "" } =
        result || {};
      if (status >= 200 && status < 300) {
        const obj = {};
        if (logoUrl) obj['logoUrl'] = logoUrl;
        if (faviconUrl) obj['faviconUrl'] = faviconUrl;
        setInput(obj);
      }
    } catch (error) {}
  };

  const updateWebsiteData = async () => {
    if (!enterpriseCode) return;
    const url = `${HARBOR_API_DOMAIN}${API_SECTION_API}${API_VERSION}/${API_ENDPOINT_ENTERPRISE}${enterpriseCode}/`;
    const reqBody = {...input};
    try {
      const response = await axios.patch(url, reqBody, {
        headers: {
          "Content-Type": "application/json",
          source: "website",
          Authorization: AuthorizationToken,
        },
      });
      const { data = {}, status = null } = response || {};
      const { result = {} } = data || {};
      const { faviconUrl = "", logoUrl = "" } = result || {};
      if (status >= 200 && status < 300) {
        setInput({ logoUrl, faviconUrl });
        setViewSnackbarMain({
          message: "Logo and favicon updated successfully!!!",
          type: "success",
        });
      }
    } catch (error) {
      const { response } = error || {};
      const { status } = response || {};
      if (status === API_RESPONSE_UNAUTHORIZED_STATUS) {
        handleRefreshToken();
      } else
        setViewSnackbarMain({
          message: "Something went wrong!",
          type: "error",
        });
    }
  };

  const handleLogoUpload = async (event) => {
    setIsImageLoading((prev) => ({ ...prev, logo: true }));
    const image = await handleImageUpload(event);
    if (image) {
      const {
        cdn_url: logoUrl = "",
        error = "",
        object_url: logoObjectUrl = "",
      } = image || {};
      if (error) {
        setIsImageLoading((prev) => ({ ...prev, logo: false }));
        setViewSnackbarMain({
          message: "Photo couldn't be uploaded",
          type: "error",
        });
      } else {
        setInput((prev) => ({ ...prev, logoUrl, logoObjectUrl }));
        setIsImageLoading((prev) => ({ ...prev, logo: false }));
        setHasInputChanged(true);
      }
    }
  };

  const handleFaviconUpload = async (event) => {
    setIsImageLoading((prev) => ({ ...prev, favicon: true }));
    const image = await handleImageUpload(event);
    if (image) {
      const {
        cdn_url: faviconUrl = "",
        error = "",
        object_url: faviconObjectUrl = "",
      } = image || {};
      if (error) {
        setIsImageLoading((prev) => ({ ...prev, favicon: false }));
        setViewSnackbarMain({
          message: "Photo couldn't be uploaded",
          type: "error",
        });
      } else {
        setInput((prev) => ({ ...prev, faviconUrl, faviconObjectUrl }));
        setIsImageLoading((prev) => ({ ...prev, favicon: false }));
        setHasInputChanged(true);
      }
    }
  };

  useEffect(() => {
    if (domainName) getWebsiteData();
  }, [domainName]);

  return (
    <Box className={appearanceClasses.tabContentBox}>
      <Box className={appearanceClasses.designBox}>
        <Box>
          <Typography
            className={appearanceClasses.designBoxHeading}
            variant="subtitle1"
          >
            Clinic logo
          </Typography>
          <Typography
            className={appearanceClasses.designBoxSubHeading}
            variant="subtitle2"
          >
            Aspect ratio of logo should be 4:1 (width x height)
          </Typography>
        </Box>
        <Box className={classes.headersLogoBox}>
          <Box
            style={{
              minHeight: "6rem",
              minWidth: "176px",
              backgroundColor: GRAY_FOUR,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "8px",
              cursor: "pointer",
            }}
            component="label"
          >
            {isImageLoading["logo"] ? (
              <CircularProgress />
            ) : input["logoUrl"] ? (
              <img
                src={getThumborUrl(input["logoUrl"], 0, 0)}
                alt="clinic-logo"
                style={{ height: "40px", width: "160px" }}
              />
            ) : (
              <LocalHospitalIcon
                style={{ fontSize: "4rem", color: GRAY_TWO }}
              />
            )}
            <input type="file" hidden onChange={handleLogoUpload} />
          </Box>
          <Box
            variant="contained"
            style={{
              backgroundColor: "#F2F2F2",
              textTransform: "none",
              fontSize: "14px",
              fontWeight: "400",
              padding: "8px 24px",
              borderRadius: "4px",
              cursor: "pointer",
            }}
            component="label"
          >
            Update image
            <input type="file" hidden onChange={handleLogoUpload} />
          </Box>
        </Box>
      </Box>
      <Box className={appearanceClasses.designBox}>
        <Box>
          <Typography
            className={appearanceClasses.designBoxHeading}
            variant="subtitle1"
          >
            Favicon
          </Typography>
          <Typography
            className={appearanceClasses.designBoxSubHeading}
            variant="subtitle2"
          >
            Favicon should be a square and atleast 48px*48px.
          </Typography>
        </Box>
        <Box className={classes.faviconBox}>
          <Box className={classes.faviconImgNameBox}>
            <img
              alt="favicon"
              src={input["faviconUrl"] ? getThumborUrl(input["faviconUrl"], 24, 24) : "/favicon.png"}
              style={{ height: "24px", width: "24px" }}
            />
            <Typography variant="subtitl2" style={{ fontSize: "13px" }}>
              KD-Clinic
            </Typography>
          </Box>
        </Box>
        <Box style={{ display: "flex", alignItems: "center", gap: "16px" }}>
          <img
            alt="favicon"
            src={input["faviconUrl"] ? getThumborUrl(input["faviconUrl"], 32, 32) : "/favicon.png"}
            style={{ height: "32px", width: "32px" }}
          />
          <Button
            variant="outlined"
            color="primary"
            style={{
              textTransform: "none",
              padding: "4px 12px",
              fontSize: "14px",
              fontWeight: "400",
            }}
            component="label"
          >
            {isImageLoading["favicon"] ? (
              <CircularProgress size={20} />
            ) : (
              <>
                Change image
                <input type="file" hidden onChange={handleFaviconUpload} />
              </>
            )}
          </Button>
        </Box>
      </Box>
      <Box style={{ textAlign: "end" }}>
        <Button
          variant="contained"
          color="primary"
          style={{
            textTransform: "none",
            padding: "4px 16px",
            fontSize: "14px",
          }}
          onClick={updateWebsiteData}
        >
          Update
        </Button>
      </Box>
    </Box>
  );
};

export default Favicon;
