import { alpha, makeStyles } from "@material-ui/core/styles";
import {
  BOX_SHADOW,
  GRAY_ONE,
  THEME_PRIMARY_COLOR,
  THEME_WEBSITE_BG_COLOR,
} from "../../../constants";

const useStyles = makeStyles((theme) => ({
  tab: {
    color: GRAY_ONE,
    fontSize: "15px",
    fontWeight: "500",
    padding: "10px 16px",
    cursor: "pointer",
    transition: "all .3s",
  },
  selectedTab: {
    color: THEME_PRIMARY_COLOR,
    fontSize: "15px",
    fontWeight: "500",
    padding: "10px 16px",
    background: `linear-gradient(to right, #fff 0%, ${alpha(
      THEME_PRIMARY_COLOR,
      0.2
    )} 100%)`,
    borderRight: `3px solid ${THEME_PRIMARY_COLOR}`,
  },
  faviconBox: {
    backgroundImage: "url(/tab.svg)",
    width: "332px",
    height: "45px",
    backgroundRepeat: "no-repeat",
    display: "flex",
    alignItems: "center",
    paddingTop: "14px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  faviconImgNameBox: {
    marginLeft: "88px",
    display: "flex",
    gap: "8px",
    alignItems: "center",
  },
  addBannerDialogBox: {
    width: "800px",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    maxHeight: "90vh",
  },
  designLayoutBox: {
    display: "flex",
    gap: "32px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  headersLogoBox: {
    display: "flex",
    gap: "1rem",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  bannersGridLayout: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: "24px",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr 1fr",
    },
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
    },
  },
}));

export default useStyles;
