import { alpha, AppBar, Tab, Tabs } from "@material-ui/core";
import React from "react";
import { BOX_SHADOW, THEME_SECONDARY_COLOR } from "../../constants";
import useStyles from "./styles";

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export const HorizontalTabs = ({ tabs = [], currentTab, setCurrentTab }) => {
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <AppBar position="static" color="default" style={{ boxShadow: BOX_SHADOW }}>
      <Tabs
        value={currentTab}
        onChange={handleChange}
        indicatorColor="secondary"
        // textColor="primary"
        variant="fullWidth"
        aria-label="full width tabs example"
      >
        {tabs.map((tab, index) => {
          const { name = "" } = tab || {};
          return (
            <Tab
              key={index}
              label={name}
              value={index}
              {...a11yProps(index)}
              style={{ background: index === currentTab && `linear-gradient(180deg, ${alpha(THEME_SECONDARY_COLOR, .05)} 15%, ${alpha(THEME_SECONDARY_COLOR, .2)} 100%)` }}
            />
          );
        })}
      </Tabs>
    </AppBar>
  );
};

export default HorizontalTabs;
