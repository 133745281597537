import {
  Button,
  MenuItem,
  TextField,
  Typography,
  alpha,
} from "@material-ui/core";
import React from "react";
import {
  THEME_WEBSITE_BG_COLOR,
  GRAY_FOUR,
  THEME_PRIMARY_COLOR,
} from "../../constants";
import useStyles from "./styles";

const DoctorsList = ({
  doctorsList = [],
  selectedDoctor = {},
  setSelectedDoctor,
  selectedDoctorCode = null,
  setIsAppointmentDialogOpen,
  setType,
  isAppointmentsLoading = false,
  areAppointments = false,
}) => {
  const classes = useStyles();

  const handleWalkInAppointment = () => {
    setType(2);
    setIsAppointmentDialogOpen(true);
  };

  return (
    <div
      style={{
        backgroundColor: THEME_WEBSITE_BG_COLOR,
        borderRight: `1px solid ${GRAY_FOUR}`,
      }}
    >
      <Typography
        variant="subtitle2"
        style={{
          fontSize: "18px",
          fontWeight: "500",
          borderBottom: `1px solid ${GRAY_FOUR}`,
          padding: "8px 16px",
        }}
      >
        Select Doctor
      </Typography>
      <div style={{ padding: "16px" }}>
        <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
          <div
            style={{
              borderRadius: "16px",
              backgroundColor: alpha(THEME_PRIMARY_COLOR, 0.05),
              height: "100px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className={classes.doctorProfileImg}></div>
          </div>
          <TextField
            id="standard-select-currency"
            select
            // label="Select"
            value={selectedDoctor}
            onChange={(e) => setSelectedDoctor(e.target.value)}
            style={{ width: "100%" }}
            SelectProps={{ classes: { select: classes.doctorSelect } }}
          >
            {doctorsList.map((row) => {
              const { doctorDetails = {}, isActive = false } = row || {};
              const { name: doctorName = "", code = null } =
                doctorDetails || {};
              const isSelected = selectedDoctorCode === code;
              return (
                <MenuItem
                  key={code}
                  value={row}
                  style={{
                    fontSize: "14px",
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  {isSelected ? (
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.9995 10C14.4848 10 16.4995 7.98528 16.4995 5.5C16.4995 3.01472 14.4848 1 11.9995 1C9.51423 1 7.49951 3.01472 7.49951 5.5C7.49951 7.98528 9.51423 10 11.9995 10Z"
                        fill="black"
                      />
                      <path
                        d="M11.84 14.8903L9.84956 13.2317C10.5421 13.08 11.2615 13 11.9995 13C12.7378 13 13.4575 13.08 14.1502 13.2319L12.16 14.8903C12.0673 14.9676 11.9327 14.9676 11.84 14.8903Z"
                        fill="black"
                      />
                      <path
                        d="M15.25 14.2679L13.1203 16.0427C12.4713 16.5835 11.5287 16.5835 10.8797 16.0427L8.75 14.2679V16.3535C9.90425 16.68 10.75 17.7412 10.75 19V20C10.75 20.6903 10.1904 21.25 9.50001 21.25C9.0858 21.25 8.75001 20.9142 8.75 20.5C8.75 20.1735 8.9587 19.8956 9.25 19.7927L9.25 19C9.25 18.3096 8.69036 17.75 8 17.75C7.30964 17.75 6.75 18.3096 6.75 19L6.75 19.7927C7.0413 19.8956 7.25 20.1734 7.25 20.5C7.25 20.9142 6.91421 21.25 6.5 21.25C5.80964 21.25 5.25 20.6904 5.25 20L5.25 19C5.25 17.7412 6.09575 16.68 7.25 16.3535V14.1977C4.15096 15.8734 2.03703 19.1379 2 22.8995C1.99946 22.9548 2.04428 23 2.09951 23H21.8995C21.9547 23 21.9996 22.9548 21.999 22.8995C21.962 19.1383 19.8485 15.8741 16.75 14.1982V16.3535C17.9043 16.68 18.75 17.7412 18.75 19C18.75 20.5188 17.5188 21.75 16 21.75C14.4812 21.75 13.25 20.5188 13.25 19C13.25 17.7412 14.0957 16.68 15.25 16.3535V14.2679Z"
                        fill="black"
                      />
                      <path
                        d="M14.75 19C14.75 18.3096 15.3096 17.75 16 17.75C16.6904 17.75 17.25 18.3096 17.25 19C17.25 19.6904 16.6904 20.25 16 20.25C15.3096 20.25 14.75 19.6904 14.75 19Z"
                        fill="black"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.5 20.5C6.22386 20.5 6 20.2761 6 20L6 19C6 17.8954 6.89543 17 8 17C9.10457 17 10 17.8954 10 19V20C10 20.2761 9.77614 20.5 9.5 20.5M8 16.44V14M9 13.5L11.3598 15.4665C11.7307 15.7756 12.2693 15.7756 12.6402 15.4665L15 13.5M16 16.44V14M16.4995 5.5C16.4995 7.98528 14.4848 10 11.9995 10C9.51423 10 7.49951 7.98528 7.49951 5.5C7.49951 3.01472 9.51423 1 11.9995 1C14.4848 1 16.4995 3.01472 16.4995 5.5ZM2 22.8995C2.05391 17.423 6.51019 13 11.9995 13C17.4888 13 21.9451 17.423 21.999 22.8995C21.9996 22.9548 21.9547 23 21.8995 23H2.09951C2.04428 23 1.99946 22.9548 2 22.8995ZM18 19C18 20.1046 17.1046 21 16 21C14.8954 21 14 20.1046 14 19C14 17.8954 14.8954 17 16 17C17.1046 17 18 17.8954 18 19Z"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-linecap="round"
                      />
                    </svg>
                  )}
                  {doctorName}
                </MenuItem>
              );
            })}
          </TextField>
          {!isAppointmentsLoading && areAppointments && (
            <Button
              variant="contained"
              color="primary"
              style={{
                textTransform: "none",
                fontSize: "14px",
                padding: "8px",
                boxShadow: "none",
              }}
              onClick={handleWalkInAppointment}
            >
              Add walk in appointment
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default DoctorsList;
