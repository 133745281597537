import React from "react";

import Box from "@material-ui/core/Box";
import useStyles from "./styles";

const Footer = () => {
  const classes = useStyles();
  return (
    <Box
      className={classes.footer}
    >
      The content provided here and elsewhere on the Docfyn site is provided for
      general informational purposes only. It is not intended as, and Docfyn
      does not provide, medical advice, diagnosis or treatment. Always contact
      your healthcare provider directly with any questions you may have
      regarding your health or specific medical advice.
    </Box>
  );
};

export default Footer;
