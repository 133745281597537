import { Box, Typography } from "@material-ui/core";
import React from "react";
import { BOX_SHADOW, THEME_WEBSITE_BG_COLOR } from "../../constants";
import useStyles from "./styles";

const VerticalTabs = ({tabs = [], currentTab = 0, setCurrentTab}) => {
  const classes = useStyles();
  return (
    <Box
      style={{
        backgroundColor: THEME_WEBSITE_BG_COLOR,
        boxShadow: BOX_SHADOW,
        minWidth: "270px",
        height: "fit-content",
      }}
    >
      <Typography
        variant="subtitle1"
        style={{ fontSize: "16px", fontWeight: "500", padding: "16px" }}
      >
        Serene
      </Typography>
      <Box style={{}}>
        {tabs.map((map, index) => {
          const { name = "" } = map || {};
          const selected = index === currentTab;
          return (
            <Box
              className={selected ? classes.selectedTab : classes.tab}
              onClick={() => setCurrentTab(index)}
            >
              {name || ""}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default VerticalTabs;
