import React from "react";

import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { devConsoleLog } from "../../utility";

const MySnackBar = (props) => {
  devConsoleLog('MySnackBar', props)
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={Boolean(props.message)}
      onClose={() => props.setViewSnackbar({ message: null, type: null })}
      autoHideDuration={props.setDuration ? props.setDuration : 2000}
    >
      <Alert severity={props.variant || 'warning'} variant="filled">
        {props.message || ''}
      </Alert>
    </Snackbar>
  );
};

export default MySnackBar;
