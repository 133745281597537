import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import EditIcon from "@material-ui/icons/Edit";

import {
  API_ENDPOINT_BLOGS,
  API_ENDPOINT_ENTERPRISE,
  API_RESPONSE_UNAUTHORIZED_STATUS,
  API_SECTION_API,
  API_VERSION,
  GRAY_FIVE,
  GRAY_ONE,
  HARBOR_API_DOMAIN,
} from "../../constants";
import useApperanceStyles from "../Appearance/styles";
import BootstrapInput from "../Commons/BootstrapInput";
import WysiwygEditor from "../Commons/WysiwygEditor";
import useStyles from "./styles";
import {
  AppContext,
  ControlPanelContext,
  getThumborUrl,
  handleImageUpload,
} from "../../utility";
import axios from "axios";

const AddBlog = () => {
  const appearanceClasses = useApperanceStyles();
  const classes = useStyles();
  const navigate = useNavigate();
  const { setViewSnackbarMain, handleRefreshToken, mobileView } =
    useContext(AppContext);
  const {
    enterprise = {},
    AuthorizationToken,
    setHeaderHeading,
  } = useContext(ControlPanelContext);
  const { enterprise: selectedEnterprise = {} } = enterprise || {};
  const { code: enterpriseCode = null } = selectedEnterprise || {};
  const [blogInput, setBlogInput] = useState({});
  const [content, setContent] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(false);

  const handleReqBody = () => {
    return {
      data: [
        { content, title: blogInput["title"], imageUrl: blogInput["imageUrl"] },
      ],
    };
  };

  const addBlog = async () => {
    if (!enterpriseCode) return;
    const url = `${HARBOR_API_DOMAIN}${API_SECTION_API}${API_VERSION}/${API_ENDPOINT_ENTERPRISE}${enterpriseCode}/${API_ENDPOINT_BLOGS}`;
    const reqBody = handleReqBody();
    setIsLoading(true);
    try {
      const response = await axios.post(url, reqBody, {
        headers: {
          "Content-Type": "application/json",
          source: "website",
          Authorization: AuthorizationToken,
        },
      });
      const { data = {}, status = null } = response || {};
      const { result = [] } = data || {};
      if (status >= 200 && status < 300) {
        setViewSnackbarMain({ message: "Blog Published !!!", type: "success" });
        navigate("/blogs");
      }
    } catch (error) {
      const { response } = error || {};
      const { status } = response || {};
      if (status === API_RESPONSE_UNAUTHORIZED_STATUS) {
        handleRefreshToken();
      } else
        setViewSnackbarMain({
          message: "Something went wrong!",
          type: "error",
        });
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    setBlogInput((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleAddBlogImage = async (event) => {
    setIsImageLoading(true);
    const image = await handleImageUpload(event);
    if (image) {
      const {
        cdn_url: imageUrl = "",
        error = "",
        object_url: imageObjectUrl = "",
      } = image || {};
      if (error) {
        setIsImageLoading(false);
        setViewSnackbarMain({
          message: "Photo couldn't be uploaded",
          type: "error",
        });
      } else {
        setBlogInput((prev) => ({
          ...prev,
          imageUrl,
          imageObjectUrl,
        }));
        setIsImageLoading(false);
      }
    }
  };

  useEffect(() => {
    setHeaderHeading(
      <Box style={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <KeyboardBackspaceIcon
          style={{ cursor: "pointer" }}
          onClick={() => navigate(-1)}
        />{" "}
        Add blog
      </Box>
    );
  }, []);

  if (mobileView)
    return (
      <Box className={appearanceClasses.tabContentBox}>
        <Box className={appearanceClasses.designBox}>
          <Typography
            variant="subtitle1"
            className={appearanceClasses.designBoxHeading}
          >
            Blog post
          </Typography>
          <FormControl>
            <InputLabel
              shrink
              htmlFor="title"
              style={{ fontSize: "20px", color: GRAY_FIVE }}
            >
              Title
            </InputLabel>
            <BootstrapInput
              name="title"
              onChange={handleInputChange}
              value={blogInput["title"]}
              placeholder="Add a title to your blog"
              id="title"
            />
          </FormControl>
          <Box>
            <Typography variant="subtitle2" className={classes.blogLabel}>
              Content
            </Typography>
            <WysiwygEditor value={content} setValue={setContent} />
          </Box>
        </Box>
        <Box
          className={appearanceClasses.designBox}
        >
          <Box>
            <Typography
              variant="subtitle1"
              className={appearanceClasses.designBoxHeading}
            >
              Blog information
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle2" className={classes.blogLabel}>
              Featured Image
            </Typography>
            <Box
              style={{
                //   width: "320px",
                height: "160px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: `1px dashed ${GRAY_ONE}`,
                borderRadius: "6px",
                cursor: "pointer",
                color: GRAY_ONE,
                flexDirection: "column",
              }}
              component="label"
            >
              {!isImageLoading && blogInput["imageUrl"] ? (
                <Box
                  style={{
                    height: "100%",
                    width: "100%",
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={getThumborUrl(blogInput["imageUrl"], 160, 0)}
                    alt="blog-img"
                    style={{ filter: "brightness(70%)" }}
                  />
                  <IconButton
                    style={{
                      position: "absolute",
                      left: "50%",
                      top: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                    component="label"
                  >
                    <EditIcon fontSize="large" style={{ color: "#fff" }} />
                    <input type="file" hidden onChange={handleAddBlogImage} />
                  </IconButton>
                  <input type="file" hidden onChange={handleAddBlogImage} />
                </Box>
              ) : (
                <>
                  {isImageLoading ? (
                    <CircularProgress color="primary" />
                  ) : (
                    <>
                      <AddIcon style={{ fontSize: "32px" }} />
                      <Typography variant="body1" style={{ fontWeight: "300" }}>
                        Add Image
                      </Typography>
                      <input type="file" hidden onChange={handleAddBlogImage} />
                    </>
                  )}
                </>
              )}
            </Box>
          </Box>
          <FormControl>
            <InputLabel
              shrink
              htmlFor="author"
              style={{ fontSize: "20px", color: GRAY_FIVE }}
            >
              Author Name (Optional)
            </InputLabel>
            <BootstrapInput
              name="author"
              // onChange={handleInputChange}
              // value={input["name"]}
              placeholder="Add author name"
              id="author"
            />
          </FormControl>
        </Box>
        <Box style={{ textAlign: "end" }}>
          <Button
            variant="contained"
            color="primary"
            style={{
              textTransform: "none",
              fontSize: "14px",
              width: "160px",
            }}
            onClick={addBlog}
          >
            {isLoading ? <CircularProgress size={24} /> : "Publish blog"}
          </Button>
        </Box>
      </Box>
    );
  return (
    <div style={{ display: "flex", gap: "32px" }}>
      <Box className={appearanceClasses.tabContentBox}>
        <Box className={appearanceClasses.designBox}>
          <Typography
            variant="subtitle1"
            className={appearanceClasses.designBoxHeading}
          >
            Blog post
          </Typography>
          <FormControl>
            <InputLabel
              shrink
              htmlFor="title"
              style={{ fontSize: "20px", color: GRAY_FIVE }}
            >
              Title
            </InputLabel>
            <BootstrapInput
              name="title"
              onChange={handleInputChange}
              value={blogInput["title"]}
              placeholder="Add a title to your blog"
              id="title"
            />
          </FormControl>
          <Box>
            <Typography variant="subtitle2" className={classes.blogLabel}>
              Content
            </Typography>
            <WysiwygEditor value={content} setValue={setContent} />
          </Box>
        </Box>
        <Box style={{ textAlign: "end" }}>
          <Button
            variant="contained"
            color="primary"
            style={{
              textTransform: "none",
              fontSize: "14px",
              width: "160px",
            }}
            onClick={addBlog}
          >
            {isLoading ? <CircularProgress size={24} /> : "Publish blog"}
          </Button>
        </Box>
      </Box>
      <Box
        className={appearanceClasses.designBox}
        style={{
          maxWidth: "400px",
          minWidth: "360px",
          alignSelf: "self-start",
        }}
      >
        <Box>
          <Typography
            variant="subtitle1"
            className={appearanceClasses.designBoxHeading}
          >
            Blog information
          </Typography>
        </Box>
        <Box>
          <Typography variant="subtitle2" className={classes.blogLabel}>
            Featured Image
          </Typography>
          <Box
            style={{
              //   width: "320px",
              height: "160px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: `1px dashed ${GRAY_ONE}`,
              borderRadius: "6px",
              cursor: "pointer",
              color: GRAY_ONE,
              flexDirection: "column",
            }}
            component="label"
          >
            {!isImageLoading && blogInput["imageUrl"] ? (
              <Box
                style={{
                  height: "100%",
                  width: "100%",
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={getThumborUrl(blogInput["imageUrl"], 160, 0)}
                  alt="blog-img"
                  style={{ filter: "brightness(70%)" }}
                />
                <IconButton
                  style={{
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                  component="label"
                >
                  <EditIcon fontSize="large" style={{ color: "#fff" }} />
                  <input type="file" hidden onChange={handleAddBlogImage} />
                </IconButton>
                <input type="file" hidden onChange={handleAddBlogImage} />
              </Box>
            ) : (
              <>
                {isImageLoading ? (
                  <CircularProgress color="primary" />
                ) : (
                  <>
                    <AddIcon style={{ fontSize: "32px" }} />
                    <Typography variant="body1" style={{ fontWeight: "300" }}>
                      Add Image
                    </Typography>
                    <input type="file" hidden onChange={handleAddBlogImage} />
                  </>
                )}
              </>
            )}
          </Box>
        </Box>
        <FormControl>
          <InputLabel
            shrink
            htmlFor="author"
            style={{ fontSize: "20px", color: GRAY_FIVE }}
          >
            Author Name (Optional)
          </InputLabel>
          <BootstrapInput
            name="author"
            // onChange={handleInputChange}
            // value={input["name"]}
            placeholder="Add author name"
            id="author"
          />
        </FormControl>
      </Box>
    </div>
  );
};

export default AddBlog;
