import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { Typography } from "@material-ui/core";
import UndoIcon from "@material-ui/icons/Undo";
import useStyles from "./styles";
import OutlinedButton from "../Commons/OutlinedButton";
import PrimaryButton from "../Commons/PrimaryButton";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ConfirmUnpublish = ({review, updateReview}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmation = () => {
    const { code = null } = review || {};
    const updatedReview = { code };
    updatedReview["isPublished"] = false;
    updateReview(updatedReview, handleClose);
  }

  return (
    <div>
      <Typography
        variant="subtitle2"
        className={classes.reviewsListReviewAction}
        onClick={handleClickOpen}
      >
        <UndoIcon /> Unpublish
      </Typography>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        classes={{paper: classes.reviewsActionConfirmDialog}}
      >
        <DialogTitle id="alert-dialog-slide-title">
          Unpublish review?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to unpublish this review?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <OutlinedButton onClick={handleClose}>Cancel</OutlinedButton>
          <PrimaryButton onClick={handleConfirmation}>Confirm</PrimaryButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConfirmUnpublish;
