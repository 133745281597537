import React, { useContext, useEffect, useState } from "react";

import {
  alpha,
  Box,
  Button,
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import {
  LIGHT_BLUE_COLOR,
  THEME_PRIMARY_COLOR,
  THEME_WEBSITE_BG_COLOR,
  THEME_WEBSITE_SECONDARY_BG_COLOR,
} from "../../constants";

import LocalStorage from "../../localStorage";
import {
  AppContext,
  isEmptyObject,
} from "../../utility";

const useStyles = makeStyles((theme) => ({
  enterpriseSelectionLayout: {
    backgroundColor: THEME_PRIMARY_COLOR,
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    position: "relative",
    padding: "32px 16px",
  },
  enterpriseSelectionLayoutBox: {
    width: "540px",
    backgroundColor: THEME_WEBSITE_BG_COLOR,
    borderRadius: "8px",
    boxShadow: "0 2px 6px #1a181e0a",
    padding: "32px",
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  enterpriseDialogList: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    marginTop: "8px",
    boxShadow: "0 2px 6px #1a181e0a",
  },
  enterpriseDialogListItem: {
    backgroundColor: alpha(THEME_WEBSITE_SECONDARY_BG_COLOR, 1),
    borderRadius: "4px",
    padding: "8px 4px",
    marginRight: 0,
    transition: "all .1s",
    "&:hover": {
      backgroundColor: alpha(LIGHT_BLUE_COLOR, 1),
      // color: "white",
    },
  },
  enterpriseDialogSelectedListItem: {
    backgroundColor: alpha(LIGHT_BLUE_COLOR, 1),
  },
}));

const EnterpriseDetails = ({ enterprises = [], isEnterprise = false }) => {
  const classes = useStyles();
  const { setViewSnackbarMain } = useContext(AppContext);
  const [enterprisesObj, setEnterprisesObj] = useState({});
  const [selectedEnterprise, setSelectedEnterprise] = useState({});
  const loggedInUser = LocalStorage.getUser() || {};
  const { enterprise: chosenEnterprise = {} } = selectedEnterprise || {};
  const { code: selectedEnterpriseCode = null } = chosenEnterprise || {};

  const handleEnterpriseRadioInput = (event) => {
    setSelectedEnterprise(enterprisesObj[event.target.value]);
  };

  //   const handleEnterpriseForInvitedUser = (enterpriseList = []) => {
  //     const inviteeEnterprise = enterpriseList.find((item) => {
  //       const { enterprise = {} } = item || {};
  //       const { code = null } = enterprise || {};
  //       return code === enterpriseCode;
  //     });
  //     const flag = LocalStorage.setUser({
  //       ...loggedInUser,
  //       enterprise: inviteeEnterprise,
  //     });
  //     if (flag) {
  //       window.location.href = "/home";
  //     }
  //   };

  const handleDefaultEnterpriseSelection = () => {
    setSelectedEnterprise(enterprises[0]);
  };

  const handleEnterpriseSelection = () => {
    if (isEmptyObject(selectedEnterprise)) return;
    const flag = LocalStorage.setUser({
      ...loggedInUser,
      enterprise: selectedEnterprise,
    });
    if (flag) {
      window.location.href = "/home";
    }
  };

  const handleOrderingEnterpriseByCode = () => {
    const obj = {};
    enterprises.forEach((item) => {
      const { enterprise = {} } = item || {};
      const { code = null } = enterprise || {};
      obj[code] = item;
    });
    setEnterprisesObj(obj);
  };

  useEffect(() => {
    if (enterprises.length > 0) {
      handleDefaultEnterpriseSelection();
      handleOrderingEnterpriseByCode();
    }
  }, [enterprises]);

  return (
    <div className={classes.enterpriseSelectionLayout}>
      <Box
        className={classes.enterpriseSelectionLayoutBox}
      >
        <Box style={{ display: "flex", justifyContent: "center" }}>
          <img alt="logo" src="/logo.svg" style={{ width: "8rem" }} />
        </Box>
        <Box>
          <Typography variant="h6">Select Clinic</Typography>
        </Box>
        <Box>
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={selectedEnterpriseCode}
            style={{ gap: "20px" }}
            onChange={handleEnterpriseRadioInput}
          >
            {enterprises.map((item) => {
              const { enterprise = {}, is_owner: isOwner = false } = item || {};
              const { code = null, name = "" } = enterprise || {};
              return (
                <FormControlLabel
                  key={code}
                  value={code}
                  control={<Radio color="primary" />}
                  label={
                    <Box style={{width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                      <Typography variant="subtitle1">{name || ""}</Typography>
                      {/* <Typography variant="subtitle2">{isOwner ? "Owner" : ""}</Typography> */}
                    </Box>
                  }
                  className={`${classes.enterpriseDialogListItem} ${
                    selectedEnterpriseCode === code
                      ? classes.enterpriseDialogSelectedListItem
                      : ""
                  }`}
                />
              );
            })}
            {/* <FormControlLabel
                  value="2"
                  control={<Radio color="primary" />}
                  label="Enterprise 2"
                  className={classes.enterpriseDialogListItem}
                />
                <FormControlLabel
                  value="3"
                  control={<Radio color="primary" />}
                  label="Enterprise 3"
                  className={classes.enterpriseDialogListItem}
                /> */}
          </RadioGroup>
        </Box>
        <Box style={{ textAlign: "end", marginTop: "24px" }}>
          <Button
            variant="contained"
            color="primary"
            disabled={isEmptyObject(selectedEnterprise)}
            style={{ textTransform: "none", fontSize: "16px" }}
            onClick={handleEnterpriseSelection}
          >
            Continue
          </Button>
        </Box>
      </Box>
    </div>
  );
};

export default EnterpriseDetails;
