import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  photosGridLayout: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: "24px",
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
    },
  },
  galleryLayoutBox: {
    display: "flex",
    gap: "32px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: 'column',
    },
  },
}));

export default useStyles;
