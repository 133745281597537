import { Box, Button, CircularProgress, Typography } from "@material-ui/core";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  API_ENDPOINT_BLOGS,
  API_ENDPOINT_ENTERPRISE,
  API_RESPONSE_UNAUTHORIZED_STATUS,
  API_SECTION_API,
  API_VERSION,
  BOX_SHADOW,
  GRAY_ONE,
  HARBOR_API_DOMAIN,
  THEME_WEBSITE_BG_COLOR,
} from "../../constants";
import { AppContext, ControlPanelContext } from "../../utility";
import BlogsList from "./BlogsList";

const Blogs = () => {
  const navigate = useNavigate();
  const { handleRefreshToken, setViewSnackbarMain, mobileView } = useContext(AppContext)
  const {
    enterprise = {},
    AuthorizationToken,
    setHeaderHeading,
  } = useContext(ControlPanelContext);
  const { enterprise: selectedEnterprise = {} } = enterprise || {};
  const { code: enterpriseCode = null } = selectedEnterprise || {};
  const [blogs, setBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getBlogsData = async () => {
    const url = `${HARBOR_API_DOMAIN}${API_SECTION_API}${API_VERSION}/${API_ENDPOINT_ENTERPRISE}${enterpriseCode}/${API_ENDPOINT_BLOGS}`;
    setIsLoading(true);
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          source: "website",
          Authorization: AuthorizationToken,
        },
      });
      const { data = {}, status = null } = response || {};
      const { result = [] } = data || {};
      if (status >= 200 && status < 300) {
        setBlogs(result);
      }
    } catch (error) {
      const { response } = error || {};
      const { status } = response || {};
      if (status === API_RESPONSE_UNAUTHORIZED_STATUS) {
        handleRefreshToken();
      } else
        setViewSnackbarMain({
          message: "Something went wrong!",
          type: "error",
        });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setHeaderHeading("Blogs");
  }, []);

  useEffect(() => {
    if (enterpriseCode) getBlogsData();
  }, [enterpriseCode]);

  if (isLoading)
    return (
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  else if (blogs.length > 0) return <BlogsList blogs={blogs} />;
  else
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: '100%'
        }}
      >
        <Box
          style={{
            backgroundColor: THEME_WEBSITE_BG_COLOR,
            boxShadow: BOX_SHADOW,
            borderRadius: "4px",
            width: mobileView ? "100%" : "800px",
            padding: mobileView ? "32px" : "64px",
            display: "flex",
            flexDirection: "column",
            gap: "32px",
            alignItems: "center",
            maxHeight: "80vh",
          }}
        >
          <img
            alt="add-blog"
            src="/add-blogs.svg"
            style={{ height: "360px", width: "100%", objectFit: "contain" }}
          />
          <Box>
            <Typography
              variant="subtitle1"
              style={{ fontSize: "20px", fontWeight: "500" }}
              align="center"
            >
              Add blog to your e-clinic
            </Typography>
            <Typography
              variant="subtitle2"
              align="center"
              style={{ fontSize: "16px", color: GRAY_ONE, fontWeight: "400" }}
            >
              Engage with your patients by sharing your expertise and building a
              story around your clinic
            </Typography>
          </Box>
          <Button
            variant="contained"
            color="primary"
            style={{ textTransform: "none", width: "160px" }}
            onClick={() => navigate("/blogs/add")}
          >
            Add blog post
          </Button>
        </Box>
      </div>
    );
};

export default Blogs;
