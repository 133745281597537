import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  FormControl,
  InputLabel,
  makeStyles,
  Slide,
  Typography,
} from "@material-ui/core";
import React from "react";
import { GRAY_FIVE, GRAY_ONE, GRAY_TWO } from "../../../constants";
import { getThumborUrl } from "../../../utility";
import BootstrapInput from "../../Commons/BootstrapInput";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  addBannerDialogBox: {
    width: "800px",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    maxHeight: "90vh",
  },
}));

const BannerDialog = ({
  isOpen = false,
  handleClose,
  banners = [],
  handleInputChange,
  addBanner,
  currentBannerIndex = 0,
  handleAddBanner,
  bannerInput = {},
  handleBannerImageUpload,
  isImageLoading,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      //   keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      classes={{ paper: classes.addBannerDialogBox }}
    >
      <Box>
        <Typography variant="h6">Upload banner</Typography>
      </Box>
      <Box
        style={{
          width: "100%",
          // minHeight: '200px',
          height: "298px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: `1px dashed ${GRAY_ONE}`,
          borderRadius: "6px",
          color: GRAY_ONE,
          flexDirection: "column",
          gap: "16px",
        }}
      >
        {isImageLoading ? (
          <CircularProgress color="primary" />
        ) : bannerInput["imageUrl"] ? (
          <Box
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
          >
            <img
              src={getThumborUrl(bannerInput["imageUrl"], 298, 0)}
              alt="banner-img"
              style={{ filter: "brightness(75%)", height: '100%', width: '100%', objectFit: 'cover', objectPosition: 'center' }}
            />
            <Box
              style={{
                padding: "8px 16px",
                border: `1px solid ${GRAY_TWO}`,
                borderRadius: "4px",
                color: GRAY_FIVE,
                cursor: "pointer",
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
                backgroundColor: "#ffffff",
              }}
              component="label"
            >
              Upload image{" "}
              <input type="file" hidden onChange={handleBannerImageUpload} />
            </Box>
          </Box>
        ) : (
          <>
            <Box
              style={{
                padding: "8px 16px",
                border: `1px solid ${GRAY_TWO}`,
                borderRadius: "4px",
                color: GRAY_FIVE,
                cursor: "pointer",
              }}
              component="label"
            >
              Upload image{" "}
              <input type="file" hidden onChange={handleBannerImageUpload} />
            </Box>
            <Typography
              variant="subtitle2"
              style={{ fontSize: "14px", fontWeight: "400", color: GRAY_ONE }}
            >
              Recommended size (1296px*700px)
            </Typography>
          </>
        )}
      </Box>

      <FormControl style={{ width: "100%" }}>
        <InputLabel
          shrink
          htmlFor="name"
          style={{ fontSize: "20px", color: GRAY_FIVE }}
        >
          Banner Title
        </InputLabel>
        <BootstrapInput
          name="title"
          placeholder="Enter an eye catchy title"
          onChange={(e) => handleInputChange(e, currentBannerIndex)}
          value={bannerInput["title"]}
          id="title"
        />
      </FormControl>
      <FormControl style={{ width: "100%" }}>
        <InputLabel
          shrink
          htmlFor="name"
          style={{ fontSize: "20px", color: GRAY_FIVE }}
        >
          Banner Text
        </InputLabel>
        <BootstrapInput
          name="text"
          placeholder="Enter a captivating banner tagline"
          onChange={(e) => handleInputChange(e, currentBannerIndex)}
          value={bannerInput["text"]}
          id="text"
        />
      </FormControl>
      <Box style={{ textAlign: "center" }}>
        <Button
          variant="contained"
          color="primary"
          style={{
            textTransform: "none",
            fontSize: "14px",
            width: "160px",
          }}
          onClick={handleAddBanner}
        >
          Add banner
        </Button>
      </Box>
    </Dialog>
  );
};

export default BannerDialog;
