import { Typography } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import { GRAY_ONE } from "../../constants";
import { AppContext, ControlPanelContext } from "../../utility";

const ComingSoon = () => {
  const { mobileView } = useContext(AppContext);
  const { setHeaderHeading } = useContext(ControlPanelContext);

  useEffect(() => {
    setHeaderHeading('');
  }, []);

  return (
    <div
      style={{
        height: "calc(100% - 72px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        // gap: "2rem",
      }}
    >
      <img
        src="/coming-soon.png"
        alt="coming-soon"
        style={{ width: mobileView ? "100%" : "800px" }}
      />
      <Typography
        variant="subtitle1"
        align="center"
        style={{ color: GRAY_ONE, fontSize: "1.6rem", fontWeight: '500' }}
      >
        Stay Connected, Stay Updated!
      </Typography>
    </div>
  );
};

export default ComingSoon;
