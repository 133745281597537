import React from "react";
import ReactDOM from "react-dom";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import "./index.css";
// import AppLayout from './components/AppLayout';
// import Routes from './Routes';
// import Layout from './layout';
import {
  THEME_SECONDARY_COLOR,
  THEME_PRIMARY_COLOR,
  GRAY_ONE,
} from "./constants";
import * as serviceWorker from "./serviceWorker";
import App from "./App";

const theme = createTheme({
  palette: {
    primary: {
      main: THEME_PRIMARY_COLOR, //'#428bfe'
    },
    secondary: {
      main: THEME_SECONDARY_COLOR,
    },
    error: {
      main: "#ED3A4F"
    },
    text: {
      primary: "#1a181e", //'#212121',  //'#7c3647',  //'rgba(124, 54, 71, 1)',
      secondary: GRAY_ONE, //'#384E89',  //'#FBAD23',  //'#ffffff',   //'rgba(255, 255, 255, 1)',
      paragraph: "rgba(0, 0, 0, 0.54)",
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.54)",
    },
  },
  typography: {
    fontFamily: 'Ubuntu, "Titillium Web", sans-serif',
  },
  components: {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: GRAY_ONE,
          //   backgroundColor: 'orange'
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  // overrides: {
  //     MuiCssBaseline: {
  //       '@global': {
  //         '@font-face': ['Titillium Web', 'sans-serif'],
  //       },
  //     },
  //   },
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
