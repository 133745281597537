import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PermIdentityOutlinedIcon from "@material-ui/icons/PermIdentityOutlined";
import MenuIcon from "@material-ui/icons/Menu";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import FaceIcon from "@material-ui/icons/Face";
import EditIcon from "@material-ui/icons/Edit";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import useStyles from "./styles";
import { AppContext } from "../utility";
import { USER_TYPE } from "../constants";
import {
  Collapse,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import LocalStorage from "../localStorage";

const navMenu = [
  {
    text: "Browse",
    link: "/",
  },
  {
    text: "List your practice",
    link: "/list-your-practice",
  },
];

const NavBar = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isUserProfileOpen, setIsUserProfileOpen] = useState(false);
  const navigate = useNavigate();
  const {
    isLoggedIn,
    userProfile = {},
    deskTopView = true,
  } = useContext(AppContext);
  const { entityCode = null, name = "", type = null } = userProfile || {};

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const redirectToLoginOrSignupRoute = (route) => {
    setAnchorEl(null);
    setIsDrawerOpen(false);
    navigate(route);
  };

  const handleUserProfileNavigation = () => {
    setAnchorEl(null);
    if (USER_TYPE[type] === "patient") {
      navigate(`/profile/${entityCode}`);
    } else if (USER_TYPE[type] === "doctor") {
      navigate(`/doctor-profile/${entityCode}`);
    }
  };

  const handleLogout = () => {
    const isDeleted = LocalStorage.deleteUser();
    if (isDeleted) window.location.href = "/login";
  };

  return (
    <div
      className={`${classes.navbar} ${!deskTopView ? classes.mWebNavbar : ""}`}
    >
      <img
        alt="company_logo"
        className={classes.logo}
        src="/logo.svg"
        onClick={() => navigate("/")}
      />
      <div>
        {deskTopView ? (
          <ul className={classes.navList}>
            <li
              key="Browse"
              style={{
                paddingRight: "32px",
                borderRight: "1px solid #333333",
                paddingLeft: 0,
                cursor: "pointer",
              }}
              onClick={() => redirectToLoginOrSignupRoute("/")}
            >
              Browse
            </li>
            {!isLoggedIn && (
              <li
                key="List your practice"
                style={{
                  paddingRight: "32px",
                  borderRight: "1px solid #333333",
                  paddingLeft: "32px",
                  cursor: "pointer",
                }}
                onClick={() =>
                  redirectToLoginOrSignupRoute("/list-your-practice")
                }
              >
                List your practice
              </li>
            )}
            {isLoggedIn ? (
              <li
                style={{
                  paddingLeft: "32px",
                  cursor: "pointer",
                }}
              >
                <Box
                  aria-controls="profile-menu"
                  aria-haspopup="true"
                  style={{ display: "flex", alignItems: "center", gap: "4px" }}
                  onClick={handleMenuClick}
                >
                  <PermIdentityOutlinedIcon style={{ fontSize: "1.6rem" }} />
                  <Typography variant="h5" style={{ fontSize: "1rem" }}>
                    {name}
                  </Typography>
                  <ExpandMoreIcon />
                </Box>
                <Menu
                  id="profile-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                  style={{ marginTop: "48px", padding: "0px" }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <MenuItem
                    style={{ padding: "8px 32px" }}
                    onClick={handleUserProfileNavigation}
                  >
                    My profile
                  </MenuItem>
                  <MenuItem
                    style={{ padding: "8px 32px" }}
                    onClick={() =>
                      redirectToLoginOrSignupRoute(
                        `/update-profile/${entityCode}`
                      )
                    }
                  >
                    Edit profile
                  </MenuItem>
                  <MenuItem
                    onClick={handleLogout}
                    style={{ padding: "8px 32px" }}
                  >
                    Logout
                  </MenuItem>
                </Menu>
              </li>
            ) : (
              <li style={{ paddingLeft: "32px" }}>
                <Box
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={handleMenuClick}
                  className={classes.navbarLoginMenu}
                >
                  Login / Sign up
                  <ExpandMoreIcon color="primary" />
                </Box>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                  style={{ marginTop: "48px", padding: "0px" }}
                >
                  <MenuItem className={classes.onHoverMenuItem}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "12px",
                      }}
                    >
                      <Typography
                        variant="h5"
                        style={{ fontSize: "1rem", fontWeight: "600" }}
                      >
                        Patients
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        <Button
                          style={{
                            textTransform: "none",
                          }}
                          className={classes.loginMenuButton}
                          onClick={() => redirectToLoginOrSignupRoute("/login")}
                        >
                          Log in
                        </Button>
                        <Button
                          className={classes.loginMenuButton}
                          style={{ textTransform: "none" }}
                          onClick={() =>
                            redirectToLoginOrSignupRoute("/sign-up")
                          }
                        >
                          Sign up
                        </Button>
                      </div>
                    </div>
                  </MenuItem>
                  <MenuItem className={classes.onHoverMenuItem}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "12px",
                      }}
                    >
                      <Typography
                        variant="h5"
                        style={{ fontSize: "1rem", fontWeight: "600" }}
                      >
                        Doctors
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        <Button
                          className={classes.loginMenuButton}
                          style={{ textTransform: "none" }}
                          onClick={() => redirectToLoginOrSignupRoute("/login")}
                        >
                          Log in
                        </Button>
                      </div>
                    </div>
                  </MenuItem>
                </Menu>
              </li>
            )}
          </ul>
        ) : (
          <Box>
            <MenuIcon
              style={{ cursor: "pointer" }}
              onClick={() => setIsDrawerOpen(true)}
            />
            <Drawer
              anchor="right"
              open={isDrawerOpen}
              onClose={() => setIsDrawerOpen(false)}
            >
              <Box style={{ width: "15rem" }}>
                <List>
                  {navMenu.map((navItem) => {
                    const { text = "", link = "/" } = navItem || {};
                    return (
                      <ListItem
                        button
                        onClick={() => redirectToLoginOrSignupRoute(link)}
                      >
                        <ListItemText primary={text} />
                      </ListItem>
                    );
                  })}
                  <ListItem
                    button
                    onClick={() => setIsUserProfileOpen((prev) => !prev)}
                  >
                    <ListItemIcon>
                      <PermIdentityOutlinedIcon
                        style={{ fontSize: "1.6rem" }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={isLoggedIn ? name : "Login / Signup"}
                    />
                    {isUserProfileOpen ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  {isLoggedIn ? (
                    <Collapse
                      in={isUserProfileOpen}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" disablePadding>
                        <ListItem
                          button
                          className={classes.nested}
                          onClick={handleUserProfileNavigation}
                        >
                          <ListItemIcon>
                            <FaceIcon />
                          </ListItemIcon>
                          <ListItemText
                            classes={{ primary: classes.collapsedListItemText }}
                            primary="My profile"
                          />
                        </ListItem>
                        <ListItem
                          button
                          className={classes.nested}
                          onClick={() =>
                            redirectToLoginOrSignupRoute(
                              `/update-profile/${entityCode}`
                            )
                          }
                        >
                          <ListItemIcon>
                            <EditIcon />
                          </ListItemIcon>
                          <ListItemText
                            classes={{ primary: classes.collapsedListItemText }}
                            primary="Edit profile"
                          />
                        </ListItem>
                        <ListItem
                          button
                          className={classes.nested}
                          onClick={handleLogout}
                        >
                          <ListItemIcon>
                            <ExitToAppIcon />
                          </ListItemIcon>
                          <ListItemText
                            classes={{ primary: classes.collapsedListItemText }}
                            primary="Logout"
                          />
                        </ListItem>
                      </List>
                    </Collapse>
                  ) : (
                    <Collapse
                      in={isUserProfileOpen}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" disablePadding>
                        <ListItem
                          button
                          className={classes.nested}
                          onClick={() => redirectToLoginOrSignupRoute(`/login`)}
                        >
                          <ListItemText
                            inset
                            classes={{ primary: classes.collapsedListItemText }}
                            primary="Log in"
                          />
                        </ListItem>
                        <ListItem
                          button
                          intent
                          className={classes.nested}
                          onClick={() =>
                            redirectToLoginOrSignupRoute(`/sign-up`)
                          }
                        >
                          {/* <ListItemIcon>
                            <ExitToAppIcon />
                          </ListItemIcon> */}
                          <ListItemText
                            inset
                            classes={{ primary: classes.collapsedListItemText }}
                            primary="Sign up"
                          />
                        </ListItem>
                      </List>
                    </Collapse>
                  )}
                </List>
              </Box>
            </Drawer>
          </Box>
        )}
      </div>
    </div>
  );
};

export default NavBar;
