import { alpha, makeStyles } from "@material-ui/core/styles";
import {
  GRAY_FIVE,
  GRAY_ONE,
  LIGHT_BLUE_COLOR,
  THEME_PRIMARY_COLOR,
  THEME_SECONDARY_COLOR,
  THEME_WEBSITE_BG_COLOR,
  THEME_WEBSITE_SECONDARY_BG_COLOR,
} from "../../constants";

const useStyles = makeStyles((theme) => ({
  starsContainer: {
    gap: "4px",
  },
  starBox: {
    // padding: '2px',
    backgroundColor: THEME_SECONDARY_COLOR,
    color: "white",
    fontSize: "1rem",
  },
  emptyStar: {
    color: "#862B0D",
    opacity: ".15",
  },
  dialogBox: {
    padding: "12px 16px",
    width: "100%",
    borderRadius: "8px",
  },
  enterpriseDialogList: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    marginTop: "8px",
    boxShadow: "0 2px 6px #1a181e0a",
  },
  enterpriseDialogListItem: {
    backgroundColor: alpha(THEME_WEBSITE_SECONDARY_BG_COLOR, 1),
    borderRadius: "4px",
    transition: "all .1s",
    "&:hover": {
      backgroundColor: alpha(THEME_PRIMARY_COLOR, 1),
      color: "white",
    },
  },
  enterpriseSvg: {
    // mask: "url(/e-clinic.svg) no-repeat center / contain",
    height: "40px",
    width: "40px",
    // backgroundColor: GRAY_ONE,
  },
  enterpriseMenu: {
    width: "320px",
    top: "48px !important",
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      left: '16px',
      right: '16px'
    }
  },
  enterpriseMenuList: {
    padding: "16px 20px",
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
  enterpriseMenuItem: {
    borderRadius: "4px",
    fontSize: "16px",
    padding: "12px 16px",
    backgroundColor: "rgba(0, 0, 0, 0.04)",
    border: `1px solid transparent`,
    transition: "all .3s",
    "&:hover": {
      border: `1px solid rgba(0, 0, 0, 0.1)`,
      backgroundColor: "rgba(0, 0, 0, 0.05)",
    },
  },
  selectedEnterpriseMenuItem: {
    backgroundColor: alpha(LIGHT_BLUE_COLOR, 0.7),
    border: `1px solid ${alpha(THEME_PRIMARY_COLOR, 0.5)}`,
    color: THEME_PRIMARY_COLOR,
  },
  enterpriseMenuCreateButton: {
    textTransform: "none",
  },
  tab: {
    color: GRAY_ONE,
    fontSize: "15px",
    fontWeight: "500",
    padding: "10px 16px",
    cursor: "pointer",
    transition: "all .3s",
  },
  selectedTab: {
    color: THEME_PRIMARY_COLOR,
    fontSize: "15px",
    fontWeight: "500",
    padding: "10px 16px",
    background: `linear-gradient(to right, #fff 0%, ${alpha(
      THEME_PRIMARY_COLOR,
      0.2
    )} 100%)`,
    borderRight: `3px solid ${THEME_PRIMARY_COLOR}`,
  },
  changeEnterpriseHeader: {
    display: "flex",
    alignItems: "center",
    gap: "4px",
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      color: theme.palette.text.primary,
      gap: "8px",
    },
  },
  launchIcon: {
    cursor: "pointer",
    color: THEME_WEBSITE_SECONDARY_BG_COLOR,
    fontSize: "1.2rem",
    [theme.breakpoints.down("sm")]: {
      color: theme.palette.text.primary,
    },
  },
  menuOpenIcon: {
    color: theme.palette.text.primary,
  },
  drawer: {
    width: 240,
    flexShrink: 0,
    backgroundColor: THEME_PRIMARY_COLOR,
  },
  drawerPaper: {
    width: 240,
    backgroundColor: THEME_PRIMARY_COLOR,
    color: THEME_WEBSITE_BG_COLOR,
  },
}));

export default useStyles;
