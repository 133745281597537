import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import EditIcon from "@material-ui/icons/Edit";

import {
  GRAY_ONE,
  THEME_PRIMARY_COLOR,
  THEME_WEBSITE_SECONDARY_BG_COLOR,
} from "../../constants";
import useApperanceStyles from "../Appearance/styles";
import useStyles from "./styles";
import { useNavigate } from "react-router-dom";
import { AppContext, getFormattedDate, getThumborUrl } from "../../utility";

const BlogsList = ({ blogs = [] }) => {
  const appearanceClasses = useApperanceStyles();
  const { mobileView } = useContext(AppContext);
  const classes = useStyles();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleAnchorClick = (event, index) => {
    setAnchorEl({ [index]: event.currentTarget });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditBlog = (index) => {
    const blogToBeEdited = blogs[index];
    const { code = null } = blogToBeEdited || {};
    navigate(`/blogs/edit/${code}`, { state: blogToBeEdited });
  };

  return (
    <div className={appearanceClasses.tabContentBox}>
      <Box style={{ textAlign: "end" }}>
        <Button
          variant="contained"
          color="primary"
          style={{
            textTransform: "none",
            fontSize: "14px",
            width: "160px",
          }}
          onClick={() => navigate("/blogs/add")}
        >
          Add new blog
        </Button>
      </Box>
      {mobileView ? (
        <Box className={appearanceClasses.tabContentBox}>
          {blogs.map((blog, index) => {
            const {
              code = null,
              title: blogTitle = "",
              imageUrl = "",
              createdAt = "",
            } = blog || {};
            return (
              <Box className={appearanceClasses.designBox}>
                <Box
                  key={code || index}
                  style={{ display: "flex", alignItems: "center", gap: "24px" }}
                >
                  {imageUrl ? (
                    <img
                      alt="blog1"
                      src={getThumborUrl(imageUrl, 48, 92)}
                      style={{
                        height: "48px",
                        width: "92px",
                        borderRadius: "4px",
                      }}
                    />
                  ) : (
                    <Box
                      style={{
                        height: "48px",
                        width: "92px",
                        borderRadius: "4px",
                        backgroundColor: "rgba(0, 0, 0, .3)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        style={{ fontSize: "12px", color: "#fff" }}
                      >
                        No image
                      </Typography>
                    </Box>
                  )}
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 1,
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        color: THEME_PRIMARY_COLOR,
                        lineHeight: "20px",
                      }}
                    >
                      {blogTitle || ""}
                    </Typography>
                    {/* <Typography
                      variant="subtitle2"
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "20px",
                      }}
                    >
                      Kartikay
                    </Typography> */}
                  </Box>
                  <EditIcon onClick={() => handleEditBlog(index)} />
                </Box>
              </Box>
            );
          })}
        </Box>
      ) : (
        <Box className={appearanceClasses.designBox}>
          <Box
            style={{
              padding: "10px 16px",
              backgroundColor: THEME_WEBSITE_SECONDARY_BG_COLOR,
              display: "grid",
              gridTemplateColumns: "1fr 160px 160px 160px",
              gap: "16px",
            }}
          >
            <Typography
              variant="subtitle1"
              className={classes.blogTableHeading}
            >
              Title
            </Typography>
            <Typography
              variant="subtitle1"
              className={classes.blogTableHeading}
            >
              Status
            </Typography>
            <Typography
              variant="subtitle1"
              className={classes.blogTableHeading}
            >
              Date
            </Typography>
            {/* <Typography variant="subtitle1" className={classes.blogTableHeading}>
            Preview
          </Typography> */}
            <Typography
              variant="subtitle1"
              className={classes.blogTableHeading}
            >
              Actions
            </Typography>
          </Box>
          <Box>
            {blogs.map((blog, index) => {
              const {
                code = null,
                title: blogTitle = "",
                imageUrl = "",
                createdAt = "",
              } = blog || {};
              return (
                <Box
                  key={code}
                  style={{
                    padding: "10px 16px",
                    display: "grid",
                    gridTemplateColumns: "92px 1fr 160px 160px 160px",
                    gap: "16px",
                    alignItems: "center",
                  }}
                >
                  <img
                    alt="blog1"
                    src={getThumborUrl(imageUrl, 48, 92)}
                    style={{
                      height: "48px",
                      width: "92px",
                      borderRadius: "4px",
                    }}
                  />
                  <Box style={{ display: "flex", flexDirection: "column" }}>
                    <Typography
                      variant="subtitle2"
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        color: THEME_PRIMARY_COLOR,
                        lineHeight: "20px",
                      }}
                    >
                      {blogTitle || ""}
                    </Typography>
                    {/* <Typography
                    variant="subtitle2"
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "20px",
                    }}
                  >
                    Kartikay
                  </Typography> */}
                  </Box>
                  <Box>
                    <Box
                      style={{
                        textTransform: "uppercase",
                        fontSize: "12px",
                        backgroundColor: "#17b31b",
                        color: "#ffffff",
                        padding: "2px 6px",
                        width: "max-content",
                        borderRadius: "3px",
                        fontWeight: "500",
                      }}
                    >
                      Published
                    </Box>
                  </Box>
                  <Box>
                    <Typography
                      variant="body1"
                      style={{ fontSize: "14px", lineHeight: "20px" }}
                    >
                      {getFormattedDate(createdAt, "dd-mm-yyyy", true) || ""}
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "16px",
                      alignItems: "center",
                    }}
                  >
                    {/* <Typography
                    variant="body1"
                    style={{
                      fontSize: "14px",
                      lineHeight: "20px",
                      color: THEME_PRIMARY_COLOR,
                      cursor: "pointer",
                    }}
                  >
                    View
                  </Typography> */}
                    <IconButton
                      style={{ padding: "6px" }}
                      onClick={(e) => handleAnchorClick(e, index)}
                    >
                      <MoreVertIcon style={{ color: GRAY_ONE }} />
                    </IconButton>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl && anchorEl[index]}
                      open={Boolean(anchorEl && anchorEl[index])}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      <MenuItem onClick={() => handleEditBlog(index)}>
                        <ListItemIcon
                          style={{
                            width: "fit-content",
                            minWidth: "fit-content",
                            marginRight: "8px",
                          }}
                        >
                          <EditIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Edit blog" />
                      </MenuItem>
                      {/* <MenuItem onClick={handleClose}>
                      <ListItemIcon
                        style={{
                          width: "fit-content",
                          minWidth: "fit-content",
                          marginRight: "8px",
                        }}
                      >
                        <DeleteForeverIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Delete blog" />
                    </MenuItem> */}
                    </Menu>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>
      )}
    </div>
  );
};

export default BlogsList;
