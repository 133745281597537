import React, { useContext, useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { FAILURE_TEXT_COLOR, THEME_WEBSITE_BG_COLOR } from "../../constants";
import { AppContext, devConsoleLog } from "../../utility";
import "./styles.css";
import dayjs from "dayjs";
import { Button, CircularProgress, Typography } from "@material-ui/core";
import { format } from "date-fns";
import useStyles from "./styles";
import AppointmentConfirmedDialog from "./AppointmentConfirmedDialog";
import { useNavigate } from "react-router-dom";

const AppointmentCalendar = ({
  handleDateTimeClick,
  appointments = [],
  isAppointmentsLoading,
  isDoctorSelected = false,
  selectedDoctor = {},
  slots = []
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { deskTopView, mobileView } = useContext(AppContext);
  const [events, setEvents] = useState([]);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const [bookingDetails, setBookingDetails] = useState({});
  const currentDateUTC = new Date();
  const currentDateIST = new Date(
    currentDateUTC.getTime() + 5.5 * 60 * 60 * 1000
  );

  const { doctorDetails = {} } = selectedDoctor || {};
  const { code: doctorCode = null, name: doctorName = "" } =
    doctorDetails || {};

  const handleDateClick = (value) => {
    const { dateStr = "" } = value || {};
    handleDateTimeClick(dateStr);
  };

  const handleEventClick = (info) => {
    const { extendedProps = {} } = info.event || {};
    const { bookingInfo = {} } = extendedProps || {};
    devConsoleLog("bookingInfo", bookingInfo);
    setBookingDetails(bookingInfo);
    setIsConfirmationDialogOpen(true);
  };

  const handleAddPracticeTimings = () => {
    if (doctorCode) navigate(`/doctors/edit/${doctorCode}/${doctorName}`);
  }

  useEffect(() => {
    if (appointments.length > 0) {
      const newAppointmentsArr = appointments.map((appointment) => {
        const {
          slotStartTime = "",
          name: title = "",
          slotEndTime = "",
          status = null,
        } = appointment || {};
        const start = new Date(slotStartTime);
        const end = new Date(slotEndTime);
        const backgroundColor = status === 4 && FAILURE_TEXT_COLOR;
        const borderColor = status === 4 && FAILURE_TEXT_COLOR;

        return {
          title,
          start,
          end,
          bookingInfo: { ...appointment },
          backgroundColor,
          borderColor,
        };
      });
      setEvents(newAppointmentsArr);
    }
  }, [appointments]);

  return (
    <div style={{ backgroundColor: THEME_WEBSITE_BG_COLOR, padding: "16px" }}>
      {!isDoctorSelected ? (
        <Typography variant="h5" align="center">
          Select Doctor for Appointments Schedule.
        </Typography>
      ) : isAppointmentsLoading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress color="primary" />
        </div>
      ) : slots.length === 0 ? (
        <div style={{display: "flex", flexDirection: "column", gap: "24px", alignItems: "center"}}>
          <img
            alt="practice-timings"
            src="/practice-timings.svg"
            className={classes.practiceTimingsImg}
          />
          <Typography variant="h4" align="center">
            Configure Your Slots
          </Typography>
          <div style={{textAlign: "center"}}>
            <Button
              variant="contained"
              color="primary"
              style={{ textTransform: "none", boxShadow: "none", width: "200px" }}
              onClick={handleAddPracticeTimings}
            >
              Add Practice Timings
            </Button>
          </div>
        </div>
      ) : mobileView ? (
        <FullCalendar
          style={{ height: "100%" }}
          plugins={[timeGridPlugin, interactionPlugin]}
          initialView={deskTopView ? "timeGridWeek" : "timeGridDay"}
          headerToolbar={{
            left: "prev,next",
            center: "title",
            right: deskTopView ? "timeGridWeek,timeGridDay" : "timeGridDay",
          }} // user can switch between the two
          titleFormat={{ year: "numeric", month: "short", day: "numeric" }}
          dayHeaderFormat={{
            // will produce something like "Tuesday, September 18, 2018"
            weekday: "short",
            month: "short",
            //   year: "numeric",
            day: "numeric",
          }}
          height={500}
          allDaySlot={false}
          dateClick={handleDateClick}
          nowIndicator
          now={currentDateIST.toISOString()}
          scrollTime={dayjs().format("H:mm")}
          events={events}
          displayEventEnd={false}
          timeZone="UTC"
          eventClick={handleEventClick}
        />
      ) : (
        <FullCalendar
          style={{ height: "100%" }}
          plugins={[timeGridPlugin, interactionPlugin]}
          initialView={deskTopView ? "timeGridWeek" : "timeGridDay"}
          headerToolbar={{
            left: "prev,next",
            center: "title",
            right: deskTopView ? "timeGridWeek,timeGridDay" : "timeGridDay",
          }} // user can switch between the two
          titleFormat={{ year: "numeric", month: "short", day: "numeric" }}
          dayHeaderFormat={{
            // will produce something like "Tuesday, September 18, 2018"
            weekday: "short",
            month: "short",
            //   year: "numeric",
            day: "numeric",
          }}
          allDaySlot={false}
          dateClick={handleDateClick}
          nowIndicator
          now={currentDateIST.toISOString()}
          scrollTime={dayjs().format("H:mm")}
          events={events}
          displayEventEnd={false}
          timeZone="UTC"
          eventClick={handleEventClick}
        />
      )}
      <AppointmentConfirmedDialog
        isOpen={isConfirmationDialogOpen}
        setIsConfirmationDialogOpen={setIsConfirmationDialogOpen}
        bookingDetails={bookingDetails}
        selectedDoctor={selectedDoctor}
      />
    </div>
  );
};

export default AppointmentCalendar;
