import { alpha, makeStyles } from "@material-ui/core/styles";
import {
  BOX_SHADOW,
  GRAY_FIVE,
  GRAY_TWO,
  THEME_PRIMARY_COLOR,
  THEME_WEBSITE_BG_COLOR,
} from "../../constants";

const useStyles = makeStyles((theme) => ({
  addDoctorSvg: {
    // mask: "url(/add-doctor.svg) no-repeat center / contain",
    background: "url(/add-doctor.svg) no-repeat center / contain",
    height: "300px",
  },
  dialogBox: {
    padding: "24px",
    width: "36rem",
    borderRadius: "8px",
  },
  table: {
    minWidth: 650,
    [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
    },
  },
  tableCell: {
    borderBottom: "none",
  },
  layoutBox: {
    display: "flex",
    flexDirection: "column",
    gap: "32px",
  },
  layoutSubBox: {
    padding: "24px",
    boxShadow: BOX_SHADOW,
    backgroundColor: THEME_WEBSITE_BG_COLOR,
    borderRadius: "4px",
    display: "flex",
    flexDirection: "column",
    gap: "32px",
  },
  sessionLabel: {
    fontSize: "14px",
    color: GRAY_FIVE,
    marginBottom: "6px",
    fontWeight: "400",
  },
  sessionGrid: {
    display: "grid",
    gap: "4px",
    alignItems: "center",
    gridTemplateColumns: "1fr",
  },
  deleteSlotIcon: {
    color: GRAY_TWO,
    cursor: "pointer",
    transition: "all .3s",
    "&:hover": {
      color: THEME_PRIMARY_COLOR
    }
  },
  practiceTimingsImg: {
    width: "400px",
    [theme.breakpoints.down('xs')]: {
      width: "100%"
    }
  }
}));

export default useStyles;
