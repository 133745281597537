import React, { useContext, useEffect, useState } from "react";
import MonochromePhotosIcon from "@material-ui/icons/MonochromePhotos";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";

import VerticalTabs from "../../Commons/VerticalTabs";
import Photos from "./Photos";
import Videos from "./Videos";
import { Box, CircularProgress } from "@material-ui/core";
import { AppContext, ControlPanelContext } from "../../../utility";
import {
  API_ENDPOINT_ENTERPRISE,
  API_ENDPOINT_GALLERY,
  API_RESPONSE_UNAUTHORIZED_STATUS,
  API_SECTION_API,
  API_VERSION,
  HARBOR_API_DOMAIN,
} from "../../../constants";
import axios from "axios";
import HorizontalTabs from "../../Commons/HorizontalTabs";
import useStyles from "./styles";

const tabs = [
  {
    name: (
      <Box style={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <MonochromePhotosIcon /> Photos
      </Box>
    ),
    Component: Photos,
  },
  {
    name: (
      <Box style={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <PlayArrowIcon /> Videos
      </Box>
    ),
    Component: Videos,
  },
];

const Gallery = () => {
  const classes = useStyles();
  const { handleRefreshToken, setViewSnackbarMain, mobileView } =
    useContext(AppContext);
  const {
    enterprise = {},
    AuthorizationToken,
    setHeaderHeading,
  } = useContext(ControlPanelContext);
  const { enterprise: selectedEnterprise = {} } = enterprise || {};
  const { code: enterpriseCode = null } = selectedEnterprise || {};
  const [currentTab, setCurrentTab] = useState(0);
  const TabComponent = tabs[currentTab].Component;
  const [galleryData, setGalleryData] = useState({ photos: [], videos: [] });
  const [isLoading, setIsLoading] = useState(false);

  const filterGalleryData = (key, data) => {
    return data.filter((item) => Boolean(item[key]));
  };

  const getGalleryData = async () => {
    const url = `${HARBOR_API_DOMAIN}${API_SECTION_API}${API_VERSION}/${API_ENDPOINT_ENTERPRISE}${enterpriseCode}/${API_ENDPOINT_GALLERY}`;
    setIsLoading(true);
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          source: "website",
          Authorization: AuthorizationToken,
        },
      });
      const { data = {}, status = null } = response || {};
      const { result = [] } = data || {};
      if (status >= 200 && status < 300) {
        const filteredPhotos = filterGalleryData("imageUrl", result);
        const filteredVideos = filterGalleryData("videoUrl", result);
        setGalleryData({ photos: filteredPhotos, videos: filteredVideos });
      }
    } catch (error) {
      const { response } = error || {};
      const { status } = response || {};
      if (status === API_RESPONSE_UNAUTHORIZED_STATUS) {
        handleRefreshToken();
      } else
        setViewSnackbarMain({
          message: "Something went wrong!",
          type: "error",
        });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setHeaderHeading("Gallery");
  }, []);

  useEffect(() => {
    if (enterpriseCode) getGalleryData();
  }, [enterpriseCode]);

  return (
    <div className={classes.galleryLayoutBox}>
      {mobileView ? (
        <HorizontalTabs
          tabs={tabs}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
        />
      ) : (
        <VerticalTabs
          tabs={tabs}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
        />
      )}
      {isLoading ? (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <TabComponent
          galleryData={galleryData}
          getGalleryData={getGalleryData}
        />
      )}
    </div>
  );
};

export default Gallery;
