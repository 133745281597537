import { alpha, makeStyles } from "@material-ui/core/styles";
import {
  BOX_SHADOW,
  GRAY_FIVE,
  GRAY_ONE,
  THEME_WEBSITE_BG_COLOR,
} from "../../constants";

const useStyles = makeStyles((theme) => ({
  blogLabel: {
    fontSize: "14px",
    color: GRAY_FIVE,
    marginBottom: "6px",
    fontWeight: "400",
  },
  blogTableHeading: {
    fontSize: "14px",
    color: GRAY_FIVE,
    fontWeight: "500",
    lineHeight: "20px",
  },
}));

export default useStyles;
