import React, { useContext, useEffect, useState } from "react";

import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

import {
  API_ENDPOINT_BANNERS,
  API_ENDPOINT_ENTERPRISE,
  API_RESPONSE_UNAUTHORIZED_STATUS,
  API_SECTION_API,
  API_VERSION,
  GRAY_ONE,
  HARBOR_API_DOMAIN,
} from "../../../constants";
import useStyles from "./styles";
import useApperanceStyles from "../styles";
import {
  AppContext,
  ControlPanelContext,
  devConsoleLog,
  getThumborUrl,
  handleImageUpload,
  isEmptyObject,
} from "../../../utility";
import axios from "axios";
import BannerDialog from "./BannerDialog";

const Banners = () => {
  const classes = useStyles();
  const appearanceClasses = useApperanceStyles();
  const { setViewSnackbarMain, handleRefreshToken } = useContext(AppContext);
  const { enterprise = {}, AuthorizationToken } =
    useContext(ControlPanelContext);
  const { enterprise: selectedEnterprise = {} } = enterprise || {};
  const { code: enterpriseCode = null } = selectedEnterprise || {};
  const [banners, setBanners] = useState([]);
  const [bannerDialog, setBannerDialog] = useState({});
  const [bannerInput, setBannerInput] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [hasInputChanged, setHasInputChanged] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(false);

  const getBanners = async () => {
    if (!enterpriseCode) return;
    const url = `${HARBOR_API_DOMAIN}${API_SECTION_API}${API_VERSION}/${API_ENDPOINT_ENTERPRISE}${enterpriseCode}/${API_ENDPOINT_BANNERS}`;
    setIsLoading(true);
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          source: "website",
          Authorization: AuthorizationToken,
        },
      });
      const { data = {}, status = null } = response || {};
      const { result = [] } = data || {};
      if (status >= 200 && status < 300) {
        setBanners(result);
        setBannerDialog({ isOpen: false, index: result.length - 1 });
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleReqBody = () => {
    const finalBanners = [];
    banners.forEach((banner) => {
      if (!isEmptyObject(banner)) finalBanners.push(banner);
    });
    return { data: [...finalBanners] };
  };

  const addBanner = async () => {
    if (!enterpriseCode) return;
    const url = `${HARBOR_API_DOMAIN}${API_SECTION_API}${API_VERSION}/${API_ENDPOINT_ENTERPRISE}${enterpriseCode}/${API_ENDPOINT_BANNERS}`;
    const reqBody = handleReqBody();
    try {
      const response = await axios.post(url, reqBody, {
        headers: {
          "Content-Type": "application/json",
          source: "website",
          Authorization: AuthorizationToken,
        },
      });
      const { data = {}, status = null } = response || {};
      const { result = [] } = data || {};
      if (status >= 200 && status < 300) {
        setViewSnackbarMain({
          message: "Banners updated successfully!!!",
          type: "success",
        });
        getBanners();
      }
    } catch (error) {
      const { response } = error || {};
      const { status } = response || {};
      if (status === API_RESPONSE_UNAUTHORIZED_STATUS) {
        handleRefreshToken();
      } else
        setViewSnackbarMain({
          message: "Something went wrong!",
          type: "error",
        });
    }
  };

  const handleAddBannerDialogOpen = () => {
    setBanners((prev) => [...prev, {}]);
    setBannerDialog((prev) => ({ index: prev.index + 1, isOpen: true }));
    setBannerInput({});
  };

  const handleClose = () => {
    setBannerDialog((prev) => ({ ...prev, isOpen: false }));
  };

  const handleAddBanner = () => {
    setHasInputChanged(true);
    const { index } = bannerDialog || {};
    const copyOfBanners = [...banners];
    copyOfBanners[index] = { ...bannerInput };
    setBanners(copyOfBanners);
    handleClose();
  };

  const handleInputChange = (e, index) => {
    setBannerInput((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleEditBanner = (index) => {
    setBannerDialog({ isOpen: true, index });
    // setIsAddBannerDialogOpen(true);
    setBannerInput(banners[index]);
  };

  const handleDeleteBanner = (index, code) => {
    const copyOfBanners = [...banners];
    if (code) {
      const bannerToBeChanged = copyOfBanners[index];
      bannerToBeChanged["isActive"] = false;
    } else copyOfBanners.splice(index, 1);
    setBanners(copyOfBanners);
    setHasInputChanged(true);
  };

  const handleBannerImageUpload = async (event) => {
    setIsImageLoading(true);
    const image = await handleImageUpload(event);
    if (image) {
      const {
        cdn_url: imageUrl = "",
        error = "",
        object_url: imageObjectUrl = "",
      } = image || {};
      if (error) {
        setIsImageLoading(false);
        setViewSnackbarMain({
          message: "Photo couldn't be uploaded",
          type: "error",
        });
      } else {
        setBannerInput((prev) => ({ ...prev, imageUrl, imageObjectUrl }));
        setIsImageLoading(false);
      }
    }
  };

  useEffect(() => {
    if (enterpriseCode) getBanners();
  }, [enterpriseCode]);

  if (isLoading)
    return (
      <Box style={{ width: "100%", textAlign: "center" }}>
        <CircularProgress />
      </Box>
    );

  return (
    <Box className={appearanceClasses.tabContentBox}>
      <Box className={appearanceClasses.designBox}>
        <Box>
          <Typography
            className={appearanceClasses.designBoxHeading}
            variant="subtitle1"
          >
            Banners
          </Typography>
          <Typography
            className={appearanceClasses.designBoxSubHeading}
            variant="subtitle2"
          >
            Grab your clinic patient's attention by banners displayed on top of
            your homepage.
          </Typography>
        </Box>
        <Box className={classes.bannersGridLayout}>
          {banners.map((banner, index) => {
            const {
              code = null,
              isActive = true,
              imageUrl = null,
            } = banner || {};
            if (isEmptyObject(banner) || !isActive) return <></>;
            return (
              <Box
                style={{
                  width: "100%",
                  height: "160px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "6px",
                  color: GRAY_ONE,
                  flexDirection: "column",
                  backgroundImage: `url(${getThumborUrl(imageUrl, 160, 0)})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  position: "relative",
                  backgroundRepeat: "no-repeat",
                  // filter: "brightness(60%)",
                }}
              >
                <Box
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    left: "0",
                    top: "0",
                    backgroundColor: "rgba(0, 0, 0, .3)",
                    borderRadius: "6px",
                  }}
                ></Box>
                <Box style={{ zIndex: 1 }}>
                  <Typography
                    variant="subtitle1"
                    style={{
                      fontWeight: "400",
                      color: "#fff",
                      fontSize: "1.2rem",
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <IconButton onClick={() => handleEditBanner(index)}>
                      <EditIcon fontSize="large" style={{ color: "#fff" }} />
                    </IconButton>
                    <IconButton onClick={() => handleDeleteBanner(index, code)}>
                      <DeleteIcon fontSize="large" style={{ color: "#fff" }} />
                    </IconButton>
                  </Typography>
                </Box>
              </Box>
            );
          })}
          <Box
            style={{
              width: "100%",
              height: "160px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: `1px dashed ${GRAY_ONE}`,
              borderRadius: "6px",
              cursor: "pointer",
              color: GRAY_ONE,
              flexDirection: "column",
            }}
            onClick={handleAddBannerDialogOpen}
          >
            <AddIcon style={{ fontSize: "32px" }} />
            <Typography variant="body1" style={{ fontWeight: "300" }}>
              Add banner
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box style={{ textAlign: "end" }}>
        <Button
          variant="contained"
          color="primary"
          style={{
            textTransform: "none",
            padding: "4px 16px",
            fontSize: "14px",
          }}
          onClick={addBanner}
          disabled={!hasInputChanged}
        >
          Update
        </Button>
      </Box>
      <BannerDialog
        isOpen={bannerDialog["isOpen"]}
        handleClose={handleClose}
        banners={banners}
        handleInputChange={handleInputChange}
        addBanner={addBanner}
        currentBannerIndex={bannerDialog["index"]}
        handleAddBanner={handleAddBanner}
        bannerInput={bannerInput}
        handleBannerImageUpload={handleBannerImageUpload}
        isImageLoading={isImageLoading}
      />
    </Box>
  );
};

export default Banners;
