import React, { useContext, useEffect, useState } from "react";
import axios from "axios";

import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Typography,
} from "@material-ui/core";

import {
  API_ENDPOINT_THEMES,
  API_RESPONSE_UNAUTHORIZED_STATUS,
  API_SECTION_API,
  API_VERSION,
  HARBOR_API_DOMAIN,
  THEME_WEBSITE_BG_COLOR,
  API_SECTION_PUBLIC,
  API_SECTION_WEBSITE,
  API_ENDPOINT_ENTERPRISE,
} from "../../constants";
import { AppContext, ControlPanelContext, getThumborUrl } from "../../utility";
import LaunchIcon from "@material-ui/icons/Launch";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import useApperanceStyles from "./styles";

const Themes = () => {
  const classes = useApperanceStyles();
  const { handleRefreshToken, setViewSnackbarMain } = useContext(AppContext);
  const {
    setHeaderHeading,
    AuthorizationToken,
    enterprise = {},
  } = useContext(ControlPanelContext);
  const { enterprise: selectedEnterprise = {} } = enterprise || {};
  const { domainName = null, code: enterpriseCode = null } =
    selectedEnterprise || {};
  const [themes, setThemes] = useState([]);
  const [selectedThemeCode, setSelectedThemeCode] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const getThemesData = async () => {
    setIsLoading(true);
    const url = `${HARBOR_API_DOMAIN}${API_SECTION_API}${API_VERSION}/${API_ENDPOINT_THEMES}`;
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          source: "website",
          Authorization: AuthorizationToken,
        },
      });
      const { data = {}, status = null } = response || {};
      const { result = [] } = data || {};
      if (status >= 200 && status < 300) {
        setThemes(result);
      }
    } catch (error) {
      const { response } = error || {};
      const { status } = response || {};
      if (status === API_RESPONSE_UNAUTHORIZED_STATUS) {
        handleRefreshToken();
      } else
        setViewSnackbarMain({
          message: "Something went wrong!",
          type: "error",
        });
    } finally {
      setIsLoading(false);
    }
  };

  const getWebsiteData = async () => {
    const url = `${HARBOR_API_DOMAIN}${API_SECTION_API}${API_VERSION}/${API_SECTION_PUBLIC}${API_SECTION_WEBSITE}?domainName=${domainName}`;
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          source: "website",
          Authorization: AuthorizationToken,
        },
      });
      const { data = {}, status = null } = response || {};
      const { result = {} } = data || {};
      const { template = {} } = result || {};
      const { code: templateCode = null } = template || {};
      if (status >= 200 && status < 300) {
        setSelectedThemeCode(templateCode);
      }
    } catch (error) {
      const { response } = error || {};
      const { status } = response || {};
      if (status === API_RESPONSE_UNAUTHORIZED_STATUS) {
        handleRefreshToken();
      } else
        setViewSnackbarMain({
          message: "Something went wrong!",
          type: "error",
        });
    }
  };

  const updateTheme = async (isAlreadyApplied = false, themeCode = null) => {
    if (isAlreadyApplied || !enterpriseCode || !themeCode) return;
    const url = `${HARBOR_API_DOMAIN}${API_SECTION_API}${API_VERSION}/${API_ENDPOINT_ENTERPRISE}${enterpriseCode}/`;
    const reqBody = { themeCode };
    try {
      const response = await axios.patch(url, reqBody, {
        headers: {
          "Content-Type": "application/json",
          source: "website",
          Authorization: AuthorizationToken,
        },
      });
      const { data = {}, status = null } = response || {};
      const { result = {} } = data || {};
      if (status >= 200 && status < 300) {
        setViewSnackbarMain({
          message: "Successfully updated!",
          type: "success",
        });
        getThemesData();
        setSelectedThemeCode(themeCode)
      }
    } catch (error) {
      const { response } = error || {};
      const { status } = response || {};
      if (status === API_RESPONSE_UNAUTHORIZED_STATUS) {
        handleRefreshToken();
      } else
        setViewSnackbarMain({
          message: "Something went wrong!",
          type: "error",
        });
    }
  };

  useEffect(() => {
    setHeaderHeading("Themes");
    getThemesData();
  }, []);

  useEffect(() => {
    if (domainName) getWebsiteData();
  }, [domainName]);

  if (isLoading)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress color="primary" />
      </div>
    );
  return (
    <div className={classes.themesBox}>
      {themes.map((theme) => {
        const {
          code = null,
          name: themeName = "",
          image = "",
          isDefault = false,
        } = theme || {};
        return (
          <Box
            key={code}
            style={{
              boxShadow: `0 3px 8px #1a181e0a`,
              backgroundColor: THEME_WEBSITE_BG_COLOR,
              borderRadius: "8px",
            }}
          >
            <Box style={{ padding: "8px 16px", position: "relative" }}>
              <img
                alt="theme"
                src={getThumborUrl(image, 0, 0)}
                style={{
                  height: "240px",
                  width: "100%",
                  objectFit: "cover",
                  objectPosition: "center",
                }}
              />
            </Box>
            <Divider />
            <Box
              style={{
                padding: "8px 16px",
                display: "flex",
                justifyContent: "space-between",
                gap: "16px",
                alignItems: "center",
              }}
            >
              <Typography variant="subtitle1" style={{ fontWeight: "500" }}>
                {themeName || ""}
              </Typography>
              <Box>
                {/* <Button
                  variant="outlined"
                  color="primary"
                  style={{
                    padding: "4px 12px",
                    textTransform: "none",
                    marginRight: "8px",
                  }}
                >
                  <LaunchIcon
                    style={{ fontSize: "16px", marginRight: "6px" }}
                  />{" "}
                  View demo
                </Button> */}
                {selectedThemeCode === code ? (
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      padding: "5px 12px",
                      textTransform: "none",
                      backgroundColor: "#18B31B",
                    }}
                    onClick={() =>
                      updateTheme(selectedThemeCode === code, code)
                    }
                  >
                    <Typography
                      variant="subtitle2"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "6px",
                      }}
                    >
                      <DoneAllIcon />
                      Applied
                    </Typography>
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    // color="primary"
                    style={{
                      padding: "5px 12px",
                      textTransform: "none",
                    }}
                    onClick={() =>
                      updateTheme(selectedThemeCode === code, code)
                    }
                  >
                    Apply
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        );
      })}
      {/* <Box
        style={{
          boxShadow: "0 3px 8px #1a181e0a",
          backgroundColor: THEME_WEBSITE_BG_COLOR,
          borderRadius: "8px",
        }}
      >
        <Box style={{ padding: "8px 16px" }}>
          <img
            alt="theme"
            src="https://dms.mydukaan.io/original/webp/media/8d3e6ee3-0bf5-480e-8b90-16ab3e4a09c9.webp"
            style={{ height: "240px", width: "100%", objectFit: "cover" }}
          />
        </Box>
        <Divider />
        <Box
          style={{
            padding: "8px 16px",
            display: "flex",
            justifyContent: "space-between",
            gap: "16px",
            alignItems: "center",
          }}
        >
          <Typography variant="subtitle1" style={{ fontWeight: "500" }}>
            Serene
          </Typography>
          <Box>
            <Button
              variant="outlined"
              color="primary"
              style={{
                padding: "4px 12px",
                textTransform: "none",
                marginRight: "8px",
              }}
            >
              <LaunchIcon style={{ fontSize: "16px", marginRight: "6px" }} />{" "}
              View demo
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ padding: "5px 12px", textTransform: "none" }}
            >
              Apply
            </Button>
          </Box>
        </Box>
      </Box>
      <Box
        style={{
          boxShadow: "0 3px 8px #1a181e0a",
          backgroundColor: THEME_WEBSITE_BG_COLOR,
          borderRadius: "8px",
        }}
      >
        <Box style={{ padding: "8px 16px" }}>
          <img
            alt="theme"
            src="https://dms.mydukaan.io/original/webp/media/1441d8fb-aeb3-4260-8af7-14215d614d92.webp"
            style={{ height: "240px", width: "100%", objectFit: "cover" }}
          />
        </Box>
        <Divider />
        <Box
          style={{
            padding: "8px 16px",
            display: "flex",
            justifyContent: "space-between",
            gap: "16px",
            alignItems: "center",
          }}
        >
          <Typography variant="subtitle1" style={{ fontWeight: "500" }}>
            Serene
          </Typography>
          <Box>
            <Button
              variant="outlined"
              color="primary"
              style={{
                padding: "4px 12px",
                textTransform: "none",
                marginRight: "8px",
              }}
            >
              <LaunchIcon style={{ fontSize: "16px", marginRight: "6px" }} />{" "}
              View demo
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ padding: "5px 12px", textTransform: "none" }}
            >
              Apply
            </Button>
          </Box>
        </Box>
      </Box> */}
    </div>
  );
};

export default Themes;
