import { alpha, makeStyles } from "@material-ui/core/styles";
import { THEME_PRIMARY_COLOR } from "../../constants";

const useStyles = makeStyles((theme) => ({
  doctorProfileImg: {
    backgroundColor: THEME_PRIMARY_COLOR,
    mask: "url(/doctor.svg) no-repeat center / contain",
    // width: "100%",
    height: "64px",
    width: "64px",
  },
  appointmentDialog: {
    width: "600px",
    borderRadius: "12px",
  },
  appointmentConfirmDialog: {
    width: "400px",
  },
  appointmentDetailsText: {
    fontWeight: "600",
  },
  appointmentDetailsButton: {
    textTransform: "none",
    fontSize: "12px",
    fontWeight: "500",
  },
  doctorSelect: {
    fontSize: "16px",
    display: "flex",
    // alignItems: "center",
    gap: "8px",
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
  appointmentGridLayout: {
    display: "grid",
    gridTemplateColumns: "240px 1fr",
    // height: "100%",
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gap: "16px",
    },
  },
  disabledTextField: {
    color: theme.palette.text.primary,
  },
  phoneNumberBox: {
    display: "grid",
    gridTemplateColumns: "120px 1fr",
    gap: "24px",
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "80px 1fr",
      gap: "10px",
    },
  },
  inputBoxGrid: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "24px",
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
    },
  },
  dateTimePickerGrid: {
    width: "100%",
    display: "grid",
    gap: "24px",
    alignItems: "center",
    gridTemplateColumns: "1fr 1fr",
    marginTop: "-8px",
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
    },
  },
  timeOfTheDayIconBox: {
    display: "flex",
    gap: "8px",
    alignItems: "center",
  },
  appointmentTimeBox: {
    padding: "16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    fontSize: "14px",
    borderRadius: "8px",
    cursor: "pointer",
    transition: "all .3s",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
    },
  },
  selectedTimeBox: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  appointmentDialogContentDiv: {
    padding: "12px 24px",
    display: "flex",
    flexDirection: "column",
    gap: "24px",
  },
  slotTimingGridBox: {
    display: "grid",
    gap: "24px",
    alignItems: "center",
    gridTemplateColumns: "120px 1fr",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
      gap: "16px",
    },
  },
  slotTimingSlotsGrid: {
    flex: 1,
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: "16px",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
  },
  eventBox: {
    display: "flex",
    flexDirection: "column",
  },
  practiceTimingsImg: {
    width: "400px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
}));

export default useStyles;
