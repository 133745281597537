import axios from "axios";
import React, { useContext, useEffect, useState } from "react";

import { Box, Button, Typography } from "@material-ui/core";

import {
  API_ENDPOINT_ENTERPRISE,
  API_RESPONSE_UNAUTHORIZED_STATUS,
  API_SECTION_API,
  API_SECTION_PUBLIC,
  API_SECTION_WEBSITE,
  API_VERSION,
  HARBOR_API_DOMAIN,
} from "../../constants";
import { AppContext, ControlPanelContext, devConsoleLog } from "../../utility";
import WysiwygEditor from "../Commons/WysiwygEditor";
import useApperanceStyles from "./styles";

const AboutUs = () => {
  const classes = useApperanceStyles();
  const { setViewSnackbarMain, handleRefreshToken } = useContext(AppContext);
  const {
    setHeaderHeading,
    AuthorizationToken,
    enterprise = {},
  } = useContext(ControlPanelContext);
  const { enterprise: selectedEnterprise = {} } = enterprise || {};
  const { domainName = null, code: enterpriseCode = null } =
    selectedEnterprise || {};
  const [aboutUs, setAboutUs] = useState("");

  const getWebsiteData = async () => {
    const url = `${HARBOR_API_DOMAIN}${API_SECTION_API}${API_VERSION}/${API_SECTION_PUBLIC}${API_SECTION_WEBSITE}?domainName=${domainName}`;
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          source: "website",
          Authorization: AuthorizationToken,
        },
      });
      const { data = {}, status = null } = response || {};
      const { result = {} } = data || {};
      const { about_us: aboutUsContent = "" } = result || {};
      if (status >= 200 && status < 300) {
        setAboutUs(aboutUsContent || "");
      }
    } catch (error) {
      const { response } = error || {};
      const { status } = response || {};
      if (status === API_RESPONSE_UNAUTHORIZED_STATUS) {
        handleRefreshToken();
      } else
        setViewSnackbarMain({
          message: "Something went wrong!",
          type: "error",
        });
    }
  };

  const updateAboutUsData = async () => {
    if (!enterpriseCode) return;
    const url = `${HARBOR_API_DOMAIN}${API_SECTION_API}${API_VERSION}/${API_ENDPOINT_ENTERPRISE}${enterpriseCode}/`;
    const reqBody = { aboutUs };
    try {
      const response = await axios.patch(url, reqBody, {
        headers: {
          "Content-Type": "application/json",
          source: "website",
          Authorization: AuthorizationToken,
        },
      });
      const { data = {}, status = null } = response || {};
      const { result = {} } = data || {};
      const { aboutUs = '' } = result || {};
      if (status >= 200 && status < 300) {
        setAboutUs(aboutUs);
        setViewSnackbarMain({
          message: "About us updated successfully!!!",
          type: "success",
        });
      }
    } catch (error) {
      const { response } = error || {};
      const { status } = response || {};
      if (status === API_RESPONSE_UNAUTHORIZED_STATUS) {
        handleRefreshToken();
      } else
        setViewSnackbarMain({
          message: "Something went wrong!",
          type: "error",
        });
    }
  };

  useEffect(() => {
    setHeaderHeading("About us");
  }, []);

  useEffect(() => {
    if (domainName) getWebsiteData();
  }, [domainName]);

  return (
    <div className={classes.tabContentBox}>
      <Box className={classes.designBox}>
        <Box>
          <Typography variant="subtitle1" className={classes.designBoxHeading}>
            Page Content
          </Typography>
          <Typography
            className={classes.designBoxSubHeading}
            variant="subtitle2"
          >
            Edit Your About Us Page Content Effortlessly
          </Typography>
        </Box>
        <WysiwygEditor value={aboutUs} setValue={setAboutUs} />
      </Box>
      <Box style={{ textAlign: "end" }}>
        <Button
          variant="contained"
          color="primary"
          style={{
            textTransform: "none",
            padding: "4px 16px",
            fontSize: "14px",
          }}
          onClick={updateAboutUsData}
        >
          Update
        </Button>
      </Box>
    </div>
  );
};

export default AboutUs;
