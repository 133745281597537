import { makeStyles } from "@material-ui/core/styles";
import {
  BOX_SHADOW,
  GRAY_FIVE,
  GRAY_FOUR,
  GRAY_ONE,
} from "../../constants";

const useStyles = makeStyles((theme) => ({
  reviewsLayout: {
    display: "flex",
    flexDirection: "column",
    gap: "32px",
  },
  reviewsActionDiv: {
    display: "flex",
    gap: "48px",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      gap: '24px'
    }
  },
  reviewsActionFlexBox: {
    display: "flex",
    alignItems: "center",
    gap: "16px",
  },
  reviewsActionBtn: {
    padding: "8px 16px",
    border: `1px solid ${GRAY_FIVE}`,
    boxShadow: BOX_SHADOW,
    borderRadius: "4px",
    backgroundColor: "#fff",
    fontWeight: "400",
    cursor: "pointer",
    fontSize: "15px",
    textAlign: "center",
  },
  reviewsActionUnacticeBtn: {
    padding: "8px 16px",
    border: `1px solid ${GRAY_FOUR}`,
    borderRadius: "4px",
    backgroundColor: GRAY_FOUR,
    fontWeight: "400",
    cursor: "pointer",
    fontSize: "15px",
    textAlign: "center",
    color: GRAY_ONE,
    transition: "all .5s",
    "&:hover": {
      borderColor: GRAY_FIVE,
      backgroundColor: "#fff",
      color: theme.palette.text.primary,
    },
  },
  reviewsListDiv: {
    backgroundColor: "#fff",
    borderRadius: "4px",
    boxShadow: BOX_SHADOW,
    padding: "16px",
  },
  reviewsListFlexBox: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
  },
  reviewsListGrid: {
    display: "grid",
    gridTemplateColumns: "112px 1fr 160px 160px",
    gap: "16px",
    alignItems: "center",
    padding: "8px",
  },
  reviewsListColHeading: {
    color: GRAY_FIVE,
    fontWeight: "500",
  },
  reviewsListReviewDiv: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  reviewsListReviewText: {
    fontSize: "14px",
    color: GRAY_FIVE,
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 4,
    wordBreak: "break-word",
    overflow: "hidden",
  },
  reviewsListReviewPatientName: {
    color: theme.palette.primary.main,
  },
  reviewsListReviewAction: {
    display: "flex",
    alignItems: "center",
    gap: "4px",
    textTransform: "uppercase",
    color: GRAY_FIVE,
    cursor: "pointer",
  },
  reviewsSectionReviewRatingFlexMweb: {
    display: "flex",
    alignItems: "center",
    gap: "16px",
    justifyContent: "space-between",
  },
  reviewsListReviewActionsFlexBox: {
    display: "flex",
    alignItems: "center",
    gap: "16px",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "start",
    },
  },
  reviewsListReviewActionsFlexBoxMweb: {
    display: "flex",
    alignItems: "center",
    gap: "16px",
  },
  reviewsActionConfirmDialog: {
    width: "600px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  postReviewDialog: {
    width: "480px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

export default useStyles;
