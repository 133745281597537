import React, { useContext, useState } from "react";
import useStyles from "./styles";
import { Checkbox, Divider, Typography } from "@material-ui/core";
import { format } from "date-fns";
import Rating from "@material-ui/lab/Rating";
import StarIcon from "@material-ui/icons/Star";
import UndoIcon from "@material-ui/icons/Undo";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  API_RESPONSE_UNAUTHORIZED_STATUS,
  GRAY_FIVE,
  GRAY_THREE,
  SUCCESS_TEXT_COLOR,
  WARNING_COLOR,
  DOCFYN_API_DOMAIN,
  API_SECTION_API,
  API_VERSION,
  API_ENDPOINT_ENTERPRISE,
  API_ENDPOINT_REVIEWS,
  GRAY_ONE,
} from "../../constants";
import ConfirmUnpublish from "./ConfirmUnpublish";
import ConfirmDelete from "./ConfirmDelete";
import BulkActions from "./BulkActions";
import { AppContext, ControlPanelContext } from "../../utility";
import ConfirmPublish from "./ConfirmPublish";
import axios from "axios";

const ReviewsList = ({ reviews = [], reviewsType = {}, getReviews }) => {
  const classes = useStyles();
  const { setViewSnackbarMain, handleRefreshToken, mobileView } =
    useContext(AppContext);
  const { AuthorizationToken, enterprise = {} } =
    useContext(ControlPanelContext);
  const { enterprise: currentEnterprise = {} } = enterprise || {};
  const { code: enterpriseCode = null } = currentEnterprise || {};

  const updateReview = async (review, handleClose) => {
    // setIsLoading(true);
    const url = `${DOCFYN_API_DOMAIN}${API_SECTION_API}${API_VERSION}/${API_ENDPOINT_ENTERPRISE}${enterpriseCode}/${API_ENDPOINT_REVIEWS}`;
    const reqBody = { data: [{ ...review }] };
    try {
      const response = await axios.post(url, reqBody, {
        headers: {
          "Content-Type": "application/json",
          source: "website",
          Authorization: AuthorizationToken,
        },
      });
      const { status = null } = response || {};
      if (status >= 200 && status < 300) {
        setViewSnackbarMain({
          message: "Review Updated Successfully",
          type: "success",
        });
        handleClose();
        getReviews();
      }
    } catch (error) {
      const { response } = error || {};
      const { status } = response || {};
      if (status === API_RESPONSE_UNAUTHORIZED_STATUS) {
        handleRefreshToken();
      } else
        setViewSnackbarMain({
          message: "Something went wrong!",
          type: "error",
        });
    } finally {
      //   setIsLoading(false);
    }
  };

  return (
    <div className={classes.reviewsListDiv}>
      {mobileView ? (
        <div className={classes.reviewsListFlexBox}>
          {reviews.map((review, index) => {
            const {
              code = "",
              rating = null,
              comment = "",
              isPublished = true,
              createdAt = "",
              givenByName = "",
            } = review || {};
            if (
              (reviewsType[1] && !isPublished) ||
              (reviewsType[2] && isPublished)
            )
              return <></>;
            return (
              <div key={code || index}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  <div className={classes.reviewsSectionReviewRatingFlexMweb}>
                    <Rating
                      name="read-only"
                      readOnly
                      value={rating}
                      precision={0.5}
                      max={5}
                      icon={<StarIcon style={{ fontSize: "1.3rem" }} />}
                    />
                    <Typography
                      variant="subtitle2"
                      style={{
                        color: GRAY_FIVE,
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                      }}
                    >
                      <span
                        style={{
                          height: "8px",
                          width: "8px",
                          borderRadius: "50%",
                          backgroundColor: isPublished
                            ? SUCCESS_TEXT_COLOR
                            : WARNING_COLOR,
                          display: "inline-block",
                        }}
                      ></span>{" "}
                      {isPublished ? "Published" : "Unpublished"}
                    </Typography>
                  </div>
                  <div className={classes.reviewsSectionReviewRatingFlexMweb}>
                    <Typography
                      variant="subtitle2"
                      className={classes.reviewsListReviewPatientName}
                    >
                      By {givenByName || ""}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      style={{ color: GRAY_ONE, fontSize: "13px" }}
                    >
                      {format(createdAt, "d MMM yyyy, p") || ""}
                    </Typography>
                  </div>
                  <Typography
                    variant="subtitle1"
                    className={classes.reviewsListReviewText}
                  >
                    {comment || ""}
                  </Typography>
                  <div
                    className={classes.reviewsListReviewActionsFlexBoxMweb}
                    style={{ marginTop: "8px" }}
                  >
                    {isPublished ? (
                      <ConfirmUnpublish
                        review={review}
                        updateReview={updateReview}
                      />
                    ) : (
                      <ConfirmPublish
                        review={review}
                        updateReview={updateReview}
                      />
                    )}
                    <ConfirmDelete
                      review={review}
                      updateReview={updateReview}
                    />
                  </div>
                </div>
                <Divider style={{ marginTop: "8px" }} />
              </div>
            );
          })}
        </div>
      ) : (
        <div className={classes.reviewsListFlexBox}>
          {/* <BulkActions /> */}
          <div
            className={classes.reviewsListGrid}
            style={{ backgroundColor: GRAY_THREE, borderRadius: "4px" }}
          >
            {/* <Checkbox
            color="primary"
            inputProps={{ "aria-label": "secondary checkbox" }}
          /> */}
            <Typography
              variant="subtitle2"
              className={classes.reviewsListColHeading}
            >
              Rating
            </Typography>
            <Typography
              variant="subtitle2"
              className={classes.reviewsListColHeading}
            >
              Review
            </Typography>
            <Typography
              variant="subtitle2"
              className={classes.reviewsListColHeading}
            >
              Date
            </Typography>
            <Typography
              variant="subtitle2"
              className={classes.reviewsListColHeading}
            >
              Status
            </Typography>
          </div>
          {reviews.length > 0 ? (
            reviews.map((review, index) => {
              const {
                code = "",
                rating = null,
                comment = "",
                isPublished = true,
                createdAt = "",
                givenByName = "",
              } = review || {};
              if (
                (reviewsType[1] && !isPublished) ||
                (reviewsType[2] && isPublished)
              )
                return <></>;
              return (
                <div key={code}>
                  <div className={classes.reviewsListGrid}>
                    {/* <Checkbox
                    color="primary"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  /> */}
                    <Rating
                      name="read-only"
                      readOnly
                      value={rating}
                      precision={0.5}
                      max={5}
                      icon={<StarIcon style={{ fontSize: "1.3rem" }} />}
                    />
                    <div className={classes.reviewsListReviewDiv}>
                      <Typography
                        variant="subtitle1"
                        className={classes.reviewsListReviewText}
                      >
                        {comment || ""}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        className={classes.reviewsListReviewPatientName}
                      >
                        By {givenByName || ""}
                      </Typography>
                      <div className={classes.reviewsListReviewActionsFlexBox}>
                        {isPublished ? (
                          <ConfirmUnpublish
                            review={review}
                            updateReview={updateReview}
                          />
                        ) : (
                          <ConfirmPublish
                            review={review}
                            updateReview={updateReview}
                          />
                        )}
                        <ConfirmDelete
                          review={review}
                          updateReview={updateReview}
                        />
                      </div>
                    </div>
                    <Typography
                      variant="subtitle2"
                      style={{ color: GRAY_FIVE }}
                    >
                      {format(createdAt, "d MMM yyyy, p") || ""}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      style={{
                        color: GRAY_FIVE,
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                      }}
                    >
                      <span
                        style={{
                          height: "8px",
                          width: "8px",
                          borderRadius: "50%",
                          backgroundColor: isPublished
                            ? SUCCESS_TEXT_COLOR
                            : WARNING_COLOR,
                          display: "inline-block",
                        }}
                      ></span>{" "}
                      {isPublished ? "Published" : "Unpublished"}
                    </Typography>
                  </div>
                  {index !== reviews.length - 1 && (
                    <Divider style={{ marginTop: "8px" }} />
                  )}
                </div>
              );
            })
          ) : (
            <div style={{ margin: "24px auto" }}>
              <Typography>No Reviews Found!</Typography>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ReviewsList;
