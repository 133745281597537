import React, { useContext, useEffect, useState } from "react";

import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import BootstrapInput from "../../Commons/BootstrapInput";
import useApperanceStyles from "../styles";
import {
  API_ENDPOINT_ENTERPRISE,
  API_ENDPOINT_GALLERY,
  API_RESPONSE_UNAUTHORIZED_STATUS,
  API_SECTION_API,
  API_VERSION,
  FAILURE_TEXT_COLOR,
  HARBOR_API_DOMAIN,
  THEME_PRIMARY_COLOR,
} from "../../../constants";
import {
  AppContext,
  ControlPanelContext,
  devConsoleLog,
  isEmptyObject,
} from "../../../utility";
import axios from "axios";

const Videos = ({ galleryData = [], getGalleryData }) => {
  const appearanceClasses = useApperanceStyles();
  const { setViewSnackbarMain, handleRefreshToken } = useContext(AppContext);
  const { enterprise = {}, AuthorizationToken } =
    useContext(ControlPanelContext);
  const { enterprise: selectedEnterprise = {} } = enterprise || {};
  const { code: enterpriseCode = null } = selectedEnterprise || {};
  const [videos, setVideos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [hasInputChanged, setHasInputChanged] = useState(false);

  const handleReqBody = () => {
    const error = {};
    videos.forEach((video, index) => {
      const { videoUrl = null, code = null } = video || {};
      if (!videoUrl) error[code] = "Cannot be empty";
    });
    setErrors(error);
    if (!isEmptyObject(error)) return {};
    else return { data: [...videos] };
  };

  const updateVideos = async () => {
    const url = `${HARBOR_API_DOMAIN}${API_SECTION_API}${API_VERSION}/${API_ENDPOINT_ENTERPRISE}${enterpriseCode}/${API_ENDPOINT_GALLERY}`;
    const reqBody = handleReqBody();
    if (isEmptyObject(reqBody)) return;
    setIsLoading(true);
    try {
      const response = await axios.post(url, reqBody, {
        headers: {
          "Content-Type": "application/json",
          source: "website",
          Authorization: AuthorizationToken,
        },
      });
      const { data = {}, status = null } = response || {};
      const { result = [] } = data || {};
      if (status >= 200 && status < 300) {
        setViewSnackbarMain({
          message: "Videos updated successfully",
          type: "success",
        });
        getGalleryData();
      }
    } catch (error) {
      const { response } = error || {};
      const { status } = response || {};
      if (status === API_RESPONSE_UNAUTHORIZED_STATUS) {
        handleRefreshToken();
      } else
        setViewSnackbarMain({
          message: "Something went wrong!",
          type: "error",
        });
    } finally {
      setIsLoading(false);
    }
  };

  const addVideoUrl = () => {
    setVideos((prev) => [...prev, { videoUrl: null }]);
  };

  const handleInputChange = (e, index) => {
    const copyOfVideos = [...videos];
    const videoObjTobeChanged = copyOfVideos[index];
    const { code = null } = videoObjTobeChanged || {};
    delete errors[code];
    videoObjTobeChanged["videoUrl"] = e.target.value;
    setVideos(copyOfVideos);
    setHasInputChanged(true);
  };

  const handleRemoveVideoUrl = (index) => {
    const copyOfVideos = [...videos];
    const videoObjTobeChanged = copyOfVideos[index];
    const { code = null } = videoObjTobeChanged || {};
    if (code) videoObjTobeChanged["isActive"] = false;
    else copyOfVideos.splice(index, 1);
    setVideos(copyOfVideos);
    setHasInputChanged(true);
  };

  useEffect(() => {
    if (galleryData["videos"].length > 0) {
      const { videos = [] } = galleryData || {};
      setVideos(videos);
    } else setVideos((prev) => [...prev, { videoUrl: null }]);
  }, [galleryData]);

  return (
    <Box className={appearanceClasses.tabContentBox}>
      <Box className={appearanceClasses.designBox}>
        <Box>
          <Typography
            className={appearanceClasses.designBoxHeading}
            variant="subtitle1"
          >
            Videos
          </Typography>
          <Typography
            className={appearanceClasses.designBoxSubHeading}
            variant="subtitle2"
          >
            Enter ID from your Youtube Video to display. Example:
            www.youtube.com/watch?v=
            <span
              style={{
                color: THEME_PRIMARY_COLOR,
                fontWeight: "600",
                textDecoration: "underline",
              }}
            >
              ID
            </span>
          </Typography>
        </Box>
        <Box style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
          {videos.map((video, index) => {
            const { videoUrl = "", code = null, isActive = true } = video || {};
            if (!isActive) return <></>;
            return (
              <Box style={{ display: "flex", gap: "16px" }}>
                <FormControl key={code} style={{ flex: 1 }}>
                  <InputLabel shrink htmlFor={`videoUrl${code}`}>
                    Video Url Id
                  </InputLabel>
                  <BootstrapInput
                    name="videoUrl"
                    onChange={(e) => handleInputChange(e, index)}
                    value={videoUrl}
                    placeholder="Example - ZQL7tL2S0oQ"
                    id={`videoUrl${code}`}
                  />
                  {Boolean(errors[code]) && (
                    <FormHelperText error={errors[code]}>
                      {errors[code]}
                    </FormHelperText>
                  )}
                  {index > 0 && (
                    <Typography
                      align="right"
                      variant="subtitle2"
                      style={{
                        color: FAILURE_TEXT_COLOR,
                        marginTop: "8px",
                        fontSize: "14px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleRemoveVideoUrl(index)}
                    >
                      Remove
                    </Typography>
                  )}
                </FormControl>
                {/* <DeleteForeverIcon
                  style={{ alignSelf: "start", fontSize: "2rem" }}
                /> */}
              </Box>
            );
          })}
          <Button
            variant="outlined"
            color="primary"
            style={{ width: "160px", textTransform: "none" }}
            onClick={addVideoUrl}
          >
            <AddIcon /> Add another
          </Button>
          {/* <Box
            style={{
              padding: "12px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: GRAY_FOUR,
              height: "48px",
              width: "48px",
              borderRadius: "50%",
            }}
          >
            <AddIcon />
          </Box> */}
        </Box>
      </Box>
      <Box style={{ textAlign: "end" }}>
        <Button
          variant="contained"
          color="primary"
          style={{
            textTransform: "none",
            padding: "4px 16px",
            fontSize: "14px",
          }}
          disabled={!hasInputChanged}
          onClick={updateVideos}
        >
          {isLoading ? (
            <CircularProgress
              thickness={5}
              size={"1.2rem"}
              style={{ color: "#ffffff" }}
            />
          ) : (
            "Update"
          )}
        </Button>
      </Box>
    </Box>
  );
};

export default Videos;
