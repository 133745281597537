import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import {
  API_ENDPOINT_ENTERPRISE,
  API_ENDPOINT_INVITED_DOCTORS,
  API_RESPONSE_UNAUTHORIZED_STATUS,
  API_SECTION_API,
  API_VERSION,
  DOCFYN_API_DOMAIN,
  DOCTOR_INVITE_STATUS,
  THEME_WEBSITE_SECONDARY_BG_COLOR,
} from "../../constants";
import { AppContext, ControlPanelContext } from "../../utility";
import useStyles from "./styles";

const InvitedDoctors = () => {
  const classes = useStyles();
  const { enterprise, AuthorizationToken } = useContext(ControlPanelContext);
  const { setViewSnackbarMain, handleRefreshToken, mobileView } = useContext(AppContext);
  const { enterprise: currentEnterprise = {} } = enterprise || {};
  const { code: enterpriseCode = null } = currentEnterprise || {};
  const [invitedDoctorsList, setInvitedDoctorsList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getInvitedDoctors = async () => {
    if (!enterpriseCode) return;
    setIsLoading(true);
    const url = `${DOCFYN_API_DOMAIN}${API_SECTION_API}${API_VERSION}/${API_ENDPOINT_ENTERPRISE}${API_ENDPOINT_INVITED_DOCTORS}?code=${enterpriseCode}`;
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          source: "website",
          Authorization: AuthorizationToken,
        },
      });
      const { status = null, data = {} } = response || {};
      const { result = {} } = data || {};
      const { invitedDoctors = [] } = result || {};
      if (status >= 200 && status < 300) {
        setInvitedDoctorsList(invitedDoctors);
      }
    } catch (error) {
      const { response } = error || {};
      const { status } = response || {};
      if (status === API_RESPONSE_UNAUTHORIZED_STATUS) {
        handleRefreshToken();
      } else
        setViewSnackbarMain({
          message: "Something went wrong!",
          type: "error",
        });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (enterpriseCode) getInvitedDoctors();
  }, [enterpriseCode]);

  if (isLoading)
    return (
      <Box style={{ width: "100%", textAlign: "center" }}>
        <CircularProgress />
      </Box>
    );
  else if (invitedDoctorsList.length === 0) return <Typography align="center" variant="h6" style={{width: '100%', fontSize: '1.5rem'}}>No Invited Doctors!</Typography>
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead
          style={{ backgroundColor: THEME_WEBSITE_SECONDARY_BG_COLOR }}
        >
          <TableRow>
            <TableCell classes={{ root: classes.tableCell }}>Doctor</TableCell>
            <TableCell classes={{ root: classes.tableCell }}>
              Speciality
            </TableCell>
            <TableCell classes={{ root: classes.tableCell }}>Status</TableCell>
            {!mobileView && <TableCell classes={{ root: classes.tableCell }}>Email</TableCell>}
            {/* <TableCell classes={{ root: classes.tableCell }}>Action</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {invitedDoctorsList.map((row) => {
            const {
              name: doctorName = "",
              medicalSpeciality = {},
              inviteStatus = 1,
              email = "",
            } = row || {};
            const { name: medicalSpecialityName = "" } = medicalSpeciality || {}
            return (
              <TableRow key={doctorName}>
                <TableCell
                  classes={{ root: classes.tableCell }}
                  component="th"
                  scope="row"
                >
                  {doctorName || "-"}
                </TableCell>
                <TableCell classes={{ root: classes.tableCell }}>
                  {medicalSpecialityName || "-"}
                </TableCell>
                <TableCell classes={{ root: classes.tableCell }}>
                    <Typography variant="subtitle2" style={{color: DOCTOR_INVITE_STATUS[inviteStatus].color || 'black'}}>{DOCTOR_INVITE_STATUS[inviteStatus].status}</Typography>
                  {/* <FormControlLabel
                    control={
                      <IOSSwitch
                        checked={isActive}
                        // onChange={handleChange}
                        name="checkedB"
                      />
                    }
                    label={isActive ? "Active" : "Unactive"}
                  /> */}
                </TableCell>
                {!mobileView && 
                <TableCell classes={{ root: classes.tableCell }}>
                  {email || "-"}
                </TableCell>}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InvitedDoctors;
