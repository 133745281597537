import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import YouTubeIcon from "@material-ui/icons/YouTube";

export const BUILD_TYPE = process.env.REACT_APP_BUILD_TYPE;
export const DOCFYN_LOGO = "/travclan-logo.png";
export const DOCFYN_API_DOMAIN = process.env.REACT_APP_DOCFYN_API_DOMAIN;
export const DOCFYN_AUTH_API_DOMAIN =
  process.env.REACT_APP_DOCFYN_AUTH_API_DOMAIN;
export const HARBOR_API_DOMAIN = process.env.REACT_APP_HARBOR_API_DOMAIN;
export const DOCSITE_DOMAIN_NAME = process.env.REACT_APP_DOCSITE_DOMAIN_NAME;
export const TINYMCE_API_KEY = process.env.REACT_APP_TINYMCE_API_KEY;
export const DOCFYN_DOCS_DOMAIN = process.env.REACT_APP_DOCFYN_DOCS_DOMAIN;
export const DOCFYN_MARKETPLACE_DOMAIN = process.env.REACT_APP_DOCFYN_MARKETPLACE_DOMAIN;
export const API_SECTION_API = "api/";
export const API_SECTION_PUBLIC = "public/";
export const API_SECTION_PROFILE = "profile/";
export const API_SECTION_DOCTOR = "doctor/";
export const API_SECTION_FEED = "feed/";
export const API_SECTION_ENTITY = "entity/";
export const API_SECTION_WEBSITE = "website/";
export const API_SECTION_AUTHENTICATION = "authentication/";
export const API_SECTION_DOCS = "docs/";
export const API_SECTION_APPOINTMENT = "appointment/";
export const API_SECTION_PLUGIN = "plugin/";
export const API_DOMAIN_HELP = process.env.REACT_APP_API_DOMAIN_HELP;
export const API_ENDPOINT_AREAS = "areas/";
export const API_ENDPOINT_SPECIALITIES = "medical-specialities/";
export const API_ENDPOINT_REQUEST_OTP = "request-otp/";
export const API_ENDPOINT_VERIFY_OTP = "verify-otp/";
export const API_ENDPOINT_SIGNUP = "sign-up";
export const API_ENDPOINT_REVIEWS = "reviews/";
export const API_ENDPOINT_COMMENT = "comment/";
export const API_ENDPOINT_REFRESH = "refresh/";
export const API_ENDPOINT_INVITE_DOCTOR = "invite-doctor/";
export const API_ENDPOINT_ENTERPRISE = "enterprise/";
export const API_ENDPOINT_DOCTORS = "doctors/";
export const API_ENDPOINT_THEMES = "themes/";
export const API_ENDPOINT_BANNERS = "banners/";
export const API_ENDPOINT_BLOGS = "blogs/";
export const API_ENDPOINT_GALLERY = "gallery/";
export const API_ENDPOINT_FAQS = "faqs/";
export const API_ENDPOINT_SERVICES = "services/";
export const API_ENDPOINT_CONTACT_US = "contact-us/";
export const API_ENDPOINT_UPLOAD = "upload";
export const API_ENDPOINT_INVITED_DOCTORS = "invited-doctors/";
export const API_ENDPOINT_PRACTICE_TIMINGS = "practice-timings/";
export const API_ENDPOINT_BOOK = "book/";
export const API_ENDPOINT_SLOTS = "slots/";
export const API_ENDPOINT_APPS = "apps/";
export const API_ENDPOINT_SUBSCRIBE = "subscribe/";
export const API_ENDPOINT_UNSUBSCRIBE = "unsubscribe/";
export const API_VERSION = "v1";
export const BUCKET_NAME = process.env.REACT_APP_BUCKET_NAME;
export const HEADER_MUI_THEME_HEIGHT_PX = 7;
export const THEME_PRIMARY_COLOR = "#115687"; //'#212121'  //'#428bfe'
export const THEME_SECONDARY_COLOR = "#FAD507"; //'#FBAD23'  //'#f18d03'
export const THEME_WEBSITE_BG_COLOR = "#FFFFFF";
export const THEME_WEBSITE_SECONDARY_BG_COLOR = "#F3F4F5";
export const THEME_WEBSITE_BLACK_COLOR = "#212121";
export const THEME_WEBSITE_YELLOW_COLOR = "#FFF7E9";
export const THEME_OTHER_SECONDARY_COLOR = "#FBAD23";
export const THEME_WEBSITE_GREY_COLOR = "#8B8B8B";
export const THEME_WEBSITE_SECONDARY_BLACK_COLOR = "#333333";
export const LIGHT_BLUE_COLOR = "#DAF5FF";
export const PRIMARY_TEXT_COLOR = "#333333";
export const FAILURE_TEXT_COLOR = "#ED3A4F";
export const SUCCESS_TEXT_COLOR = "#75C87E";
export const SUCCESS_ICON_COLOR = "#56BE32";
export const WARNING_COLOR = "#f18d03"
export const GRAY_ONE = "#828282";
export const GRAY_TWO = "#BDBDBD";
export const GRAY_THREE = "#F5F5F5";
export const GRAY_FOUR = "#E8E8E8";
export const GRAY_FIVE = "#4D4D4D";
export const DOMAIN_FIELD_BACKGROUND = "#F5F5F5";
export const BOX_SHADOW = "0 3px 8px #1a181e0a";
export const API_RESPONSE_UNAUTHORIZED_STATUS = 401;
export const TITLE_DATA = [
  {
    label: "Mr",
    value: "Mr",
    gender: "male",
  },
  {
    label: "Miss",
    value: "Miss",
    gender: "female",
  },
  {
    label: "Mrs",
    value: "Mrs",
    gender: "female",
  },
];

export const STARLABELS = {
  1: "Poor",
  2: "Ok",
  3: "Good",
  4: "Very Good",
  5: "Excellent",
};

export const USER_TYPE = {
  1: "doctor",
  2: "patient",
};

export const GENDERS = {
  1: "Male",
  2: "Female",
  3: "Others",
};

export const BOOKING_STATUS = {
  1: "Pending",
  2: "Confirmed",
  3: "Failed",
  4: "Cancelled"
}

export const TwitterIcon = (props) => {
  return <img alt="twitter" src="/twitter-icon.svg" {...props} />
}

export const SOCIAL_LINKS = {
  1: {
    Icon: FacebookIcon,
    name: "Facebook",
  },
  2: {
    Icon: InstagramIcon,
    name: "Instagram",
  },
  3: {
    Icon: TwitterIcon,
    name: "X",
  },
  4: {
    Icon: LinkedInIcon,
    name: "LinkedIn",
  },
  5: {
    Icon: YouTubeIcon,
    name: "Youtube",
  },
};

export const DOCTOR_INVITE_STATUS = {
  1: { status: "Pending", color: '#FF9800' },
  2: { status: "Rejected", color: FAILURE_TEXT_COLOR },
  3: { status: "Accepted", color: SUCCESS_TEXT_COLOR },
  4: { status: 'NA', color: 'black' }
};

export const INPUT_DATA_LAST_UPDATED = "INPUT_DATA_LAST_UPDATED";
export const INPUT_DATA_CACHE_TIMEOUT = 5184000;

export const DEFAULT_CURRENCY = "INR";

export const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY; //BUILD_TYPE == "PRODUCTION" ? 'AIzaSyAatDFDc5G7rjoX1Din_mMUcyz6DYxFqDk' : 'AIzaSyDdPL8f8Gs4-guFUxW-zv2SI-HP1ZlH2JI';

export const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const windowHeight = "calc(100vh - 75px)";

export const countryToFlag = (isoCode) => {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
};

export const COUNTRIES = [
  {
    name: "India",
    dial_code: "+91",
    code: "IN",
    label: "India +91",
    value: "+91",
  },
  {
    name: "Australia",
    dial_code: "+61",
    code: "AU",
    label: "Australia +61",
    value: "+61",
  },
  {
    name: "Austria",
    dial_code: "+43",
    code: "AT",
    label: "Austria +43",
    value: "+43",
  },
  {
    name: "Azerbaijan",
    dial_code: "+994",
    code: "AZ",
    label: "Azerbaijan +994",
    value: "+994",
  },
  {
    name: "Bahamas",
    dial_code: "+1 242",
    code: "BS",
    label: "Bahamas +1 242",
    value: "+1 242",
  },
  {
    name: "Bahrain",
    dial_code: "+973",
    code: "BH",
    label: "Bahrain +973",
    value: "+973",
  },
  {
    name: "Bangladesh",
    dial_code: "+880",
    code: "BD",
    label: "Bangladesh +880",
    value: "+880",
  },
  {
    name: "Barbados",
    dial_code: "+1 246",
    code: "BB",
    label: "Barbados +1 246",
    value: "+1 246",
  },
  {
    name: "Belarus",
    dial_code: "+375",
    code: "BY",
    label: "Belarus +375",
    value: "+375",
  },
  {
    name: "Belgium",
    dial_code: "+32",
    code: "BE",
    label: "Belgium +32",
    value: "+32",
  },
  {
    name: "Belize",
    dial_code: "+501",
    code: "BZ",
    label: "Belize +501",
    value: "+501",
  },
  {
    name: "Benin",
    dial_code: "+229",
    code: "BJ",
    label: "Benin +229",
    value: "+229",
  },
  {
    name: "Bermuda",
    dial_code: "+1 441",
    code: "BM",
    label: "Bermuda +1 441",
    value: "+1 441",
  },
  {
    name: "Bhutan",
    dial_code: "+975",
    code: "BT",
    label: "Bhutan +975",
    value: "+975",
  },
  {
    name: "Bolivia",
    dial_code: "+591",
    code: "BO",
    label: "Bolivia +591",
    value: "+591",
  },
  {
    name: "Bosnia and HZ",
    dial_code: "+387",
    code: "BA",
    label: "Bosnia and HZ +387",
    value: "+387",
  },
  {
    name: "Botswana",
    dial_code: "+267",
    code: "BW",
    label: "Botswana +267",
    value: "+267",
  },
  {
    name: "Brazil",
    dial_code: "+55",
    code: "BR",
    label: "Brazil +55",
    value: "+55",
  },
  {
    name: "Bulgaria",
    dial_code: "+359",
    code: "BG",
    label: "Bulgaria +359",
    value: "+359",
  },
  {
    name: "Cambodia",
    dial_code: "+855",
    code: "KH",
    label: "Cambodia +855",
    value: "+855",
  },
  {
    name: "Canada",
    dial_code: "+1",
    code: "CA",
    label: "Canada +1",
    value: " +1",
  },
  {
    name: "China",
    dial_code: "+86",
    code: "CN",
    label: "China +86",
    value: "+86",
  },
  {
    name: "Colombia",
    dial_code: "+57",
    code: "CO",
    label: "Colombia +57",
    value: "+57",
  },
  {
    name: "Croatia",
    dial_code: "+385",
    code: "HR",
    label: "Croatia +385",
    value: "+385",
  },
  {
    name: "Cuba",
    dial_code: "+53",
    code: "CU",
    label: "Cuba +53",
    value: "+53",
  },
  {
    name: "Cyprus",
    dial_code: "+537",
    code: "CY",
    label: "Cyprus +537",
    value: "+537",
  },
  {
    name: "Czech Republic",
    dial_code: "+420",
    code: "CZ",
    label: "Czech Republic +420",
    value: "+420",
  },
  {
    name: "Denmark",
    dial_code: "+45",
    code: "DK",
    label: "Denmark +45",
    value: "+45",
  },
  {
    name: "Ecuador",
    dial_code: "+593",
    code: "EC",
    label: "Ecuador +593",
    value: "+593",
  },
  {
    name: "Egypt",
    dial_code: "+20",
    code: "EG",
    label: "Egypt +20",
    value: "+20",
  },
  {
    name: "Ethiopia",
    dial_code: "+251",
    code: "ET",
    label: "Ethiopia +251",
    value: "+251",
  },
  {
    name: "Finland",
    dial_code: "+358",
    code: "FI",
    label: "Finland +358",
    value: "+358",
  },
  {
    name: "France",
    dial_code: "+33",
    code: "FR",
    label: "France +33",
    value: "+33",
  },
  {
    name: "Germany",
    dial_code: "+49",
    code: "DE",
    label: "Germany +49",
    value: "+49",
  },
  {
    name: "Greece",
    dial_code: "+30",
    code: "GR",
    label: "Greece +30",
    value: "+30",
  },
  {
    name: "Greenland",
    dial_code: "+299",
    code: "GL",
    label: "Greenland +299",
    value: "+299",
  },
  {
    name: "Honduras",
    dial_code: "+504",
    code: "HN",
    label: "Honduras +504",
    value: "+504",
  },
  {
    name: "Hong Kong",
    dial_code: "+852",
    code: "HK",
    label: "Hong Kong +852",
    value: "+852",
  },
  {
    name: "Hungary",
    dial_code: "+36",
    code: "HU",
    label: "Hungary +36",
    value: "+36",
  },
  {
    name: "Iceland",
    dial_code: "+354",
    code: "IS",
    label: "Iceland +354",
    value: "+354",
  },
  {
    name: "India",
    dial_code: "+91",
    code: "IN",
    label: "India +91",
    value: "+91",
  },
  {
    name: "Indonesia",
    dial_code: "+62",
    code: "ID",
    label: "Indonesia +62",
    value: "+62",
  },
  {
    name: "Iran",
    dial_code: "+98",
    code: "IR",
    label: "Iran +98",
    value: "+98",
  },
  {
    name: "Iraq",
    dial_code: "+964",
    code: "IQ",
    label: "Iraq +964",
    value: "+964",
  },
  {
    name: "Ireland",
    dial_code: "+353",
    code: "IE",
    label: "Ireland +353",
    value: "+353",
  },
  {
    name: "Isle of Man",
    dial_code: "+44",
    code: "IM",
    label: "Isle of Man +44",
    value: "+44",
  },
  {
    name: "Israel",
    dial_code: "+972",
    code: "IL",
    label: "Israel +972",
    value: "+972",
  },
  {
    name: "Italy",
    dial_code: "+39",
    code: "IT",
    label: "Italy +39",
    value: "+39",
  },
  {
    name: "Jamaica",
    dial_code: "+1 876",
    code: "JM",
    label: "Jamaica +1 876",
    value: "+1 876",
  },
  {
    name: "Japan",
    dial_code: "+81",
    code: "JP",
    label: "Japan +81",
    value: "+81",
  },
  {
    name: "Jersey",
    dial_code: "+44",
    code: "JE",
    label: "Jersey +44",
    value: "+44",
  },
  {
    name: "Jordan",
    dial_code: "+962",
    code: "JO",
    label: "Jordan +962",
    value: "+962",
  },
  {
    name: "Kazakhstan",
    dial_code: "+7 7",
    code: "KZ",
    label: "Kazakhstan +7 7",
    value: "+7 7",
  },
  {
    name: "Kenya",
    dial_code: "+254",
    code: "KE",
    label: "Kenya +254",
    value: "+254",
  },
  {
    name: "Kuwait",
    dial_code: "+965",
    code: "KW",
    label: "Kuwait +965",
    value: "+965",
  },
  {
    name: "Kyrgyzstan",
    dial_code: "+996",
    code: "KG",
    label: "Kyrgyzstan +996",
    value: "+996",
  },
  {
    name: "Latvia",
    dial_code: "+371",
    code: "LV",
    label: "Latvia +371",
    value: "+371",
  },
  {
    name: "Lebanon",
    dial_code: "+961",
    code: "LB",
    label: "Lebanon +961",
    value: "+961",
  },
  {
    name: "Liberia",
    dial_code: "+231",
    code: "LR",
    label: "Liberia +231",
    value: "+231",
  },
  {
    name: "Liechtenstein",
    dial_code: "+423",
    code: "LI",
    label: "Liechtenstein +423",
    value: "+423",
  },
  {
    name: "Lithuania",
    dial_code: "+370",
    code: "LT",
    label: "Lithuania +370",
    value: "+370",
  },
  {
    name: "Luxembourg",
    dial_code: "+352",
    code: "LU",
    label: "Luxembourg +352",
    value: "+352",
  },
  {
    name: "Macao",
    dial_code: "+853",
    code: "MO",
    label: "Macao +853",
    value: "+853",
  },
  {
    name: "Macedonia",
    dial_code: "+389",
    code: "MK",
    label: "Macedonia +389",
    value: "+389",
  },
  {
    name: "Madagascar",
    dial_code: "+261",
    code: "MG",
    label: "Madagascar +261",
    value: "+261",
  },
  {
    name: "Malawi",
    dial_code: "+265",
    code: "MW",
    label: "Malawi +265",
    value: "+265",
  },
  {
    name: "Malaysia",
    dial_code: "+60",
    code: "MY",
    label: "Malaysia +60",
    value: "+60",
  },
  {
    name: "Maldives",
    dial_code: "+960",
    code: "MV",
    label: "Maldives +960",
    value: "+960",
  },
  {
    name: "Mali",
    dial_code: "+223",
    code: "ML",
    label: "Mali +223",
    value: "+223",
  },
  {
    name: "Malta",
    dial_code: "+356",
    code: "MT",
    label: "Malta +356",
    value: "+356",
  },
  {
    name: "Mauritius",
    dial_code: "+230",
    code: "MU",
    label: "Mauritius +230",
    value: "+230",
  },
  {
    name: "Mexico",
    dial_code: "+52",
    code: "MX",
    label: "Mexico +52",
    value: "+52",
  },
  {
    name: "Mongolia",
    dial_code: "+976",
    code: "MN",
    label: "Mongolia +976",
    value: "+976",
  },
  {
    name: "Montenegro",
    dial_code: "+382",
    code: "ME",
    label: "Montenegro +382",
    value: "+382",
  },
  {
    name: "Morocco",
    dial_code: "+212",
    code: "MA",
    label: "Morocco +212",
    value: "+212",
  },
  {
    name: "Mozambique",
    dial_code: "+258",
    code: "MZ",
    label: "Mozambique +258",
    value: "+258",
  },
  {
    name: "Myanmar",
    dial_code: "+95",
    code: "MM",
    label: "Myanmar +95",
    value: "+95",
  },
  {
    name: "Namibia",
    dial_code: "+264",
    code: "NA",
    label: "Namibia +264",
    value: "+264",
  },
  {
    name: "Nauru",
    dial_code: "+674",
    code: "NR",
    label: "Nauru +674",
    value: "+674",
  },
  {
    name: "Nepal",
    dial_code: "+977",
    code: "NP",
    label: "Nepal +977",
    value: "+977",
  },
  {
    name: "Netherlands",
    dial_code: "+31",
    code: "NL",
    label: "Netherlands +31",
    value: "+31",
  },
  {
    name: "New Zealand",
    dial_code: "+64",
    code: "NZ",
    label: "New Zealand +64",
    value: "+64",
  },
  {
    name: "Nicaragua",
    dial_code: "+505",
    code: "NI",
    label: "Nicaragua +505",
    value: "+505",
  },
  {
    name: "Niger",
    dial_code: "+227",
    code: "NE",
    label: "Niger +227",
    value: "+227",
  },
  {
    name: "Nigeria",
    dial_code: "+234",
    code: "NG",
    label: "Nigeria +234",
    value: "+234",
  },
  {
    name: "Niue",
    dial_code: "+683",
    code: "NU",
    label: "Niue +683",
    value: "+683",
  },
  {
    name: "Norfolk Island",
    dial_code: "+672",
    code: "NF",
    label: "Norfolk Island +672",
    value: "+672",
  },
  {
    name: "Norway",
    dial_code: "+47",
    code: "NO",
    label: "Norway +47",
    value: "+47",
  },
  {
    name: "Oman",
    dial_code: "+968",
    code: "OM",
    label: "Oman +968",
    value: "+968",
  },
  {
    name: "Pakistan",
    dial_code: "+92",
    code: "PK",
    label: "Pakistan +92",
    value: "+92",
  },
  {
    name: "Palau",
    dial_code: "+680",
    code: "PW",
    label: "Palau +680",
    value: "+680",
  },
  {
    name: "Panama",
    dial_code: "+507",
    code: "PA",
    label: "Panama +507",
    value: "+507",
  },
  {
    name: "Papua New Guinea",
    dial_code: "+675",
    code: "PG",
    label: "Papua New Guinea +675",
    value: "+675",
  },
  {
    name: "Paraguay",
    dial_code: "+595",
    code: "PY",
    label: "Paraguay +595",
    value: "+595",
  },
  {
    name: "Peru",
    dial_code: "+51",
    code: "PE",
    label: "Peru +51",
    value: "+51",
  },
  {
    name: "Philippines",
    dial_code: "+63",
    code: "PH",
    label: "Philippines +63",
    value: "+63",
  },
  {
    name: "Pitcairn",
    dial_code: "+872",
    code: "PN",
    label: "Pitcairn +872",
    value: "+872",
  },
  {
    name: "Poland",
    dial_code: "+48",
    code: "PL",
    label: "Poland +48",
    value: "+48",
  },
  {
    name: "Portugal",
    dial_code: "+351",
    code: "PT",
    label: "Portugal +351",
    value: "+351",
  },
  {
    name: "Puerto Rico",
    dial_code: "+1 939",
    code: "PR",
    label: "Puerto Rico +1 939",
    value: "+1 939",
  },
  {
    name: "Qatar",
    dial_code: "+974",
    code: "QA",
    label: "Qatar +974",
    value: "+974",
  },
  {
    name: "Romania",
    dial_code: "+40",
    code: "RO",
    label: "Romania +40",
    value: "+40",
  },
  {
    name: "Russia",
    dial_code: "+7",
    code: "RU",
    label: "Russia +7",
    value: "+7",
  },
  {
    name: "Rwanda",
    dial_code: "+250",
    code: "RW",
    label: "Rwanda +250",
    value: "+250",
  },
  {
    name: "Réunion",
    dial_code: "+262",
    code: "RE",
    label: "Réunion +262",
    value: "+262",
  },
  {
    name: "Saudi Arabia",
    dial_code: "+966",
    code: "SA",
    label: "Saudi Arabia +966",
    value: "+966",
  },
  {
    name: "Serbia",
    dial_code: "+381",
    code: "RS",
    label: "Serbia +381",
    value: "+381",
  },
  {
    name: "Seychelles",
    dial_code: "+248",
    code: "SC",
    label: "Seychelles +248",
    value: "+248",
  },
  {
    name: "Sierra Leone",
    dial_code: "+232",
    code: "SL",
    label: "Sierra Leone +232",
    value: "+232",
  },
  {
    name: "Singapore",
    dial_code: "+65",
    code: "SG",
    label: "Singapore +65",
    value: "+65",
  },
  {
    name: "Slovakia",
    dial_code: "+421",
    code: "SK",
    label: "Slovakia +421",
    value: "+421",
  },
  {
    name: "Slovenia",
    dial_code: "+386",
    code: "SI",
    label: "Slovenia +386",
    value: "+386",
  },
  {
    name: "Solomon Islands",
    dial_code: "+677",
    code: "SB",
    label: "Solomon Islands +677",
    value: "+677",
  },
  {
    name: "Somalia",
    dial_code: "+252",
    code: "SO",
    label: "Somalia +252",
    value: "+252",
  },
  {
    name: "South Africa",
    dial_code: "+27",
    code: "ZA",
    label: "South Africa +27",
    value: "+27",
  },
  {
    name: "Spain",
    dial_code: "+34",
    code: "ES",
    label: "Spain +34",
    value: "+34",
  },
  {
    name: "Sri Lanka",
    dial_code: "+94",
    code: "LK",
    label: "Sri Lanka +94",
    value: "+94",
  },
  {
    name: "Sudan",
    dial_code: "+249",
    code: "SD",
    label: "Sudan +249",
    value: "+249",
  },
  {
    name: "Sweden",
    dial_code: "+46",
    code: "SE",
    label: "Sweden +46",
    value: "+46",
  },
  {
    name: "Switzerland",
    dial_code: "+41",
    code: "CH",
    label: "Switzerland +41",
    value: "+41",
  },
  {
    name: "Syrian ",
    dial_code: "+963",
    code: "SY",
    label: "Syrian  +963",
    value: "+963",
  },
  {
    name: "Taiwan",
    dial_code: "+886",
    code: "TW",
    label: "Taiwan +886",
    value: "+886",
  },
  {
    name: "Tajikistan",
    dial_code: "+992",
    code: "TJ",
    label: "Tajikistan +992",
    value: "+992",
  },
  {
    name: "Tanzania",
    dial_code: "+255",
    code: "TZ",
    label: "Tanzania +255",
    value: "+255",
  },
  {
    name: "Thailand",
    dial_code: "+66",
    code: "TH",
    label: "Thailand +66",
    value: "+66",
  },
  {
    name: "Timor-Leste",
    dial_code: "+670",
    code: "TL",
    label: "Timor-Leste +670",
    value: "+670",
  },
  {
    name: "Togo",
    dial_code: "+228",
    code: "TG",
    label: "Togo +228",
    value: "+228",
  },
  {
    name: "Tokelau",
    dial_code: "+690",
    code: "TK",
    label: "Tokelau +690",
    value: "+690",
  },
  {
    name: "Tonga",
    dial_code: "+676",
    code: "TO",
    label: "Tonga +676",
    value: "+676",
  },
  {
    name: "Trinidad",
    dial_code: "+1 868",
    code: "TT",
    label: "Trinidad +1 868",
    value: "+1 868",
  },
  {
    name: "Tunisia",
    dial_code: "+216",
    code: "TN",
    label: "Tunisia +216",
    value: "+216",
  },
  {
    name: "Turkey",
    dial_code: "+90",
    code: "TR",
    label: "Turkey +90",
    value: "+90",
  },
  {
    name: "Turkmenistan",
    dial_code: "+993",
    code: "TM",
    label: "Turkmenistan +993",
    value: "+993",
  },
  {
    name: "Uganda",
    dial_code: "+256",
    code: "UG",
    label: "Uganda +256",
    value: "+256",
  },
  {
    name: "Ukraine",
    dial_code: "+380",
    code: "UA",
    label: "Ukraine +380",
    value: "+380",
  },
  {
    name: "UAE",
    dial_code: "+971",
    code: "AE",
    label: "UAE +971",
    value: "+971",
  },
  {
    name: "United Kingdom",
    dial_code: "+44",
    code: "GB",
    label: "United Kingdom +44",
    value: "+44",
  },
  {
    name: "United States",
    dial_code: "+1",
    code: "US",
    label: "United States +1",
    value: "+1",
  },
  {
    name: "Uruguay",
    dial_code: "+598",
    code: "UY",
    label: "Uruguay +598",
    value: "+598",
  },
  {
    name: "Uzbekistan",
    dial_code: "+998",
    code: "UZ",
    label: "Uzbekistan +998",
    value: "+998",
  },
  {
    name: "Vanuatu",
    dial_code: "+678",
    code: "VU",
    label: "Vanuatu +678",
    value: "+678",
  },
  {
    name: "Venezuela",
    dial_code: "+58",
    code: "VE",
    label: "Venezuela +58",
    value: "+58",
  },
  {
    name: "Vietnam",
    dial_code: "+84",
    code: "VN",
    label: "Vietnam +84",
    value: "+84",
  },
  {
    name: "Virgin Islands",
    dial_code: "+1 284",
    code: "VG",
    label: "Virgin Islands +1 284",
    value: "+1 284",
  },
  {
    name: "Yemen",
    dial_code: "+967",
    code: "YE",
    label: "Yemen +967",
    value: "+967",
  },
  {
    name: "Zambia",
    dial_code: "+260",
    code: "ZM",
    label: "Zambia +260",
    value: "+260",
  },
  {
    name: "Zimbabwe",
    dial_code: "+263",
    code: "ZW",
    label: "Zimbabwe +263",
    value: "+263",
  },
];
