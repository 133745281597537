import React, { useContext, useEffect, useState } from "react";
import axios from "axios";

import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import {
  API_ENDPOINT_ENTERPRISE,
  API_ENDPOINT_SERVICES,
  API_RESPONSE_UNAUTHORIZED_STATUS,
  API_SECTION_API,
  API_VERSION,
  GRAY_ONE,
  GRAY_THREE,
  HARBOR_API_DOMAIN,
} from "../../constants";
import useApperanceStyles from "./styles";
import { AppContext, ControlPanelContext, devConsoleLog, getThumborUrl } from "../../utility";

const Services = () => {
  const classes = useApperanceStyles();
  const { setViewSnackbarMain, handleRefreshToken } = useContext(AppContext);
  const {
    setHeaderHeading,
    AuthorizationToken,
    enterprise = {},
  } = useContext(ControlPanelContext);
  const { enterprise: selectedEnterprise = {} } = enterprise || {};
  const { code: enterpriseCode = null } = selectedEnterprise || {};
  const [services, setServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [servicesObj, setServicesObj] = useState({});
  const [hasServicesChanged, setHasServicesChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getSelectedServicesData = async () => {
    const url = `${HARBOR_API_DOMAIN}${API_SECTION_API}${API_VERSION}/${API_ENDPOINT_ENTERPRISE}${enterpriseCode}/${API_ENDPOINT_SERVICES}`;
    setIsLoading(true);
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          source: "website",
          Authorization: AuthorizationToken,
        },
      });
      const { data = {}, status = null } = response || {};
      const { result = [] } = data || {};
      if (status >= 200 && status < 300) {
        // setSelectedServices(result);
        const arr = [];
        const obj = {};
        result.forEach((item) => {
          const { service = {} } = item || {};
          const { code = null } = service || {};
          obj[code] = item;
          arr.push(service);
        });
        setServicesObj(obj);
        setSelectedServices(arr);
      }
    } catch (error) {
      devConsoleLog("getServicesData", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getServicesData = async () => {
    const url = `${HARBOR_API_DOMAIN}${API_SECTION_API}${API_VERSION}/${API_ENDPOINT_SERVICES}`;
    setIsLoading(true);
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          source: "website",
          Authorization: AuthorizationToken,
        },
      });
      const { data = {}, status = null } = response || {};
      const { result = [] } = data || {};
      if (status >= 200 && status < 300) {
        setServices(result);
        getSelectedServicesData();
      }
    } catch (error) {
      const { response } = error || {};
      const { status } = response || {};
      if (status === API_RESPONSE_UNAUTHORIZED_STATUS) {
        handleRefreshToken();
      } else
        setViewSnackbarMain({
          message: "Something went wrong!",
          type: "error",
        });
    } finally {
      setIsLoading(false);
    }
  };

  const handleReqBody = () => {
    // const reqBody = {};
    const reqBody = Object.values(servicesObj).map((item) => {
      const { service = {} } = item || {};
      const { code = null } = service || {};
      item["code"] = code;
      delete item["service"];
      return { ...item };
    });
    return { data: [...reqBody] };
  };

  const updateServices = async () => {
    const url = `${HARBOR_API_DOMAIN}${API_SECTION_API}${API_VERSION}/${API_ENDPOINT_ENTERPRISE}${enterpriseCode}/${API_ENDPOINT_SERVICES}`;
    const reqBody = handleReqBody();
    try {
      const response = await axios.post(url, reqBody, {
        headers: {
          "Content-Type": "application/json",
          source: "website",
          Authorization: AuthorizationToken,
        },
      });
      const { data = {}, status = null } = response || {};
      const { result = [] } = data || {};
      if (status >= 200 && status < 300) {
        setViewSnackbarMain({
          message: "Website Services updated successfully!!!",
          type: "success",
        });
        getServicesData();
      }
    } catch (error) {
      const { response } = error || {};
      const { status } = response || {};
      if (status === API_RESPONSE_UNAUTHORIZED_STATUS) {
        handleRefreshToken();
      } else
        setViewSnackbarMain({
          message: "Something went wrong!",
          type: "error",
        });
    }
  };

  const handleServicesChange = (e, value, reason, detail) => {
    setHasServicesChanged(true);
    let copyOfServicesObj = { ...servicesObj };
    if (reason === "select-option") {
      const addedOption = detail.option || {};
      const { code = null } = addedOption;
      if (!copyOfServicesObj[code]) {
        const obj = { [code]: { service: { ...addedOption } } };
        copyOfServicesObj = { ...copyOfServicesObj, ...obj };
      }
      copyOfServicesObj[code]["isActive"] = true;
      setServicesObj(copyOfServicesObj);
    } else if (reason === "remove-option") {
      const removedOption = detail.option || {};
      const { code = null } = removedOption;
      copyOfServicesObj[code]["isActive"] = false;
      setServicesObj(copyOfServicesObj);
    }
    setSelectedServices(value);
  };

  useEffect(() => {
    setHeaderHeading("Services");
  }, []);

  useEffect(() => {
    if (enterpriseCode) getServicesData();
  }, [enterpriseCode]);

  if (isLoading)
    return (
      <Box style={{ width: "100%", textAlign: "center" }}>
        <CircularProgress />
      </Box>
    );
  else
    return (
      <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "32px",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" style={{ fontSize: "20px" }}>
            Services
          </Typography>
          <Button
            variant="contained"
            color="primary"
            style={{
              textTransform: "none",
              padding: "4px 16px",
              fontSize: "16px",
              width: "120px",
            }}
            disabled={!hasServicesChanged}
            onClick={updateServices}
          >
            Update
          </Button>
        </Box>
        <Autocomplete
          multiple
          id="tags-standard"
          options={services}
          getOptionLabel={(option) => option?.name}
          value={selectedServices}
          style={{ flex: 1 }}
          onChange={handleServicesChange}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Medical Services"
              placeholder="Select medical services"
              helperText="You can select multiple services"
            />
          )}
        />
        <Box
          className={classes.servicesGridLayout}
        >
          {Object.values(servicesObj).map((service, index) => {
            const { service: websiteService = {}, isActive = true } =
              service || {};
            const {
              name = "",
              shortDescription = "",
              code = null,
              imageUrl = "",
            } = websiteService || {};
            if (!isActive) return <></>;
            return (
              <Box
                key={code || index}
                className={classes.designBox}
                style={{ borderRadius: "16px", gap: "8px" }}
              >
                <Box
                  style={{
                    //   padding: "12px",
                    display: "flex",
                    gap: "24px",
                    alignItems: "center",
                    height: "100%",
                    // boxShadow: `0 2px 40px ${alpha(THEME_PRIMARY_COLOR, .15)}`,
                  }}
                >
                  <Box
                    style={{
                      height: "80px",
                      width: "80px",
                      backgroundColor: GRAY_THREE,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "8px",
                      padding: "8px",
                    }}
                  >
                    {imageUrl && (
                      <img
                        src={getThumborUrl(imageUrl, 0, 0)}
                        alt="service-name"
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "cover",
                          objectPosition: "center",
                        }}
                      />
                    )}
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                      flex: 1,
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      style={{ fontSize: "18px" }}
                    >
                      {name || ""}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      className={classes.serviceItemSubHeading}
                      style={{ color: GRAY_ONE, fontSize: "15px" }}
                    >
                      {shortDescription || ""}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            );
          })}
        </Box>
        {/* {isLoading ? (
        <Box style={{ textAlign: "center" }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: "24px",
          }}
        >
          {services.map((service, index) => {
            const { service: websiteService = {}, isActive = true } = service || {};
            const {
              name = "",
              shortDescription = "",
              code = null
            } = websiteService || {};
            if (!isActive) return <></>
            return (
              <Box
                key={code || index}
                className={classes.designBox}
                style={{ borderRadius: "16px", gap: "8px", paddingTop: "8px" }}
              >
                <Typography
                  align="right"
                  variant="subtitle2"
                  style={{ color: FAILURE_TEXT_COLOR, cursor: "pointer" }}
                >
                  Remove
                </Typography>
                <Box
                  style={{
                    //   padding: "12px",
                    display: "flex",
                    gap: "24px",
                    alignItems: "center",
                    height: "100%",
                    // boxShadow: `0 2px 40px ${alpha(THEME_PRIMARY_COLOR, .15)}`,
                  }}
                >
                  <Box
                    style={{
                      minHeight: "5rem",
                      minWidth: "5rem",
                      backgroundColor: GRAY_FOUR,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "8px",
                      cursor: "pointer",
                    }}
                    component="label"
                  >
                    <LocalHospitalIcon
                      style={{ fontSize: "3rem", color: GRAY_TWO }}
                    />
                    <input type="file" hidden />
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "24px",
                      flex: 1,
                    }}
                  >
                    <TextField
                      name="name"
                      placeholder="Enter service name"
                      style={{ width: "100%" }}
                      value={name || ""}
                      onChange={(e) => handleInputChange(e, index)}
                    />
                    <TextField
                      //   variant="outlined"
                      name="shortDescription"
                      placeholder="Enter service description"
                      multiline
                      maxRows={3}
                      style={{ width: "100%", borderBottom: "none" }}
                      value={shortDescription || ""}
                      onChange={(e) => handleInputChange(e, index)}
                    />
                  </Box>
                </Box>
              </Box>
            );
          })}
          <Box
            style={{
              borderRadius: "16px",
              border: `1px dashed ${GRAY_ONE}`,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              minHeight: "136px",
            }}
            onClick={addNewService}
          >
            <Typography
              variant="subtitle1"
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "1.5rem",
                color: GRAY_ONE,
              }}
            >
              <AddIcon style={{ marginRight: "8px", fontSize: "1.5rem" }} /> Add
              Service
            </Typography>
          </Box>
        </Box>
      )} */}
        {/* <Box style={{ textAlign: "center" }}>
        <Button
          variant="contained"
          color="primary"
          style={{
            textTransform: "none",
            padding: "4px 16px",
            fontSize: "14px",
          }}
            onClick={updateServices}
        >
          Update Services
        </Button>
      </Box> */}
      </div>
    );
};

export default Services;
