import React, { useContext, useEffect, useState } from "react";
import axios from "axios";

import {
  AppContext,
  ControlPanelContext,
  devConsoleLog,
  isEmptyObject,
} from "../../utility";
import {
  API_ENDPOINT_DOCTORS,
  API_ENDPOINT_ENTERPRISE,
  API_RESPONSE_UNAUTHORIZED_STATUS,
  API_SECTION_API,
  API_VERSION,
  DOCFYN_API_DOMAIN,
  API_SECTION_APPOINTMENT,
  API_ENDPOINT_SLOTS,
} from "../../constants";
import DoctorsList from "./DoctorsList";
import AppointmentCalendar from "./AppointmentCalendar";
import useStyles from "./styles";
import WalkInAppointmentDialog from "./WalkInAppointmentDialog";
import dayjs from "dayjs";

const Appointments = () => {
  const classes = useStyles();
  const { setHeaderHeading, enterprise, AuthorizationToken } =
    useContext(ControlPanelContext);
  const { setViewSnackbarMain, handleRefreshToken, mobileView } =
    useContext(AppContext);
  const [doctorsList, setDoctorsList] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState({});
  const [isAppointmentDialogOpen, setIsAppointmentDialogOpen] = useState(false);
  const [dateTimeClicked, setDateTimeClicked] = useState(null);
  const [type, setType] = useState(null);
  const [appointments, setAppointments] = useState([]);
  const [slots, setSlots] = useState([]);
  const [isAppointmentsLoading, setIsAppointmentsLoading] = useState(true);
  const { enterprise: currentEnterprise = {} } = enterprise || {};
  const { code: enterpriseCode = null } = currentEnterprise || {};
  const { doctorDetails = {} } = selectedDoctor || {};
  const { code: doctorCode = null, name: doctorName = "" } =
    doctorDetails || {};

  const getDoctors = async () => {
    if (!enterpriseCode) return;
    // setIsLoading(true);
    const url = `${DOCFYN_API_DOMAIN}${API_SECTION_API}${API_VERSION}/${API_ENDPOINT_ENTERPRISE}${API_ENDPOINT_DOCTORS}?code=${enterpriseCode}`;
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          source: "website",
          Authorization: AuthorizationToken,
        },
      });
      const { status = null, data = {} } = response || {};
      const { result = {} } = data || {};
      const { doctors = [] } = result || {};
      if (status >= 200 && status < 300) {
        setDoctorsList(doctors);
      }
    } catch (error) {
      const { response } = error || {};
      const { status } = response || {};
      if (status === API_RESPONSE_UNAUTHORIZED_STATUS) {
        handleRefreshToken();
      } else
        setViewSnackbarMain({
          message: "Something went wrong!",
          type: "error",
        });
    } finally {
      //   setIsLoading(false);
    }
  };

  const getAppointments = async () => {
    if (!enterpriseCode || !doctorCode) return;
    setIsAppointmentsLoading(true);
    const url = `${DOCFYN_API_DOMAIN}${API_SECTION_API}${API_VERSION}/${API_ENDPOINT_ENTERPRISE}${enterpriseCode}/${API_SECTION_APPOINTMENT}?code=${doctorCode}`;
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          source: "website",
          Authorization: AuthorizationToken,
        },
      });
      const { status = null, data = {} } = response || {};
      const { result = [] } = data || {};
      if (status >= 200 && status < 300) {
        setAppointments(result);
      }
    } catch (error) {
      const { response } = error || {};
      const { status } = response || {};
      if (status === API_RESPONSE_UNAUTHORIZED_STATUS) {
        handleRefreshToken();
      } else
        setViewSnackbarMain({
          message: "Something went wrong!",
          type: "error",
        });
    } finally {
      setIsAppointmentsLoading(false);
    }
  };

  const getSlots = async () => {
    if (!enterpriseCode || !doctorCode) return;
    // setIsLoading(true);
    const url = `${DOCFYN_API_DOMAIN}${API_SECTION_API}${API_VERSION}/${API_ENDPOINT_ENTERPRISE}${enterpriseCode}/${API_SECTION_APPOINTMENT}${API_ENDPOINT_SLOTS}?code=${doctorCode}`;
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          source: "website",
          Authorization: AuthorizationToken,
        },
      });
      const { status = null, data = {} } = response || {};
      const { result = {} } = data || {};
      if (status >= 200 && status < 300) {
        const { slots = [] } = result || {};
        setSlots(slots);
      }
    } catch (error) {
      const { response } = error || {};
      const { status } = response || {};
      if (status === API_RESPONSE_UNAUTHORIZED_STATUS) {
        handleRefreshToken();
      } else
        setViewSnackbarMain({
          message: "Something went wrong!",
          type: "error",
        });
    } finally {
      //   setIsLoading(false);
    }
  };

  const handleDateTimeClick = (value) => {
    const dateClicked = new Date(dayjs(value).format("YYYY-MM-DD"));
    const currentDate = new Date();
    dateClicked.setHours(0, 0, 0, 0);
    currentDate.setHours(0, 0, 0, 0);
    if (dateClicked < currentDate) return;

    setDateTimeClicked(dayjs(value).format("YYYY-MM-DD"));
    setType(1);
    setIsAppointmentDialogOpen(true);
  };

  useEffect(() => {
    setHeaderHeading("Appointments");
  }, []);

  useEffect(() => {
    if (enterpriseCode) getDoctors();
  }, [enterpriseCode]);

  useEffect(() => {
    if (doctorsList.length > 0) setSelectedDoctor(doctorsList[0]);
  }, [doctorsList]);

  useEffect(() => {
    if (!isEmptyObject(selectedDoctor)) {
      getAppointments();
      getSlots();
    }
  }, [selectedDoctor]);

  return (
    <div style={{ height: "100%" }}>
      <div className={classes.appointmentGridLayout}>
        <DoctorsList
          doctorsList={doctorsList}
          selectedDoctor={selectedDoctor}
          selectedDoctorCode={doctorCode}
          setSelectedDoctor={setSelectedDoctor}
          setIsAppointmentDialogOpen={setIsAppointmentDialogOpen}
          setType={setType}
          isAppointmentsLoading={isAppointmentsLoading}
          areAppointments={Boolean(appointments.length)}
        />
        <AppointmentCalendar
          handleDateTimeClick={handleDateTimeClick}
          appointments={appointments}
          isAppointmentsLoading={isAppointmentsLoading}
          isDoctorSelected={Boolean(doctorCode)}
          selectedDoctor={selectedDoctor}
          doctorCode={doctorCode}
          slots={slots}
        />
        {isAppointmentDialogOpen && (
          <WalkInAppointmentDialog
            doctorsList={doctorsList}
            isOpen={isAppointmentDialogOpen}
            setIsAppointmentDialogOpen={setIsAppointmentDialogOpen}
            selectedDoctorCode={doctorCode}
            doctorName={doctorName}
            dateTimeClicked={dateTimeClicked}
            type={type}
            slots={slots}
            getAppointments={getAppointments}
            getSlots={getSlots}
          />
        )}
      </div>
    </div>
  );
};

export default Appointments;
