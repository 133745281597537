import { isEmptyObject } from "./utility";


const Auth = {
  isAuthenticated: function () {
    let isAuthenticated = false;

    if (typeof window !== "undefined") {
      const user = JSON.parse(window.localStorage.getItem("user"));
      // console.log(user.data)
      // alert("hgfhfh")
      if (user && !isEmptyObject(user.auth_tokens)) {
        isAuthenticated = true;
        return isAuthenticated;
      }
    }
    return isAuthenticated;
  },
};
export default Auth;
