import React, { useContext, useState } from "react";

import {
  Box,
  Button,
  Divider,
  Drawer,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LaunchIcon from "@material-ui/icons/Launch";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import PersonIcon from "@material-ui/icons/Person";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import {
  GRAY_FIVE,
  GRAY_FOUR,
  GRAY_THREE,
  GRAY_TWO,
  THEME_PRIMARY_COLOR,
  THEME_WEBSITE_BG_COLOR,
} from "../../constants";
import {
  AppContext,
  ControlPanelContext,
  devConsoleLog,
  getClinicDomainName,
  isEmptyObject,
} from "../../utility";
import useStyles from "./styles";
import CreateEnterpriseDialog from "./CreateEnterpriseDialog";
import LocalStorage from "../../localStorage";
import { DrawerList } from "../ControlPanelLayout";
import { useNavigate } from "react-router-dom";

const ChangeEnterprise = ({ enterprises = [] }) => {
  const classes = useStyles();
  const { mobileView } = useContext(AppContext);
  const { enterprise, isEnterprise } = useContext(ControlPanelContext);
  const navigate = useNavigate();
  const [enterpriseMenu, setEnterpriseMenu] = useState(null);
  const [selectedEnterprise, setSelectedEnterprise] = useState({});
  const [isCreateEnterpriseOpen, setIsCreateEnterpriseOpen] = useState(false);
  const { enterprise: currentEnterprise = {} } = enterprise || {};
  const { name: enterpriseName = "" } = currentEnterprise || {};
  const { enterprise: defaultEnterprise = {} } = selectedEnterprise || {};
  const { code: selectedEnterpriseCode = null } = defaultEnterprise || {};
  const [anchorEl, setAnchorEl] = useState(false);

  const handleMenuClick = (event) => {
    setEnterpriseMenu(event.currentTarget);
  };

  const handleClose = () => {
    setEnterpriseMenu(null);
  };

  const handleEnterpriseSelection = (selectedValue) => {
    if (isEmptyObject(selectedValue)) return;
    const loggedInUser = LocalStorage.getUser() || {};
    const flag = LocalStorage.setUser({
      ...loggedInUser,
      enterprise: selectedValue,
    });
    if (flag) {
      window.location.href = "/home";
    }
  };

  const handleCreateEnterprise = () => {
    handleClose();
    setIsCreateEnterpriseOpen(true);
  };

  const handleVisitClinic = () => {
    const domain = getClinicDomainName();
    devConsoleLog("domain", domain);
    window.open(domain, "_blank");
  };

  const handleMenuClose = () => {
    setAnchorEl(false);
  };

  const handleRedirection = (link) => {
    setAnchorEl(false);
    navigate(link);
  }

  const handleLogout = () => {
    const isDeleted = LocalStorage.deleteUser();
    if (isDeleted) window.location.href = "/login";
  };

  useState(() => {
    setSelectedEnterprise(enterprise);
  }, [enterprise]);

  if (mobileView)
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "16px",
          width: "100%",
        }}
      >
        {mobileView && (
          <React.Fragment>
            <MenuOpenIcon
              className={classes.menuOpenIcon}
              onClick={() => setAnchorEl((prev) => !prev)}
            />
            <Drawer
              className={classes.drawer}
              classes={{
                paper: classes.drawerPaper,
              }}
              anchor="left"
              open={anchorEl}
              onClose={handleMenuClose}
            >
              <Box
                style={{
                  padding: "12px 16px",
                  display: "flex",
                  alignItems: "center",
                  gap: "16px",
                }}
                onClick={() => handleRedirection("/my-profile")}
              >
                <Box
                  style={{
                    height: "36px",
                    width: "36px",
                    borderRadius: "50px",
                    backgroundColor: "rgba(0, 0, 0, .1)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <PersonIcon />
                </Box>
                My Profile
              </Box>
              <Divider />
              <DrawerList anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
              <Box style={{marginTop: 'auto'}}>
                <Divider />
                <Box
                  style={{
                    padding: "8px 16px",
                    display: "flex",
                    alignItems: "center",
                    gap: "16px",
                  }}
                  onClick={handleLogout}
                >
                  <Box
                    style={{
                      height: "36px",
                      width: "36px",
                      borderRadius: "50px",
                      backgroundColor: "rgba(0, 0, 0, .1)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <ExitToAppIcon fontSize="small" />
                  </Box>
                  Sign out
                </Box>
              </Box>
            </Drawer>
          </React.Fragment>
        )}
        <Box style={{ display: "flex", alignItems: "center", gap: "12px" }}>
          <Box
            style={{
              //   padding: "8px",
              backgroundColor: THEME_WEBSITE_BG_COLOR,
              borderRadius: "4px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => navigate('/home')}
          >
            <img alt="clinicImg" src="/e-clinic-primary.png" />
          </Box>
          {isEnterprise && (
            <Box>
              <Box
                onClick={handleMenuClick}
                className={classes.changeEnterpriseHeader}
              >
                <Typography
                  variant="h6"
                  style={{
                    fontSize: "1.2rem",
                    fontWeight: "400",
                    color: THEME_PRIMARY_COLOR,
                  }}
                >
                  {enterpriseName || ""}
                </Typography>
                <ExpandMoreIcon style={{ color: THEME_PRIMARY_COLOR }} />
              </Box>
              <Menu
                id="simple-menu"
                anchorEl={enterpriseMenu}
                keepMounted
                open={Boolean(enterpriseMenu)}
                onClose={handleClose}
                style={{ padding: "8px" }}
                classes={{
                  paper: classes.enterpriseMenu,
                  list: classes.enterpriseMenuList,
                }}
              >
                {enterprises.length > 1 && (
                  <>
                    <Typography
                      variant="subtitle1"
                      style={{
                        color: GRAY_FIVE,
                        fontSize: "12px",
                        fontWeight: "400",
                        textTransform: "uppercase",
                      }}
                    >
                      Select Clinic
                    </Typography>
                    {enterprises.map((item) => {
                      const { enterprise } = item || {};
                      const { name = "", code = null } = enterprise || {};
                      return (
                        <MenuItem
                          key={code}
                          className={`${classes.enterpriseMenuItem} ${
                            selectedEnterpriseCode === code
                              ? classes.selectedEnterpriseMenuItem
                              : ""
                          }`}
                          onClick={() => handleEnterpriseSelection(item)}
                        >
                          {name || ""}
                        </MenuItem>
                      );
                    })}
                    <Divider />
                  </>
                )}
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{
                      color: GRAY_FIVE,
                      fontSize: "12px",
                      fontWeight: "400",
                      textTransform: "uppercase",
                    }}
                  >
                    Create Clinic
                  </Typography>
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.enterpriseMenuCreateButton}
                    onClick={handleCreateEnterprise}
                  >
                    Create your E-Clinic
                  </Button>
                </Box>
              </Menu>
              {isCreateEnterpriseOpen && (
                <CreateEnterpriseDialog
                  setIsCreateEnterpriseOpen={setIsCreateEnterpriseOpen}
                />
              )}
            </Box>
          )}
        </Box>
        {isEnterprise && (
          <LaunchIcon
            className={classes.launchIcon}
            onClick={handleVisitClinic}
          />
        )}
      </div>
    );
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      {mobileView && <MenuOpenIcon className={classes.menuOpenIcon} />}
      <Box style={{ display: "flex", alignItems: "center", gap: "12px" }}>
        <Box
          style={{
            //   padding: "8px",
            backgroundColor: THEME_WEBSITE_BG_COLOR,
            borderRadius: "4px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img alt="clinicImg" src="/e-clinic.png" />
        </Box>
        {isEnterprise && (
          <Box>
            <Box
              onClick={handleMenuClick}
              className={classes.changeEnterpriseHeader}
            >
              <Typography>{enterpriseName || ""}</Typography>
              <ExpandMoreIcon
                style={{ color: mobileView ? GRAY_FIVE : "inherit" }}
              />
            </Box>
            <Menu
              id="simple-menu"
              anchorEl={enterpriseMenu}
              keepMounted
              open={Boolean(enterpriseMenu)}
              onClose={handleClose}
              style={{ padding: "8px" }}
              classes={{
                paper: classes.enterpriseMenu,
                list: classes.enterpriseMenuList,
              }}
            >
              {enterprises.length > 1 && (
                <>
                  <Typography
                    variant="subtitle1"
                    style={{
                      color: GRAY_FIVE,
                      fontSize: "12px",
                      fontWeight: "400",
                      textTransform: "uppercase",
                    }}
                  >
                    Select Clinic
                  </Typography>
                  {enterprises.map((item) => {
                    const { enterprise } = item || {};
                    const { name = "", code = null } = enterprise || {};
                    return (
                      <MenuItem
                        key={code}
                        className={`${classes.enterpriseMenuItem} ${
                          selectedEnterpriseCode === code
                            ? classes.selectedEnterpriseMenuItem
                            : ""
                        }`}
                        onClick={() => handleEnterpriseSelection(item)}
                      >
                        {name || ""}
                      </MenuItem>
                    );
                  })}
                  <Divider />
                </>
              )}
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "12px",
                }}
              >
                <Typography
                  variant="subtitle1"
                  style={{
                    color: GRAY_FIVE,
                    fontSize: "12px",
                    fontWeight: "400",
                    textTransform: "uppercase",
                  }}
                >
                  Create Clinic
                </Typography>
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.enterpriseMenuCreateButton}
                  onClick={handleCreateEnterprise}
                >
                  Create your E-Clinic
                </Button>
              </Box>
            </Menu>
            {isCreateEnterpriseOpen && (
              <CreateEnterpriseDialog
                setIsCreateEnterpriseOpen={setIsCreateEnterpriseOpen}
              />
            )}
          </Box>
        )}
      </Box>
      {isEnterprise && (
        <LaunchIcon
          className={classes.launchIcon}
          onClick={handleVisitClinic}
        />
      )}
    </div>
  );
};

export default ChangeEnterprise;
