import React, { useContext, useEffect, useState } from "react";
import { AppContext, ControlPanelContext } from "../../utility";
import useStyles from "./styles";
import ReviewsList from "./ReviewsList";
import AddReview from "./AddReview";
import {
  DOCFYN_API_DOMAIN,
  API_SECTION_API,
  API_VERSION,
  API_ENDPOINT_ENTERPRISE,
  API_ENDPOINT_REVIEWS,
  API_RESPONSE_UNAUTHORIZED_STATUS,
} from "../../constants";
import axios from "axios";
import Skeleton from "@material-ui/lab/Skeleton";
import { CircularProgress } from "@material-ui/core";

const reviewCategory = {
  0: false, // All reviews
  1: false, // Published
  2: false, // Unpublished
};

const Reviews = () => {
  const classes = useStyles();
  const { handleRefreshToken, setViewSnackbarMain } = useContext(AppContext);
  const { setHeaderHeading, enterprise, AuthorizationToken } =
    useContext(ControlPanelContext);
  const [reviews, setReviews] = useState([]);
  const [reviewsType, setReviewsType] = useState(reviewCategory);
  const [isLoading, setIsLoading] = useState(true);
  const { enterprise: currentEnterprise = {} } = enterprise || {};
  const { code: enterpriseCode = null } = currentEnterprise || {};

  const getReviews = async () => {
    if (!enterpriseCode) {
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    const url = `${DOCFYN_API_DOMAIN}${API_SECTION_API}${API_VERSION}/${API_ENDPOINT_ENTERPRISE}${enterpriseCode}/${API_ENDPOINT_REVIEWS}`;
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          source: "website",
          Authorization: AuthorizationToken,
        },
      });
      const { status = null, data = {} } = response || {};
      if (status >= 200 && status < 300) {
        const { result = {} } = data || {};
        const { reviews = [] } = result || {};
        setReviewsType({ ...reviewCategory, 0: true });
        setReviews(reviews);
      }
    } catch (error) {
      const { response } = error || {};
      const { status } = response || {};
      if (status === API_RESPONSE_UNAUTHORIZED_STATUS) {
        handleRefreshToken();
      } else
        setViewSnackbarMain({
          message: "Something went wrong!",
          type: "error",
        });
    } finally {
      setIsLoading(false);
    }
  };

  const handleReviewsTypeChange = (type) => {
    setReviewsType({ ...reviewCategory, [type]: true });
  };

  useEffect(() => {
    setHeaderHeading("Patient Reviews and Ratings");
  }, []);

  useEffect(() => {
    getReviews();
  }, [enterpriseCode]);

  return (
    <div className={classes.reviewsLayout}>
      <div className={classes.reviewsActionDiv}>
        <div className={classes.reviewsActionFlexBox}>
          <div
            onClick={() => handleReviewsTypeChange(0)}
            className={
              reviewsType[0]
                ? classes.reviewsActionBtn
                : classes.reviewsActionUnacticeBtn
            }
          >
            All
          </div>
          <div
            onClick={() => handleReviewsTypeChange(1)}
            className={
              reviewsType[1]
                ? classes.reviewsActionBtn
                : classes.reviewsActionUnacticeBtn
            }
          >
            Published
          </div>
          <div
            onClick={() => handleReviewsTypeChange(2)}
            className={
              reviewsType[2]
                ? classes.reviewsActionBtn
                : classes.reviewsActionUnacticeBtn
            }
          >
            Unpublished
          </div>
        </div>
        {!isLoading && <AddReview getReviews={getReviews} />}
      </div>
      {isLoading ? (
        <div style={{ margin: "24px auto" }}>
          <CircularProgress color="primary" />
        </div>
      ) : (
        <ReviewsList
          reviews={reviews}
          reviewsType={reviewsType}
          getReviews={getReviews}
        />
      )}
    </div>
  );
};

export default Reviews;
