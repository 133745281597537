import React, { useContext, useEffect, useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Slide,
  TextField,
  Typography,
} from "@material-ui/core";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import LocalHospitalOutlinedIcon from "@material-ui/icons/LocalHospitalOutlined";
import PhoneIcon from "@material-ui/icons/Phone";

import {
  API_RESPONSE_UNAUTHORIZED_STATUS,
  COUNTRIES,
  GRAY_FIVE,
  countryToFlag,
  DOCFYN_API_DOMAIN,
  API_SECTION_API,
  API_VERSION,
  API_ENDPOINT_ENTERPRISE,
  API_SECTION_APPOINTMENT,
  API_ENDPOINT_BOOK,
  THEME_PRIMARY_COLOR,
  GRAY_FOUR,
  GRAY_ONE,
  SUCCESS_TEXT_COLOR,
} from "../../constants";
import useStyles from "./styles";
import {
  AppContext,
  ControlPanelContext,
  devConsoleLog,
  getInitials,
  isEmptyObject,
} from "../../utility";
import dayjs from "dayjs";
import axios from "axios";
import SlotsPanel from "./SlotsPanel";
import { format, parse } from "date-fns";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const InputBox = ({ children, label }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
      {label && (
        <Typography
          variant="subtitle2"
          style={{ color: GRAY_FIVE, fontSize: "12px" }}
        >
          {label}
        </Typography>
      )}
      {children}
    </div>
  );
};

const WalkInAppointmentDialog = ({
  doctorsList = [],
  isOpen = false,
  setIsAppointmentDialogOpen,
  selectedDoctorCode = null,
  doctorName = "",
  dateTimeClicked,
  type = null,
  slots = [],
  getAppointments,
  getSlots
}) => {
  const classes = useStyles();
  const { handleRefreshToken, setViewSnackbarMain } = useContext(AppContext);
  const { enterprise = {}, AuthorizationToken } =
    useContext(ControlPanelContext);
  const [open, setOpen] = useState(false);
  const [input, setInput] = useState({
    dialCode: "+91",
    slotDate: type === 1 ? null : dayjs(),
    slotTime: type === 1 ? null : dayjs(),
    name: null,
    phone: null,
  });
  const [bookingCode, setBookingCode] = useState(null);
  const [errors, setErrors] = useState({});
  const { enterprise: currentEnterprise = {} } = enterprise || {};
  const { code: enterpriseCode = null } = currentEnterprise || {};

  const handleClose = () => {
    setOpen(false);
    setIsAppointmentDialogOpen(false);
  };

  const handleConfirmationClose = () => {
    handleClose();
    getSlots();
    getAppointments();
  };

  const handleInputChange = (e) => {
    if (errors[e.target.name]) {
      const newErrors = { ...errors };
      delete newErrors[e.target.name];
      setErrors(newErrors);
    }
    setInput((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleDateTimeChange = (value, name) => {
    setInput((prev) => ({ ...prev, [name]: value }));
  };

  const handleErrors = () => {
    const error = {};
    for (let i in input) {
      if (!input[i]) error[i] = "Field cannot be empty";
    }
    if (!input["slotTime"]) error["slotTime"] = "Please select a slot";
    devConsoleLog("handleErrors", error);
    setErrors(error);
    return !isEmptyObject(error);
  };

  const handleReqBody = () => {
    let reqBody = { ...input };
    reqBody["slotTime"] =
      type === 1 ? reqBody["slotTime"] : reqBody["slotTime"].format("HH:mm");
    reqBody["slotDate"] =
      type === 1
        ? reqBody["slotDate"]
        : reqBody["slotDate"].format("YYYY-MM-DD");
    reqBody["type"] = type;
    reqBody["phone"] = reqBody["dialCode"] + reqBody["phone"];
    delete reqBody["dialCode"];
    return reqBody;
  };

  const handleWalkInAppointment = async () => {
    if (!enterpriseCode || !selectedDoctorCode) return;
    // setIsLoading(true);
    const isError = handleErrors();
    if (isError) return;
    const reqBody = handleReqBody();
    const url = `${DOCFYN_API_DOMAIN}${API_SECTION_API}${API_VERSION}/${API_ENDPOINT_ENTERPRISE}${enterpriseCode}/${API_SECTION_APPOINTMENT}${API_ENDPOINT_BOOK}?code=${selectedDoctorCode}`;
    try {
      const response = await axios.post(url, reqBody, {
        headers: {
          "Content-Type": "application/json",
          source: "website",
          Authorization: AuthorizationToken,
        },
      });
      const { status = null, data = {} } = response || {};
      const { result = {} } = data || {};
      const { bookingCode = null } = result || {};
      if (status >= 200 && status < 300) {
        setViewSnackbarMain({
          message: "Appointment created successfully!",
          type: "success",
        });
        setBookingCode(bookingCode);
      }
    } catch (error) {
      const { response } = error || {};
      const { status } = response || {};
      if (status === API_RESPONSE_UNAUTHORIZED_STATUS) {
        handleRefreshToken();
      } else
        setViewSnackbarMain({
          message: "Something went wrong!",
          type: "error",
        });
    } finally {
      //   setIsLoading(false);
    }
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      //   keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      classes={{ paper: classes.appointmentDialog }}
    >
      {bookingCode ? (
        <>
          <DialogContent
            style={{ display: "flex", flexDirection: "column", gap: "24px" }}
          >
            <div style={{ display: "flex", gap: "24px" }}>
              <div
                style={{
                  height: "64px",
                  width: "64px",
                  borderRadius: "50%",
                  border: `1px solid ${GRAY_FOUR}`,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "20px",
                  color: THEME_PRIMARY_COLOR,
                  alignSelf: "start",
                }}
              >
                {getInitials(input["name"] || "")}
              </div>
              <div>
                <Typography
                  variant="subtitle1"
                  style={{ fontWeight: "600", fontSize: "18px" }}
                >
                  {input["name"] || ""}
                </Typography>
                <Typography
                  variant="subtitle1"
                  style={{
                    color: GRAY_ONE,
                    fontSize: "14px",
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <PhoneIcon style={{ fontSize: "14px" }} />{" "}
                  {input["phone"] || ""}
                </Typography>
              </div>
            </div>
            <Divider />
            <div
              style={{ display: "flex", gap: "12px", flexDirection: "column" }}
            >
              <Typography
                variant="h6"
                align="center"
                style={{ color: SUCCESS_TEXT_COLOR, fontWeight: "600" }}
              >
                Booking Confirmed
              </Typography>
              <div style={{ display: "flex", gap: "12px" }}>
                <div
                  style={{
                    padding: "6px",
                    borderRadius: "50%",
                    backgroundColor: THEME_PRIMARY_COLOR,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    alignSelf: "start",
                  }}
                >
                  <LocalHospitalOutlinedIcon
                    style={{ color: "#fff" }}
                    // fontSize="small"
                  />
                </div>
                <Typography
                  variant="body1"
                  style={{ flex: 1, fontSize: "16px" }}
                >
                  <span className={classes.appointmentDetailsText}>
                    In-Clinic Appointment
                  </span>{" "}
                  with{" "}
                  <span className={classes.appointmentDetailsText}>
                    {doctorName || ""}
                  </span>{" "}
                  at{" "}
                  <span className={classes.appointmentDetailsText}>
                    {type === 1
                      ? format(
                          parse(input["slotTime"], "HH:mm", new Date()),
                          "h:mm a"
                        )
                      : dayjs(input["slotTime"]).format("h:mm A")}
                  </span>{" "}
                  on {dayjs(input["slotDate"]).format("D, MMM YYYY")}
                </Typography>
              </div>
            </div>
            {/* <div style={{display: "flex", flexDirection: "column"}}>
              <Typography variant="subtitle1" style={{fontWeight: '600'}}>Planned Procedures</Typography>
              <Typography variant="body1" style={{fontSize: "14px"}}>Consultation</Typography>
            </div>
            <div style={{display: "flex", flexDirection: "column"}}>
              <Typography variant="subtitle1" style={{fontWeight: '600'}}>Notes</Typography>
              <Typography variant="body1" style={{fontSize: "14px"}}>Testing</Typography>
            </div> */}
          </DialogContent>
          <DialogActions style={{ margin: "16px 0" }}>
            <Button
              variant="contained"
              color="primary"
              style={{ textTransform: "none", fontSize: "14px" }}
              onClick={handleConfirmationClose}
            >
              Close
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogTitle>
            {type === 1 ? "Add Appointment" : "Walk in Appointment"}
          </DialogTitle>
          <DialogContent>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "32px" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "24px",
                }}
              >
                <div className={classes.phoneNumberBox}>
                  <InputBox label="Dial Code">
                    <TextField
                      id="select"
                      name="dialCode"
                      // label="Dial Code"
                      value={input["dialCode"]}
                      onChange={handleInputChange}
                      select
                      variant="outlined"
                      placeholder="Select Doctor"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{ style: { fontSize: "14px" } }}
                      required
                      size="small"
                      error={errors["dialCode"]}
                      helperText={errors["dialCode"]}
                    >
                      {COUNTRIES.map((country) => {
                        const {
                          label = "",
                          dial_code: dialCode = "",
                          code = "IN",
                        } = country || {};
                        return (
                          <MenuItem value={dialCode}>{`${countryToFlag(
                            code
                          )} ${label}`}</MenuItem>
                        );
                      })}
                    </TextField>
                  </InputBox>
                  <InputBox label="Mobile Number">
                    <TextField
                      variant="outlined"
                      name="phone"
                      // label="Mobile Number"
                      value={input["phone"]}
                      onChange={handleInputChange}
                      size="small"
                      placeholder="Enter Mobile Number"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{ style: { fontSize: "14px" } }}
                      required
                      error={errors["phone"]}
                      helperText={errors["phone"]}
                    />
                  </InputBox>
                </div>
                <div className={classes.inputBoxGrid}>
                  <InputBox label="Patient Name">
                    <TextField
                      variant="outlined"
                      name="name"
                      // label="Patient Name"
                      value={input["name"]}
                      onChange={handleInputChange}
                      size="small"
                      placeholder="Enter Patient Name"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{ style: { fontSize: "14px" } }}
                      required
                      error={errors["name"]}
                      helperText={errors["name"]}
                    />
                  </InputBox>
                  <InputBox label="Doctor">
                    <TextField
                      id="select"
                      // label="Doctor"
                      value={selectedDoctorCode}
                      select
                      variant="outlined"
                      placeholder="Select Doctor"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{ style: { fontSize: "14px" } }}
                      required
                      size="small"
                      disabled
                      SelectProps={{
                        classes: { disabled: classes.disabledTextField },
                      }}
                    >
                      {doctorsList.map((row) => {
                        const { doctorDetails = {} } = row || {};
                        const { name: doctorName = "", code = null } =
                          doctorDetails || {};
                        return (
                          <MenuItem value={code}>{doctorName || ""}</MenuItem>
                        );
                      })}
                    </TextField>
                  </InputBox>
                </div>
                {type === 1 && (
                  <div className={classes.inputBoxGrid}>
                    <InputBox label={`Email (Optional)`}>
                      <TextField
                        variant="outlined"
                        name="email"
                        // label="Mobile Number"
                        value={input["email"]}
                        onChange={handleInputChange}
                        size="small"
                        placeholder="Enter Email Address"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ style: { fontSize: "14px" } }}
                        // error={errors["email"]}
                        // helperText={errors["email"]}
                      />
                    </InputBox>
                  </div>
                )}
                {type === 2 ? (
                  <div style={{ gridColumn: "span 2" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["TimePicker", "DatePicker"]}>
                        <div className={classes.dateTimePickerGrid}>
                          <InputBox label="Consultation Date">
                            <DatePicker
                              // label="Date"
                              format="DD/MM/YYYY"
                              disabled
                              value={dayjs(input["slotDate"], "DD/MM/YYYY")}
                              sx={{
                                input: {
                                  "&.Mui-disabled": {
                                    WebkitTextFillColor: "#000",
                                    opacity: 1,
                                  },
                                },
                              }}
                              slotProps={{
                                textField: {
                                  size: "small",
                                  sx: { flex: 1, color: "#000" },
                                },
                                input: {
                                  sx: {
                                    "&.Mui-disabled": {
                                      WebkitTextFillColor: "#000",
                                      opacity: 1,
                                    },
                                  },
                                },
                              }}
                              onChange={(value) =>
                                handleDateTimeChange(value, "slotDate")
                              }
                            />
                          </InputBox>
                          <InputBox label="Consultation Time">
                            <TimePicker
                              // label="Consultation time"
                              value={dayjs(input["slotTime"], "HH:mm")}
                              disabled
                              slotProps={{
                                textField: {
                                  size: "small",
                                  sx: { flex: 1 },
                                },
                              }}
                              timeSteps={{ minutes: 1 }}
                              onChange={(value) =>
                                handleDateTimeChange(value, "slotTime")
                              }
                            />
                          </InputBox>
                        </div>
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                ) : (
                  <>
                    <Divider />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "16px",
                      }}
                    >
                      <Typography variant="h6" style={{}}>
                        Select Slot
                      </Typography>
                      <FormHelperText error={errors["slotTime"]}>
                        {errors["slotTime"]}
                      </FormHelperText>
                      <SlotsPanel
                        slots={slots}
                        input={input}
                        setInput={setInput}
                        dateTimeClicked={dateTimeClicked}
                      />
                    </div>
                  </>
                )}
              </div>
              {/* <Divider /> */}
              {/* <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                //   gridTemplateRows: "auto auto 1fr 1fr",
                gap: "24px",
              }}
            >
              <TextField
                variant="outlined"
                label="Planned Procedures"
                size="small"
                placeholder="Add Procedure"
                InputLabelProps={{ shrink: true }}
                InputProps={{ style: { fontSize: "14px" } }}
                multiline
                minRows={3}
                maxRows={5}
              />
              <TextField
                variant="outlined"
                label="Notes"
                size="small"
                placeholder="Add Notes"
                InputLabelProps={{ shrink: true }}
                InputProps={{ style: { fontSize: "14px" } }}
                multiline
                minRows={3}
                maxRows={5}
              />
            </div> */}
            </div>
          </DialogContent>
          <DialogActions style={{ marginTop: "16px" }}>
            <Button
              variant="outlined"
              style={{ textTransform: "none", fontSize: "14px" }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ textTransform: "none", fontSize: "14px" }}
              onClick={handleWalkInAppointment}
            >
              Save
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default WalkInAppointmentDialog;
