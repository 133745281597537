import { makeStyles } from "@material-ui/core/styles";
import {
  GRAY_ONE,
  THEME_PRIMARY_COLOR,
  THEME_WEBSITE_BG_COLOR,
  THEME_WEBSITE_SECONDARY_BG_COLOR,
} from "../constants";

const useStyles = makeStyles((theme) => ({
  appLayout: {
    minHeight: "calc(100vh - 75px)",
    // padding: "40px 200px",
    position: "relative",
    backgroundColor: THEME_WEBSITE_SECONDARY_BG_COLOR,
  },
  noNavbarAppLayout: {
    minHeight: "100vh",
    overflow: "hidden !important",
  },
  navbar: {
    padding: "8px 200px",
    // backgroundColor: THEME_SECONDARY_COLOR,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: `1px solid #F0F0F0`,
    top: 0,
    position: "sticky",
    zIndex: 10,
    overflow: "hidden",
    backgroundColor: THEME_WEBSITE_BG_COLOR,
  },
  mWebNavbar: {
    padding: "8px 40px",
    [theme.breakpoints.down("sm")]: {
      padding: "8px 16px",
    },
  },
  logo: {
    width: "130px",
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      width: "100px",
    },
  },
  navList: {
    display: "flex",
    listStyle: "none",
    fontSize: "1rem",
    color: "#040D12",
    fontWeight: "400",
    alignItems: "center",
  },
  navbarLoginMenu: {
    fontSize: "1rem",
    textTransform: "none",
    fontWeight: "400",
    padding: "4px 16px",
    border: `1px solid ${THEME_PRIMARY_COLOR}`,
    color: THEME_PRIMARY_COLOR,
    display: "flex",
    alignItems: "center",
    borderRadius: "4px",
    cursor: "pointer",
  },
  onHoverMenuItem: {
    "&:hover": {
      cursor: "default",
      background: "none !important",
    },
  },
  loginMenuButton: {
    "&:hover": {
      backgroundColor: `${THEME_PRIMARY_COLOR} !important`,
      color: THEME_WEBSITE_BG_COLOR,
    },
  },
  noPadding: {
    padding: 0,
  },
  nested: {
    paddingLeft: "32px !important",
  },
  collapsedListItemText: {
    fontSize: "14px",
  },
  footer: {
    backgroundColor: "#333333",
    padding: "32px 200px",
    color: THEME_WEBSITE_BG_COLOR,
    [theme.breakpoints.down("lg")]: {
      padding: "32px 40px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "16px",
    },
  },
}));

export default useStyles;
