import React, { useContext, useEffect, useState } from "react";

import {
  API_SECTION_API,
  API_SECTION_PUBLIC,
  API_SECTION_WEBSITE,
  API_VERSION,
  HARBOR_API_DOMAIN,
} from "../../../constants";
import {
  AppContext,
  ControlPanelContext,
  devConsoleLog,
} from "../../../utility";
import useStyles from "./styles";
import Favicon from "./Favicon";
import Banners from "./Banners";
import VerticalTabs from "../../Commons/VerticalTabs";
import axios from "axios";
import HorizontalTabs from "../../Commons/HorizontalTabs";

const tabs = [
  {
    name: "Header & Favicon",
    Component: Favicon,
  },
  {
    name: "Banners",
    Component: Banners,
  },
];

const Design = () => {
  const classes = useStyles();
  const { mobileView } = useContext(AppContext);
  const {
    setHeaderHeading,
    AuthorizationToken,
    enterprise = {},
  } = useContext(ControlPanelContext);
  const { enterprise: selectedEnterprise = {} } = enterprise || {};
  const { domainName = null } = selectedEnterprise || {};
  const [currentTab, setCurrentTab] = useState(0);
  const TabComponent = tabs[currentTab].Component;

  useEffect(() => {
    setHeaderHeading("Design");
  }, []);

  return (
    <div className={classes.designLayoutBox}>
      {mobileView ? (
        <HorizontalTabs
          tabs={tabs}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
        />
      ) : (
        <VerticalTabs
          tabs={tabs}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
        />
      )}
      <TabComponent setCurrentTab={setCurrentTab} />
    </div>
  );
};

export default Design;
