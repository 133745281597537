import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Slide,
  Typography,
} from "@material-ui/core";
import LocalHospitalOutlinedIcon from "@material-ui/icons/LocalHospitalOutlined";
import PhoneIcon from "@material-ui/icons/Phone";

import {
  GRAY_FOUR,
  THEME_PRIMARY_COLOR,
  GRAY_ONE,
  SUCCESS_TEXT_COLOR,
  BOOKING_STATUS,
  FAILURE_TEXT_COLOR,
} from "../../constants";
import { getInitials } from "../../utility";
import dayjs from "dayjs";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AppointmentConfirmedDialog = ({
  isOpen = false,
  setIsConfirmationDialogOpen,
  bookingDetails = {},
  selectedDoctor = {},
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const { doctorDetails = {} } = selectedDoctor || {};
  const { name: doctorName = "" } = doctorDetails || {};

  const {
    code: bookingCode = null,
    name: patientName = "",
    patient = {},
    appointmentDateTime = "",
    status = null,
  } = bookingDetails || {};
  const { phone: patientPhone = "" } = patient || {};

  const handleClose = () => {
    setOpen(false);
    setIsConfirmationDialogOpen(false);
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      //   keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      classes={{ paper: classes.appointmentDialog }}
    >
      <DialogContent
        style={{ display: "flex", flexDirection: "column", gap: "24px" }}
      >
        <div style={{ display: "flex", gap: "24px" }}>
          <div
            style={{
              height: "64px",
              width: "64px",
              borderRadius: "50%",
              border: `1px solid ${GRAY_FOUR}`,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "20px",
              color: THEME_PRIMARY_COLOR,
              alignSelf: "start",
            }}
          >
            {getInitials(patientName || "")}
          </div>
          <div>
            <Typography
              variant="subtitle1"
              style={{ fontWeight: "600", fontSize: "18px" }}
            >
              {patientName || ""}
            </Typography>
            <Typography
              variant="subtitle1"
              style={{
                color: GRAY_ONE,
                fontSize: "14px",
                display: "flex",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <PhoneIcon style={{ fontSize: "14px" }} />
              {patientPhone || ""}
            </Typography>
          </div>
        </div>
        <Divider />
        <div style={{ display: "flex", gap: "12px", flexDirection: "column" }}>
          <Typography
            variant="h6"
            align="center"
            style={{
              color: status === 2 ? SUCCESS_TEXT_COLOR : FAILURE_TEXT_COLOR,
              fontWeight: "600",
            }}
          >
            Booking {BOOKING_STATUS[status]}
          </Typography>
          <div>
            <Typography
              variant="subtitle1"
              align="center"
              style={{ fontSize: "14px", fontWeight: "300" }}
            >
              Booking Code
            </Typography>
            <Typography
              variant="h5"
              align="center"
              style={{ fontSize: "32px", color: THEME_PRIMARY_COLOR }}
            >
              {bookingCode || ""}
            </Typography>
          </div>
          <div style={{ display: "flex", gap: "12px" }}>
            <div
              style={{
                padding: "6px",
                borderRadius: "50%",
                backgroundColor: THEME_PRIMARY_COLOR,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "start",
              }}
            >
              <LocalHospitalOutlinedIcon
                style={{ color: "#fff" }}
                // fontSize="small"
              />
            </div>
            <Typography variant="body1" style={{ flex: 1, fontSize: "16px" }}>
              <span className={classes.appointmentDetailsText}>
                In-Clinic Appointment
              </span>{" "}
              with{" "}
              <span className={classes.appointmentDetailsText}>
                {doctorName || ""}
              </span>{" "}
              at {dayjs(appointmentDateTime).format("h:mm A") || ""}
              <span className={classes.appointmentDetailsText}></span> on{" "}
              {dayjs(appointmentDateTime).format("D, MMM YYYY") || ""}
            </Typography>
          </div>
        </div>
        {/* <div style={{display: "flex", flexDirection: "column"}}>
              <Typography variant="subtitle1" style={{fontWeight: '600'}}>Planned Procedures</Typography>
              <Typography variant="body1" style={{fontSize: "14px"}}>Consultation</Typography>
            </div>
            <div style={{display: "flex", flexDirection: "column"}}>
              <Typography variant="subtitle1" style={{fontWeight: '600'}}>Notes</Typography>
              <Typography variant="body1" style={{fontSize: "14px"}}>Testing</Typography>
            </div> */}
      </DialogContent>
      <DialogActions style={{ margin: "16px 0" }}>
        <Button
          variant="contained"
          color="primary"
          style={{ textTransform: "none", fontSize: "14px" }}
          onClick={handleClose}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AppointmentConfirmedDialog;
