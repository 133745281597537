import React, { useEffect, useState } from "react";
import { alpha, withStyles } from "@material-ui/core/styles";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

const DAYS = [
  {
    key: "monday",
    label: "Mon",
  },
  {
    key: "tuesday",
    label: "Tue",
  },
  {
    key: "wednesday",
    label: "Wed",
  },
  {
    key: "thursday",
    label: "Thu",
  },
  {
    key: "friday",
    label: "Fri",
  },
  {
    key: "saturday",
    label: "Sat",
  },
  {
    key: "sunday",
    label: "Sun",
  },
];

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    margin: theme.spacing(2),
    padding: theme.spacing(0, 1),
    "&:not(:first-child)": {
      border: "1px solid",
      borderColor: theme.palette.primary.main,
      borderRadius: "50%",
    },
    "&:first-child": {
      border: "1px solid",
      borderColor: theme.palette.primary.main,
      borderRadius: "50%",
    },
  },
}))(ToggleButtonGroup);

const StyledToggle = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    "&$selected": {
      color: "white",
      background: theme.palette.primary.main,
    },
    "&:hover": {
      borderColor: alpha(theme.palette.primary.main, 0.2),
      background: alpha(theme.palette.primary.main, 0.2),
    },
    "&:hover$selected": {
      borderColor: theme.palette.primary.main,
      background: theme.palette.primary.main,
    },
    minWidth: 32,
    maxWidth: 32,
    height: 32,
    textTransform: "unset",
    fontSize: "0.75rem",
  },
  selected: {},
}))(ToggleButton);

const ToggleDays = ({selectedDays = [], index = null, handleDaysChange}) => {
  const [days, setDays] = useState(selectedDays);

  const handleInputChange = (value) => {
    handleDaysChange(index, value);
  }

  useEffect(() => {
    setDays(selectedDays);
  }, [selectedDays]);

  return (
    <>
      <StyledToggleButtonGroup
        size="small"
        arial-label="Days of the week"
        value={days}
        style={{display: "flex", gap: "16px"}}
        onChange={(event, value) => handleInputChange(value)}
      >
        {DAYS.map((day, index) => (
          <StyledToggle key={day.key} value={index + 1} aria-label={day.key} style={{ margin: 0}}>
            {day.label}
          </StyledToggle>
        ))}
      </StyledToggleButtonGroup>
    </>
  );
};

export default ToggleDays;
