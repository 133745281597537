import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { Typography } from "@material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SlotsChangeDialog = ({isOpen, updatePracticeTimings, setOpenConfirmationDialog}) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenConfirmationDialog(false)
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen])

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{"Confirmation"}</DialogTitle>
      <DialogContent>
        {/* <Typography>Please be aware that any appointments scheduled may be subject to
          cancellation or rescheduling due to these changes.</Typography> */}
        <DialogContentText id="alert-dialog-slide-description">
          Please be aware that any appointments scheduled may be subject to
          cancellation or rescheduling due to these changes.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={handleClose}
          color="primary"
          style={{ boxShadow: "none", textTransform: "none" }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={updatePracticeTimings}
          color="primary"
          style={{ boxShadow: "none", textTransform: "none" }}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SlotsChangeDialog;
