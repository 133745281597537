import React, { useContext, useState } from "react";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { THEME_WEBSITE_SECONDARY_BG_COLOR } from "../../constants";
import { FormControlLabel, Switch, Typography } from "@material-ui/core";
import useStyles from "./styles";
import { AppContext } from "../../utility";
import { useNavigate } from "react-router-dom";

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const DoctorsList = ({ doctorsList = [] }) => {
  const classes = useStyles();
  const { mobileView } = useContext(AppContext);
  const navigate = useNavigate();
  const theme = useTheme();
  const [isDoctorActive, setIsDoctorActive] = useState({});

  if (doctorsList.length === 0)
    return (
      <Typography
        align="center"
        variant="h6"
        style={{ width: "100%", fontSize: "1.5rem" }}
      >
        No Active Doctors!
      </Typography>
    );
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead
          style={{ backgroundColor: THEME_WEBSITE_SECONDARY_BG_COLOR }}
        >
          <TableRow>
            <TableCell classes={{ root: classes.tableCell }}>Doctor</TableCell>
            <TableCell classes={{ root: classes.tableCell }}>
              Speciality
            </TableCell>
            <TableCell classes={{ root: classes.tableCell }}>Status</TableCell>
            <TableCell classes={{ root: classes.tableCell }}>Practice Timings</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {doctorsList.map((row) => {
            const { doctorDetails = {}, isActive = false } = row || {};
            const {
              name: doctorName = "",
              medicalSpecialities = [],
              phone = "",
              code = null
            } = doctorDetails || {};
            const { name: medicalSpeciality = "" } = medicalSpecialities
              ? medicalSpecialities[0]
              : {};
            return (
              <TableRow key={doctorName}>
                <TableCell
                  classes={{ root: classes.tableCell }}
                  component="th"
                  scope="row"
                >
                  {doctorName || "-"}
                </TableCell>
                <TableCell classes={{ root: classes.tableCell }}>
                  {medicalSpeciality || "-"}
                </TableCell>
                <TableCell classes={{ root: classes.tableCell }}>
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        checked={isActive}
                        // onChange={handleChange}
                        name="checkedB"
                      />
                    }
                    label={mobileView ? "" : isActive ? "Active" : "Unactive"}
                  />
                </TableCell>
                <TableCell
                  classes={{ root: classes.tableCell }}
                  onClick={() => navigate(`/doctors/edit/${code}/${doctorName}`)}
                >
                  <Typography
                    variant="subtitle1"
                    style={{
                      color: theme.palette.primary.main,
                      cursor: "pointer",
                    }}
                  >
                    Edit
                  </Typography>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DoctorsList;
