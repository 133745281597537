import React, { useContext, useEffect, useState } from "react";
import { AppContext, ControlPanelContext, getThumborUrl } from "../../utility";
import useStyles from "./styles";
import {
  AppBar,
  Button,
  CircularProgress,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import SearchIcon from "@material-ui/icons/Search";
import {
  API_RESPONSE_UNAUTHORIZED_STATUS,
  GRAY_FIVE,
  DOCFYN_MARKETPLACE_DOMAIN,
  API_SECTION_API,
  API_VERSION,
  API_SECTION_PLUGIN,
  API_ENDPOINT_APPS,
  API_ENDPOINT_SUBSCRIBE,
  API_ENDPOINT_UNSUBSCRIBE,
} from "../../constants";
import axios from "axios";
import OutlinedButton from "../Commons/OutlinedButton";
import PrimaryButton from "../Commons/PrimaryButton";

const Marketplace = () => {
  const classes = useStyles();
  const { handleRefreshToken, setViewSnackbarMain, mobileView } =
    useContext(AppContext);
  const { setHeaderHeading, AuthorizationToken, enterprise } =
    useContext(ControlPanelContext);
  const [plugins, setPlugins] = useState([]);
  const [isPluginLoading, setIsPluginLoading] = useState(true);
  const [isPluginInstalling, setIsPluginInstalling] = useState(false);
  const { enterprise: currentEnterprise = {} } = enterprise || {};
  const { code: enterpriseCode = null } = currentEnterprise || {};

  const getPlugins = async () => {
    if (!enterpriseCode) {
      setIsPluginLoading(false);
      return;
    }
    setIsPluginLoading(true);
    const url = `${DOCFYN_MARKETPLACE_DOMAIN}${API_SECTION_API}${API_VERSION}/${API_SECTION_PLUGIN}${API_ENDPOINT_APPS}?enterpriseCode=${enterpriseCode}`;
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          source: "website",
          Authorization: AuthorizationToken,
        },
      });
      const { status = null, data = {} } = response || {};
      if (status >= 200 && status < 300) {
        const { result = [] } = data || {};
        setPlugins(result);
      }
    } catch (error) {
      const { response } = error || {};
      const { status } = response || {};
      if (status === API_RESPONSE_UNAUTHORIZED_STATUS) {
        handleRefreshToken();
      } else
        setViewSnackbarMain({
          message: "Something went wrong!",
          type: "error",
        });
    } finally {
      setIsPluginLoading(false);
    }
  };

  const handleInstallPlugin = async (pluginCode, isInstall = true) => {
    if (!pluginCode) return;
    setIsPluginInstalling(true);
    const url = `${DOCFYN_MARKETPLACE_DOMAIN}${API_SECTION_API}${API_VERSION}/${API_SECTION_PLUGIN}${API_ENDPOINT_APPS}${pluginCode}/${
      isInstall ? API_ENDPOINT_SUBSCRIBE : API_ENDPOINT_UNSUBSCRIBE
    }?enterpriseCode=${enterpriseCode}`;
    try {
      const response = await axios.post(
        url,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            source: "website",
            Authorization: AuthorizationToken,
          },
        }
      );
      const { status = null, data = {} } = response || {};
      if (status >= 200 && status < 300) {
        getPlugins();
      }
    } catch (error) {
      const { response } = error || {};
      const { status } = response || {};
      if (status === API_RESPONSE_UNAUTHORIZED_STATUS) {
        handleRefreshToken();
      } else
        setViewSnackbarMain({
          message: "Something went wrong!",
          type: "error",
        });
    } finally {
      setIsPluginInstalling(false);
    }
  };

  useEffect(() => {
    getPlugins();
  }, [enterpriseCode]);

  useEffect(() => {
    setHeaderHeading("Marketplace");
  }, []);

  return (
    <div className={classes.marketplace}>
      <div className={classes.marketplaceBannerDiv}>
        <div>
          <Typography variant="h4" style={{ fontWeight: "600" }}>
            Elevate your E-Clinic with Docfyn plugins
          </Typography>
          <Typography
            variant="subtitle1"
            style={{ marginTop: "8px", fontWeight: "300" }}
          >
            Revolutionize Your E-Clinic Operations: Unleash the Power of
            Advanced Plugin Features for Enhanced Patient Care and Streamlined
            Workflow Efficiency
          </Typography>
        </div>
        {!mobileView && (
          <img
            alt="plugins"
            src="/plugins.png"
            className={classes.marketplaceImg}
          />
        )}
      </div>
      <div className={classes.marketplacePluginsDiv}>
        {/* <TextField
          id="outlined-basic"
          placeholder="Search plugins"
          variant="outlined"
          style={{ width: "320px", borderRadius: "8px" }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            style: { backgroundColor: "#fff" },
          }}
        /> */}
        <div className={classes.marketplacePluginsGrid}>
          {isPluginLoading
            ? Array.from(new Array(6)).map((_, index) => {
                return (
                  <div
                    key={index}
                    className={classes.marketplacePluginGridItem}
                  >
                    <div className={classes.marketplaceGridItemFirstDiv}>
                      <Skeleton variant="circular" width={58} height={58} />
                      <Skeleton variant="rectangular" width={80} height={36} />
                    </div>
                    <div>
                      <Skeleton variant="text" width="50%" />
                      <Skeleton
                        variant="rectangular"
                        width="100%"
                        height={60}
                      />
                    </div>
                  </div>
                );
              })
            : plugins.map((plugin, index) => {
                const {
                  code = null,
                  name: pluginName = "",
                  appDetails = {},
                  isPluginAppActiveForEnterprise = false,
                } = plugin || {};
                const { shortDescription = "", appLogoUrl = "" } =
                  appDetails || {};
                return (
                  <div
                    key={code || index}
                    className={classes.marketplacePluginGridItem}
                  >
                    <div className={classes.marketplaceGridItemFirstDiv}>
                      <img
                        alt="reviews"
                        src={getThumborUrl(appLogoUrl, 58, 58)}
                        style={{
                          height: "58px",
                          minWidth: "58px",
                          objectFit: "contain",
                          borderRadius: "6px",
                        }}
                      />
                      {isPluginAppActiveForEnterprise ? (
                        <OutlinedButton
                          onClick={() => handleInstallPlugin(code, false)}
                        >
                          {isPluginInstalling ? (
                            <CircularProgress size={24} />
                          ) : (
                            "Uninstall"
                          )}
                        </OutlinedButton>
                      ) : (
                        <PrimaryButton
                          onClick={() => handleInstallPlugin(code, true)}
                        >
                          {isPluginInstalling ? (
                            <CircularProgress size={24} />
                          ) : (
                            "Install"
                          )}
                        </PrimaryButton>
                      )}
                    </div>
                    <div>
                      <Typography
                        variant="subtitle1"
                        style={{ fontWeight: "600", fontSize: "18px" }}
                      >
                        {pluginName || ""}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        style={{ color: GRAY_FIVE }}
                      >
                        {shortDescription || ""}
                      </Typography>
                    </div>
                  </div>
                );
              })}
        </div>
      </div>
    </div>
  );
};

export default Marketplace;
