import React, { useContext, useEffect, useState } from "react";

import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import useApperanceStyles from "./styles";
import BootstrapInput from "../Commons/BootstrapInput";
import {
  API_ENDPOINT_ENTERPRISE,
  API_ENDPOINT_FAQS,
  API_RESPONSE_UNAUTHORIZED_STATUS,
  API_SECTION_API,
  API_VERSION,
  FAILURE_TEXT_COLOR,
  HARBOR_API_DOMAIN,
} from "../../constants";
import {
  AppContext,
  ControlPanelContext,
  isEmptyObject,
} from "../../utility";
import axios from "axios";

const Faqs = () => {
  const classes = useApperanceStyles();
  const { setViewSnackbarMain, handleRefreshToken, mobileView } = useContext(AppContext);
  const {
    enterprise = {},
    AuthorizationToken,
    setHeaderHeading,
  } = useContext(ControlPanelContext);
  const { enterprise: selectedEnterprise = {} } = enterprise || {};
  const { code: enterpriseCode = null } = selectedEnterprise || {};
  const [faqs, setFaqs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [hasFaqsUpdated, setHasFaqsUpdated] = useState(false);

  const getFaqs = async () => {
    const url = `${HARBOR_API_DOMAIN}${API_SECTION_API}${API_VERSION}/${API_ENDPOINT_ENTERPRISE}${enterpriseCode}/${API_ENDPOINT_FAQS}`;
    setIsLoading(true);
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          source: "website",
          Authorization: AuthorizationToken,
        },
      });
      const { data = {}, status = null } = response || {};
      const { result = [] } = data || {};
      if (status >= 200 && status < 300) {
        if (result.length === 0) setFaqs([{}]);
        else setFaqs(result);
      }
    } catch (error) {
      const { response } = error || {};
      const { status } = response || {};
      if (status === API_RESPONSE_UNAUTHORIZED_STATUS) {
        handleRefreshToken();
      } else
        setViewSnackbarMain({
          message: "Something went wrong!",
          type: "error",
        });
    } finally {
      setIsLoading(false);
    }
  };

  const handleReqBody = () => {
    const errors = [];
    let isError = false;
    faqs.forEach((faq, index) => {
      const { question = null, answer = null } = faq || {};
      const error = {};
      if (!question) error["question"] = "Cannot be empty!";
      if (!answer) error["answer"] = "Cannot be empty!";
      if (!isEmptyObject(error)) {
        errors[index] = error;
        isError = true;
      } else errors[index] = {};
    });
    if (isError) {
      setErrors(errors);
      return {};
    } else return { data: [...faqs] };
  };

  const updateFaqs = async () => {
    const url = `${HARBOR_API_DOMAIN}${API_SECTION_API}${API_VERSION}/${API_ENDPOINT_ENTERPRISE}${enterpriseCode}/${API_ENDPOINT_FAQS}`;
    const reqBody = handleReqBody();
    if (isEmptyObject(reqBody)) return;
    setIsLoading(true);
    try {
      const response = await axios.post(url, reqBody, {
        headers: {
          "Content-Type": "application/json",
          source: "website",
          Authorization: AuthorizationToken,
        },
      });
      const { data = {}, status = null } = response || {};
      const { result = [] } = data || {};
      if (status >= 200 && status < 300) {
        getFaqs();
        setViewSnackbarMain({ message: "Faqs updated successfully!!", type: "success" });
      }
    } catch (error) {
      const { response } = error || {};
      const { status } = response || {};
      if (status === API_RESPONSE_UNAUTHORIZED_STATUS) {
        handleRefreshToken();
      } else
        setViewSnackbarMain({
          message: "Something went wrong!",
          type: "error",
        });
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e, index) => {
    const faqsArr = [...faqs];
    const currentFaq = faqsArr[index];
    currentFaq[e.target.name] = e.target.value;
    currentFaq['order'] = index;
    setFaqs(faqsArr);
    setHasFaqsUpdated(true);
  };

  const handleRemoveFaq = (index, code) => {
    const faqsArr = [...faqs];
    const currentFaq = faqsArr[index];
    if (code) currentFaq['isActive'] = false;
    else faqsArr.splice(index, 1);
    setFaqs(faqsArr);
    setHasFaqsUpdated(true);
  }

  const handleAddFaq = () => {
    setFaqs([...faqs, {}]);
  };

  useEffect(() => {
    setHeaderHeading("Faqs");
  }, []);

  useEffect(() => {
    if (enterpriseCode) getFaqs();
  }, [enterpriseCode]);

  if (isLoading)
    return (
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Box
        className={classes.faqsLayoutBox}
      >
        {!mobileView && <Typography
          variant="h5"
          style={{ fontWeight: "500", fontSize: "20px" }}
        >
          Faqs
        </Typography>}
        <Box className={classes.designBox}>
          <Box
            style={{ display: "flex", flexDirection: "column", gap: "48px" }}
          >
            {faqs.map((faq, index) => {
              const { code = null, question = "", answer = "", isActive = true } = faq || {};
              if (!isActive) return <></>;
              return (
                <Box
                  key={code || index}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "24px",
                    // padding: "16px",
                    // border: `1px solid ${GRAY_FOUR}`,
                    // borderRadius: "4px",
                  }}
                >
                  {index > 0 && (
                    <Typography
                      variant="subtitle2"
                      align="right"
                      style={{ color: FAILURE_TEXT_COLOR, cursor: "pointer" }}
                      onClick={() => handleRemoveFaq(index, code)}
                    >
                      Remove
                    </Typography>
                  )}
                  <FormControl>
                    <InputLabel shrink htmlFor="question1">
                      {`Question ${index + 1}`}
                    </InputLabel>
                    <BootstrapInput
                      name="question"
                      onChange={(e) => handleInputChange(e, index)}
                      id="question1"
                      value={question}
                    />
                    {Boolean(errors[index]?.question) && (
                      <FormHelperText error>
                        {errors[index]?.question}
                      </FormHelperText>
                    )}
                  </FormControl>
                  <FormControl>
                    <InputLabel shrink htmlFor="answer1">
                      {`Answer ${index + 1}`}
                    </InputLabel>
                    <BootstrapInput
                      name="answer"
                      onChange={(e) => handleInputChange(e, index)}
                      id="answer1"
                      value={answer}
                    />
                    {Boolean(errors[index]?.answer) && (
                      <FormHelperText error>
                        {errors[index]?.answer}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Box>
              );
            })}
          </Box>
          <Button
            variant="outlined"
            color="primary"
            style={{ width: "160px", textTransform: "none" }}
            onClick={handleAddFaq}
          >
            <AddIcon /> Add another
          </Button>
        </Box>
        <Box style={{ textAlign: "end" }}>
          <Button
            variant="contained"
            color="primary"
            style={{
              textTransform: "none",
              padding: "4px 16px",
              fontSize: "14px",
            }}
            disabled={!hasFaqsUpdated}
            onClick={updateFaqs}
          >
            Update Faqs
          </Button>
        </Box>
      </Box>
    </div>
  );
};

export default Faqs;
