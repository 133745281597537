import React, { useContext, useEffect, useState } from "react";

import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  TextField,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";

import {
  API_ENDPOINT_CONTACT_US,
  API_ENDPOINT_ENTERPRISE,
  API_RESPONSE_UNAUTHORIZED_STATUS,
  API_SECTION_API,
  API_VERSION,
  FAILURE_TEXT_COLOR,
  GRAY_FIVE,
  GRAY_FOUR,
  HARBOR_API_DOMAIN,
  SOCIAL_LINKS,
} from "../../constants";
import { AppContext, ControlPanelContext, isEmptyObject } from "../../utility";
import BootstrapInput from "../Commons/BootstrapInput";

import useApperanceStyles from "./styles";
import axios from "axios";

const ContactUs = () => {
  const classes = useApperanceStyles();
  const { setViewSnackbarMain, handleRefreshToken, mobileView } =
    useContext(AppContext);
  const {
    setHeaderHeading,
    AuthorizationToken,
    enterprise = {},
  } = useContext(ControlPanelContext);
  const { enterprise: selectedEnterprise = {} } = enterprise || {};
  const { code: enterpriseCode = null } = selectedEnterprise || {};
  const [phones, setPhones] = useState([]);
  const [emails, setEmails] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [socialMediaLinks, setSocialMediaLinks] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({
    phones: [],
    emails: [],
    addresses: [],
  });

  const getContactUsInfo = async () => {
    const url = `${HARBOR_API_DOMAIN}${API_SECTION_API}${API_VERSION}/${API_ENDPOINT_ENTERPRISE}${enterpriseCode}/${API_ENDPOINT_CONTACT_US}`;
    setIsLoading(true);
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          source: "website",
          Authorization: AuthorizationToken,
        },
      });
      const { data = {}, status = null } = response || {};
      const { result = {} } = data || {};
      if (status >= 200 && status < 300) {
        const {
          phoneNumbers = [],
          emails = [],
          addresses = [],
          socialMediaLinks = [],
        } = result || {};
        setPhones(phoneNumbers);
        setEmails(emails);
        setAddresses(addresses);
        const obj = {};
        socialMediaLinks.forEach((social) => {
          const { type = null } = social || {};
          obj[type] = { ...social };
        });
        setSocialMediaLinks(obj);
      }
    } catch (error) {
      const { response } = error || {};
      const { status } = response || {};
      if (status === API_RESPONSE_UNAUTHORIZED_STATUS) {
        handleRefreshToken();
      } else
        setViewSnackbarMain({
          message: "Something went wrong!",
          type: "error",
        });
    } finally {
      setIsLoading(false);
    }
  };

  const handleError = (arr = [], key) => {
    const error = [];
    arr.forEach((item, index) => {
      error[index] = {};
      if (key === "phones") {
        if (!item["phone"]) error[index]["phone"] = "Cannot be empty";
      } else if (key === "emails") {
        if (!item["email"]) error[index]["email"] = "Cannot be empty";
      } else if (key === "addresses") {
        if (!item["line1"]) error[index]["line1"] = "Cannot be empty";
        if (!item["line2"]) error[index]["line2"] = "Cannot be empty";
        if (!item["city"]) error[index]["city"] = "Cannot be empty";
        if (!item["state"]) error[index]["state"] = "Cannot be empty";
        if (!item["country"]) error[index]["country"] = "Cannot be empty";
        if (!item["postalCode"]) error[index]["postalCode"] = "Cannot be empty";
      }
    });
    setErrors((prev) => ({ ...prev, [key]: error }));
    let isError = false;
    for (let i = 0; i < arr.length; i++) {
      if (!isEmptyObject(error[i])) {
        isError = true;
        break;
      }
    }
    return isError;
  };

  const handleReqBody = () => {
    const data = {};
    const isErrorInPhoneField = handleError(phones, "phones");
    const isErrorInEmailsField = handleError(emails, "emails");
    const isErrorInAddressesField = handleError(addresses, "addresses");
    if (isErrorInPhoneField || isErrorInEmailsField || isErrorInAddressesField)
      return {};
    if (!isEmptyObject(socialMediaLinks)) {
      data["socialMediaLinks"] = Object.values(socialMediaLinks);
    }
    data["emails"] = emails;
    data["phoneNumbers"] = phones;
    data["addresses"] = addresses;
    return { data };
  };

  const updateContactUs = async () => {
    const url = `${HARBOR_API_DOMAIN}${API_SECTION_API}${API_VERSION}/${API_ENDPOINT_ENTERPRISE}${enterpriseCode}/${API_ENDPOINT_CONTACT_US}`;
    const reqBody = handleReqBody();
    if (isEmptyObject(reqBody)) return;
    try {
      const response = await axios.post(url, reqBody, {
        headers: {
          "Content-Type": "application/json",
          source: "website",
          Authorization: AuthorizationToken,
        },
      });
      const { data = {}, status = null } = response || {};
      const { result = {} } = data || {};
      if (status >= 200 && status < 300) {
        getContactUsInfo();
        setViewSnackbarMain({
          message: "Contact us updated successfully!!!",
          type: "success",
        });
      }
    } catch (error) {
      const { response } = error || {};
      const { status } = response || {};
      if (status === API_RESPONSE_UNAUTHORIZED_STATUS) {
        handleRefreshToken();
      } else
        setViewSnackbarMain({
          message: "Something went wrong!",
          type: "error",
        });
    }
  };

  const handleSocialMediaInput = (e, type) => {
    const copyOfSocialMediaLinks = { ...socialMediaLinks };
    const inputToBeChanged = copyOfSocialMediaLinks[type] || {};
    inputToBeChanged["link"] = e.target.value;
    inputToBeChanged["type"] = type;
    inputToBeChanged["isActive"] = true;
    copyOfSocialMediaLinks[type] = inputToBeChanged;
    setSocialMediaLinks(copyOfSocialMediaLinks);
  };

  const handleAddAnother = (key) => {
    switch (key) {
      case "phone":
        setPhones((prev) => [...prev, {}]);
        break;
      case "email":
        setEmails((prev) => [...prev, {}]);
        break;
      case "address":
        setAddresses((prev) => [...prev, {}]);
        break;
      default:
        break;
    }
  };

  const handlePhoneInput = (event, index, feature = "add", code) => {
    const copyOfPhones = [...phones];
    const phoneToBeInput = copyOfPhones[index] || {};
    if (errors["phones"][index]?.phone) delete errors["phones"][index]?.phone;
    if (feature === "add") phoneToBeInput["phone"] = event.target.value;
    else {
      if (code) {
        phoneToBeInput["isActive"] = false;
      } else copyOfPhones.splice(index, 1);
    }
    setPhones(copyOfPhones);
  };

  const handleEmailInput = (event, index, feature = "add", code) => {
    const copyOfEmails = [...emails];
    const emailToBeInput = copyOfEmails[index] || {};
    if (errors["emails"][index]?.email) delete errors["emails"][index]?.email;
    if (feature === "add") emailToBeInput["email"] = event.target.value;
    else {
      if (code) {
        emailToBeInput["isActive"] = false;
      } else copyOfEmails.splice(index, 1);
    }
    setEmails(copyOfEmails);
  };

  const handleAddressInputChange = (event, index, feature = "add", code) => {
    const copyOfAddresses = [...addresses];
    const addressToBeChanged = copyOfAddresses[index] || {};
    if (errors["addresses"][index]) {
      if (delete errors["addresses"][index][event.target.name])
        delete errors["addresses"][index][event.target.name];
    }
    if (feature === "add")
      addressToBeChanged[event.target.name] = event.target.value;
    else {
      if (code) {
        addressToBeChanged["isActive"] = false;
      } else copyOfAddresses.splice(index, 1);
    }
    setAddresses(copyOfAddresses);
  };

  const handleRadioButtonPhoneChange = (event, index) => {
    const copyOfPhones = [...phones];
    const phoneToBeInput = copyOfPhones[index] || {};
    phoneToBeInput[event.target.name] = event.target.checked;
    setPhones(copyOfPhones);
  };

  const handleRadioButtonEmailChange = (event, index) => {
    const copyOfEmails = [...emails];
    const emailToBeInput = copyOfEmails[index] || {};
    emailToBeInput[event.target.name] = event.target.checked;
    setEmails(copyOfEmails);
  };

  useEffect(() => {
    setHeaderHeading("Contact us");
  }, []);

  useEffect(() => {
    if (enterpriseCode) getContactUsInfo();
  }, [enterpriseCode]);

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      {isLoading ? (
        <CircularProgress color="primary" />
      ) : (
        <Box className={classes.contactLayoutBox}>
          {!mobileView && <Typography
            variant="h5"
            style={{ fontWeight: "500", fontSize: "20px" }}
          >
            Contact Information
          </Typography>}
          <Box className={classes.designBox}>
            <Box>
              <Typography
                className={classes.designBoxHeading}
                variant="subtitle1"
              >
                Phone Numbers
              </Typography>
              <Typography
                className={classes.designBoxSubHeading}
                variant="subtitle2"
              >
                Include dial code (eg +91) with phone number
              </Typography>
            </Box>
            {phones.map((item, index) => {
              const {
                code = null,
                phone = "",
                isPrimary = false,
                isWhatsapp = false,
                isActive = true,
              } = item || {};
              if (!isActive) return <></>;
              return mobileView ? (
                <Box
                  key={code || index}
                  style={{
                    display: "flex",
                    gap: "8px",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "16px",
                    }}
                  >
                    <TextField
                      style={{ flex: 1 }}
                      id="outlined-basic"
                      //   label="Enter mobile number"
                      placeholder="Enter mobile number"
                      variant="outlined"
                      value={phone || ""}
                      type="text"
                      onChange={(e) => handlePhoneInput(e, index)}
                      error={errors["phones"][index]?.phone}
                      helperText={errors["phones"][index]?.phone}
                    />
                    <DeleteIcon
                      style={{ cursor: "pointer" }}
                      onClick={(e) =>
                        handlePhoneInput(e, index, "remove", code)
                      }
                    />
                  </Box>
                  <Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isPrimary}
                          onChange={(e) =>
                            handleRadioButtonPhoneChange(e, index)
                          }
                          name="isPrimary"
                        />
                      }
                      label="Primary"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isWhatsapp}
                          onChange={(e) =>
                            handleRadioButtonPhoneChange(e, index)
                          }
                          name="isWhatsapp"
                        />
                      }
                      label="Whatsapp"
                    />
                  </Box>
                </Box>
              ) : (
                <Box
                  key={code || index}
                  style={{
                    display: "flex",
                    gap: "24px",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    style={{ flex: 1 }}
                    id="outlined-basic"
                    //   label="Enter mobile number"
                    placeholder="Enter mobile number"
                    variant="outlined"
                    value={phone || ""}
                    type="text"
                    onChange={(e) => handlePhoneInput(e, index)}
                    error={errors["phones"][index]?.phone}
                    helperText={errors["phones"][index]?.phone}
                  />
                  <Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isPrimary}
                          onChange={(e) =>
                            handleRadioButtonPhoneChange(e, index)
                          }
                          name="isPrimary"
                        />
                      }
                      label="Primary"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isWhatsapp}
                          onChange={(e) =>
                            handleRadioButtonPhoneChange(e, index)
                          }
                          name="isWhatsapp"
                        />
                      }
                      label="Whatsapp"
                    />
                  </Box>
                  <DeleteIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => handlePhoneInput(e, index, "remove", code)}
                  />
                </Box>
              );
            })}
            <Button
              variant="outlined"
              color="primary"
              style={{ width: "160px", textTransform: "none" }}
              onClick={() => handleAddAnother("phone")}
            >
              <AddIcon /> Add another
            </Button>
          </Box>
          <Box className={classes.designBox}>
            <Typography
              className={classes.designBoxHeading}
              variant="subtitle1"
            >
              Emails
            </Typography>
            {emails.map((item, index) => {
              const {
                code = null,
                email = "",
                isPrimary = false,
                isActive = true,
              } = item || {};
              if (!isActive) return <></>;
              return mobileView ? (
                <Box
                  key={code || index}
                  style={{
                    display: "flex",
                    gap: "8px",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "16px",
                    }}
                  >
                    <TextField
                      style={{ flex: 1 }}
                      id="outlined-basic"
                      //   label="Enter mobile number"
                      placeholder="Enter email address"
                      variant="outlined"
                      value={email}
                      type="text"
                      onChange={(e) => handleEmailInput(e, index)}
                      error={errors["emails"][index]?.email}
                      helperText={errors["emails"][index]?.email}
                    />
                    <DeleteIcon
                      style={{ cursor: "pointer" }}
                      onClick={(e) =>
                        handleEmailInput(e, index, "remove", code)
                      }
                    />
                  </Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isPrimary}
                        onChange={(e) => handleRadioButtonEmailChange(e, index)}
                        name="isPrimary"
                      />
                    }
                    label="Primary"
                  />
                </Box>
              ) : (
                <Box
                  key={code || index}
                  style={{
                    display: "flex",
                    gap: "24px",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    style={{ flex: 1 }}
                    id="outlined-basic"
                    //   label="Enter mobile number"
                    placeholder="Enter email address"
                    variant="outlined"
                    value={email}
                    type="text"
                    onChange={(e) => handleEmailInput(e, index)}
                    error={errors["emails"][index]?.email}
                    helperText={errors["emails"][index]?.email}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isPrimary}
                        onChange={(e) => handleRadioButtonEmailChange(e, index)}
                        name="isPrimary"
                      />
                    }
                    label="Primary"
                  />
                  <DeleteIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => handleEmailInput(e, index, "remove", code)}
                  />
                </Box>
              );
            })}
            <Button
              variant="outlined"
              color="primary"
              style={{ width: "160px", textTransform: "none" }}
              onClick={() => handleAddAnother("email")}
            >
              <AddIcon /> Add another
            </Button>
          </Box>
          <Box className={classes.designBox}>
            <Typography
              className={classes.designBoxHeading}
              variant="subtitle1"
            >
              Addresses
            </Typography>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: "30px",
              }}
            >
              {addresses.map((item, index) => {
                const {
                  code = null,
                  line1 = "",
                  line2 = "",
                  city = "",
                  state = "",
                  country = "",
                  postalCode = "",
                  isActive = true,
                } = item || {};
                if (!isActive) return <></>;
                return (
                  <Box>
                    <Typography
                      variant="subtitle2"
                      align="right"
                      style={{
                        color: FAILURE_TEXT_COLOR,
                        cursor: "pointer",
                        marginBottom: "4px",
                      }}
                      onClick={(e) =>
                        handleAddressInputChange(e, index, "remove", code)
                      }
                    >
                      Remove
                    </Typography>
                    <Box
                      key={code}
                      style={{
                        display: "grid",
                        gridTemplateColumns: `repeat(${mobileView ? 1 : 2}, 1fr)`,
                        columnGap: "24px",
                        rowGap: "24px",
                        padding: "16px",
                        border: `1px solid ${GRAY_FOUR}`,
                        borderRadius: "4px",
                      }}
                    >
                      <FormControl>
                        <InputLabel shrink htmlFor="line1">
                          Address Line 1
                        </InputLabel>
                        <BootstrapInput
                          name="line1"
                          onChange={(e) => handleAddressInputChange(e, index)}
                          value={line1 || ""}
                          id="line1"
                        />
                        <FormHelperText
                          error={errors["addresses"][index]?.line1}
                        >
                          {errors["addresses"][index]?.line1}
                        </FormHelperText>
                      </FormControl>
                      <FormControl>
                        <InputLabel shrink htmlFor="line2">
                          Address Line 2
                        </InputLabel>
                        <BootstrapInput
                          name="line2"
                          onChange={(e) => handleAddressInputChange(e, index)}
                          value={line2 || ""}
                          id="line2"
                        />
                        <FormHelperText
                          error={errors["addresses"][index]?.line2}
                        >
                          {errors["addresses"][index]?.line2}
                        </FormHelperText>
                      </FormControl>
                      <FormControl>
                        <InputLabel shrink htmlFor="city">
                          City
                        </InputLabel>
                        <BootstrapInput
                          name="city"
                          onChange={(e) => handleAddressInputChange(e, index)}
                          value={city || ""}
                          id="city"
                        />
                        <FormHelperText
                          error={errors["addresses"][index]?.city}
                        >
                          {errors["addresses"][index]?.city}
                        </FormHelperText>
                      </FormControl>
                      <FormControl>
                        <InputLabel shrink htmlFor="state">
                          State
                        </InputLabel>
                        <BootstrapInput
                          name="state"
                          onChange={(e) => handleAddressInputChange(e, index)}
                          value={state || ""}
                          id="state"
                        />
                        <FormHelperText
                          error={errors["addresses"][index]?.state}
                        >
                          {errors["addresses"][index]?.state}
                        </FormHelperText>
                      </FormControl>
                      <FormControl>
                        <InputLabel shrink htmlFor="country">
                          Country
                        </InputLabel>
                        <BootstrapInput
                          name="country"
                          onChange={(e) => handleAddressInputChange(e, index)}
                          value={country || ""}
                          id="country"
                        />
                        <FormHelperText
                          error={errors["addresses"][index]?.country}
                        >
                          {errors["addresses"][index]?.country}
                        </FormHelperText>
                      </FormControl>
                      <FormControl>
                        <InputLabel shrink htmlFor="postalCode">
                          Pin Code
                        </InputLabel>
                        <BootstrapInput
                          name="postalCode"
                          onChange={(e) => handleAddressInputChange(e, index)}
                          value={postalCode || ""}
                          id="postalCode"
                        />
                        <FormHelperText
                          error={errors["addresses"][index]?.postalCode}
                        >
                          {errors["addresses"][index]?.postalCode}
                        </FormHelperText>
                      </FormControl>
                    </Box>
                  </Box>
                );
              })}
            </Box>
            <Button
              variant="outlined"
              color="primary"
              style={{ width: "160px", textTransform: "none" }}
              onClick={() => handleAddAnother("address")}
            >
              <AddIcon /> Add another
            </Button>
          </Box>
          <Box className={classes.designBox}>
            <Typography
              className={classes.designBoxHeading}
              variant="subtitle1"
            >
              Social media links
            </Typography>
            <Box
              style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            >
              {Object.values(SOCIAL_LINKS).map((social, index) => {
                const { Icon, name = "" } = social || {};
                const { link = "" } = socialMediaLinks[index + 1] || {};
                return (
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: mobileView ? "16px" : "48px",
                    }}
                  >
                    {index === 2 ? (
                      <Box className={classes.twitterIcon}></Box>
                    ) : (
                      <Icon fontSize="large" style={{ color: GRAY_FIVE }} />
                    )}
                    <TextField
                      style={{ flex: 1 }}
                      variant="outlined"
                      placeholder={`Enter ${name} url`}
                      value={link}
                      onChange={(e) => handleSocialMediaInput(e, index + 1)}
                    />
                  </Box>
                );
              })}
            </Box>
          </Box>
          <Box style={{ textAlign: "end" }}>
            <Button
              variant="contained"
              color="primary"
              style={{
                textTransform: "none",
                padding: "4px 16px",
                fontSize: "14px",
              }}
              onClick={updateContactUs}
            >
              Update
            </Button>
          </Box>
        </Box>
      )}
    </div>
  );
};

export default ContactUs;
