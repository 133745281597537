import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { CircularProgress, FormControl, InputLabel } from "@material-ui/core";
import BootstrapInput from "../Commons/BootstrapInput";
import {
  API_ENDPOINT_ENTERPRISE,
  API_RESPONSE_UNAUTHORIZED_STATUS,
  API_SECTION_API,
  API_VERSION,
  DOCFYN_API_DOMAIN,
  GRAY_ONE,
  THEME_PRIMARY_COLOR,
} from "../../constants";
import { Link } from "react-router-dom";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import LocalStorage from "../../localStorage";
import { AppContext, ControlPanelContext, devConsoleLog, getClinicDomainName, _authorization } from "../../utility";
import axios from "axios";
import useLocalStorage from "../../hooks/useLocalStorage";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    width: "120px",
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
    marginTop: "16px",
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  stepLabel: {
    fontSize: "1rem",
  },
}));

const VerticalStepper = ({activeStep, setActiveStep}) => {
  const classes = useStyles();
  const { handleRefreshToken, setViewSnackbarMain } = useContext(AppContext);
  const { isEnterprise, setEnterprises } = useContext(ControlPanelContext);
  // const [activeStep, setActiveStep] = useState(null);
  const [enterpriseName, setEnterpriseName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useLocalStorage("user", {});
  const { enterprise = {}, auth_tokens = {} } = userData || {};
  const AuthorizationToken = `Bearer ${auth_tokens.AccessToken || ""}`;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleEnterpriseNameChange = (event) => {
    setEnterpriseName(event.target.value);
  };

  const handleCreateClinic = async () => {
    setIsLoading(true);
    const url = `${DOCFYN_API_DOMAIN}${API_SECTION_API}${API_VERSION}/${API_ENDPOINT_ENTERPRISE}`;
    const reqBody = { name: enterpriseName };
    try {
      const response = await axios.post(url, reqBody, {
        headers: {
          "Content-Type": "application/json",
          source: "website",
          Authorization: AuthorizationToken,
        },
      });
      const { data = {}, error } = response || {};
      const { result = {} } = data || {};
      const loggedInUser = LocalStorage.getUser() || {};
      const flag = LocalStorage.setUser({ ...loggedInUser, enterprise: result });
      if (flag) {
        setEnterprises((prev) => ([...prev, result]));
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } catch (error) {
      const { response } = error || {};
      const { status = null } = response || {};
      if (status === API_RESPONSE_UNAUTHORIZED_STATUS) {
        // handleRefreshToken();
      } else
        setViewSnackbarMain({
          message: "Something went wrong. Please try again !",
          type: "error",
        });
    } finally {
      setIsLoading(false);
    }
  };

  const handleVisitClinic = () => {
    const domain = getClinicDomainName();
    devConsoleLog('domain', domain)
    window.open(domain, "_blank");
  }

  useEffect(() => {
    if (isEnterprise) setActiveStep(1);
    else setActiveStep(0);
  }, [isEnterprise]);

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} orientation="vertical">
        <Step>
          <StepLabel classes={{ label: classes.stepLabel }}>
            {"Create your e-clinic"}
          </StepLabel>
          <StepContent>
            <Typography
              variant="subtitle2"
              style={{ color: GRAY_ONE, fontWeight: "400" }}
            >
              Craft a Distinct Identity for Your E-Clinic with a Unique Name
            </Typography>
            <div className={classes.actionsContainer}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "12px",
                }}
              >
                <FormControl style={{ width: "100%" }}>
                  <InputLabel
                    shrink
                    htmlFor="enterpriseName"
                    // style={{ fontSize: "18px", color: "#333333" }}
                  >
                    Clinic Name
                  </InputLabel>
                  <BootstrapInput
                    id="enterpriseName"
                    value={enterpriseName}
                    onChange={handleEnterpriseNameChange}
                  />
                </FormControl>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleCreateClinic}
                  className={classes.button}
                >
                  {isLoading ? <CircularProgress size={24} /> : "Create"}
                </Button>
              </div>
            </div>
          </StepContent>
        </Step>
        <Step>
          <StepLabel classes={{ label: classes.stepLabel }}>
            {"Visit your Clinic website"}
          </StepLabel>
          <StepContent>
            <Typography
              variant="subtitle2"
              style={{ color: GRAY_ONE, fontWeight: "400" }}
            >
              Your Clinic Website is now live.
            </Typography>
            <div className={classes.actionsContainer}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "12px",
                }}
              >
                <Link
                  href="#"
                  onClick={handleVisitClinic}
                  style={{
                    color: THEME_PRIMARY_COLOR,
                    textDecoration: "underline",
                    display: "flex",
                    alignItems: "center",
                    fontWeight: "500",
                  }}
                >
                  Visit Clinic <ArrowRightAltIcon />
                </Link>
              </div>
            </div>
          </StepContent>
        </Step>
      </Stepper>
      {/* {activeStep === steps.length && (
        <Paper square elevation={0} className={classes.resetContainer}>
          <Typography>All steps completed - you&apos;re finished</Typography>
          <Button onClick={handleReset} className={classes.button}>
            Reset
          </Button>
        </Paper>
      )} */}
    </div>
  );
};

export default VerticalStepper;
