import { devConsoleLog } from "./utility"

const LocalStorage = {

    getUser: function () {
        if (typeof window !== 'undefined') {
            try {
              devConsoleLog('LocalStorageUser', window.localStorage)
              var user = JSON.parse(window.localStorage.getItem("user"))
              return user;
            } catch (error) {
              return null;
            }
        }
        return null
    }
    ,

    setUser: function (user) {
        if (typeof window !== 'undefined') {
            try {
              window.localStorage.setItem("user", JSON.stringify(user))
              console.log("in set user 1")
              const User = JSON.parse(window.localStorage.getItem("user"))
              console.log("in set user 2", User, user)
              if (User) {
                return true
              }
            } catch (e) {
              console.error(e)
              return false
            }
        }
        return false
    },

    deleteUser: function () {
        if (typeof window !== 'undefined') {
            window.localStorage.removeItem("user")
            return true
        }
        return false
    },

    setData: function (key, data) {
      console.log('setData ', key, data, typeof window !== 'undefined', typeof data, JSON.stringify(data));
      if (typeof window !== 'undefined') {
          window.localStorage.setItem(key, typeof data == 'object' ? JSON.stringify(data) : data)
          return true
      }
      return false
    },

    getData: function (key) {
      console.log('getData ', key);
        if (typeof window !== 'undefined') {
            let data = window.localStorage.getItem(key)
            if(data) return data
        }
        return null
    },

    deleteData: (key) => {
      try {
        if(key) localStorage.removeItem(key);
        return true;
      } catch (e) {
        return false;
      }
    }
}

export default LocalStorage
