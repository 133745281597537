import React, { useContext, useEffect, useState } from "react";

import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";

import {
  API_ENDPOINT_ENTERPRISE,
  API_ENDPOINT_GALLERY,
  API_RESPONSE_UNAUTHORIZED_STATUS,
  API_SECTION_API,
  API_VERSION,
  GRAY_ONE,
  HARBOR_API_DOMAIN,
} from "../../../constants";
import useApperanceStyles from "../styles";
import {
  AppContext,
  ControlPanelContext,
  getThumborUrl,
  handleImageUpload,
  isEmptyObject,
} from "../../../utility";
import axios from "axios";
import useStyles from "./styles";

const Photos = ({ galleryData = {}, getGalleryData }) => {
  const classes = useStyles();
  const appearanceClasses = useApperanceStyles();
  const { setViewSnackbarMain, handleRefreshToken } = useContext(AppContext);
  const { enterprise = {}, AuthorizationToken } =
    useContext(ControlPanelContext);
  const { enterprise: selectedEnterprise = {} } = enterprise || {};
  const { code: enterpriseCode = null } = selectedEnterprise || {};
  const [photos, setPhotos] = useState([]);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [hasInputChanged, setHasInputChanged] = useState(false);

  const handleAddImage = async (event) => {
    setIsImageLoading(true);
    const image = await handleImageUpload(event);
    if (image) {
      const {
        cdn_url: imageUrl = "",
        error = "",
        object_url: imageObjectUrl = "",
      } = image || {};
      if (error) {
        setIsImageLoading(false);
        setViewSnackbarMain({
          message: "Photo couldn't be uploaded",
          type: "error",
        });
      } else {
        setIsImageLoading(false);
        setPhotos((prev) => [...prev, { imageUrl, imageObjectUrl }]);
        setHasInputChanged(true);
      }
    }
  };

  const handleReqBody = () => {
    return { data: [...photos] };
  };

  const updatePhotos = async () => {
    if (!enterpriseCode) return;
    const url = `${HARBOR_API_DOMAIN}${API_SECTION_API}${API_VERSION}/${API_ENDPOINT_ENTERPRISE}${enterpriseCode}/${API_ENDPOINT_GALLERY}`;
    const reqBody = handleReqBody();
    if (isEmptyObject(reqBody)) return;
    try {
      const response = await axios.post(url, reqBody, {
        headers: {
          "Content-Type": "application/json",
          source: "website",
          Authorization: AuthorizationToken,
        },
      });
      const { data = {}, status = null } = response || {};
      if (status >= 200 && status < 300) {
        setViewSnackbarMain({
          message: "Photos updated successfully",
          type: "success",
        });
        getGalleryData();
      }
    } catch (error) {
      const { response } = error || {};
      const { status } = response || {};
      if (status === API_RESPONSE_UNAUTHORIZED_STATUS) {
        handleRefreshToken();
      } else
        setViewSnackbarMain({
          message: "Something went wrong!",
          type: "error",
        });
    }
  };

  const handlePhotoDelete = (index, code) => {
    const copyOfPhotos = [...photos];
    const photoTobeDeleted = copyOfPhotos[index];
    if (code) {
      photoTobeDeleted["isActive"] = false;
    } else copyOfPhotos.splice(index, 1);
    setPhotos(copyOfPhotos);
    setHasInputChanged(true);
  };

  useEffect(() => {
    if (galleryData["photos"].length > 0) {
      setPhotos(galleryData["photos"]);
    }
  }, [galleryData]);

  return (
    <Box className={appearanceClasses.tabContentBox}>
      <Box className={appearanceClasses.designBox}>
        <Box>
          <Typography
            className={appearanceClasses.designBoxHeading}
            variant="subtitle1"
          >
            Photos
          </Typography>
          {/* <Typography
            className={appearanceClasses.designBoxSubHeading}
            variant="subtitle2"
          >
            Upload photos 
          </Typography> */}
        </Box>
        <Box
          className={classes.photosGridLayout}
        >
          {photos.map((photo, index) => {
            const { imageUrl = "", code = null, isActive = true } = photo || {};
            if (!isActive) return <></>;
            return (
              <Box key={code || index} style={{ position: "relative" }}>
                <img
                  alt="photo1"
                  src={getThumborUrl(imageUrl, 200, 0)}
                  style={{
                    height: "200px",
                    objectFit: "cover",
                    objectPosition: "center",
                    width: "100%",
                    borderRadius: "6px",
                    filter: "brightness(50%)",
                  }}
                />
                <IconButton
                  style={{
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                  onClick={() => handlePhotoDelete(index, code)}
                >
                  <DeleteIcon fontSize="large" style={{ color: "#fff" }} />
                </IconButton>
              </Box>
            );
          })}
          <Box
            style={{
              //   width: "320px",
              height: "200px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: `1px dashed ${GRAY_ONE}`,
              borderRadius: "6px",
              cursor: "pointer",
              color: GRAY_ONE,
              flexDirection: "column",
            }}
            component="label"
          >
            {isImageLoading ? (
              <CircularProgress color="primary" />
            ) : (
              <>
                <AddIcon style={{ fontSize: "32px" }} />
                <Typography variant="body1" style={{ fontWeight: "300" }}>
                  Add photos
                </Typography>
                <input type="file" hidden onChange={handleAddImage} />
              </>
            )}
          </Box>
        </Box>
      </Box>
      <Box style={{ textAlign: "end" }}>
        <Button
          variant="contained"
          color="primary"
          style={{
            textTransform: "none",
            padding: "4px 16px",
            fontSize: "14px",
          }}
          onClick={updatePhotos}
          disabled={!hasInputChanged}
        >
          Update
        </Button>
      </Box>
    </Box>
  );
};

export default Photos;
