import React, { useContext, useEffect, useState } from "react";

import {
  Box,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from "@material-ui/core";
import FaceIcon from "@material-ui/icons/Face";

import BootstrapInput from "../Commons/BootstrapInput";
import {
  AppContext,
  ControlPanelContext,
  devConsoleLog,
  getThumborUrl,
  handleImageUpload,
} from "../../utility";
import {
  API_ENDPOINT_SPECIALITIES,
  API_RESPONSE_UNAUTHORIZED_STATUS,
  API_SECTION_API,
  API_SECTION_PROFILE,
  API_SECTION_PUBLIC,
  API_VERSION,
  DOCFYN_API_DOMAIN,
  GRAY_FOUR,
  GRAY_TWO,
} from "../../constants";
import useStyles from "./styles";
import axios from "axios";
import Autocomplete from "@material-ui/lab/Autocomplete";

const MyProfile = () => {
  const classes = useStyles();
  const { setViewSnackbarMain, mobileView, handleRefreshToken } =
    useContext(AppContext);
  const { setHeaderHeading, AuthorizationToken } =
    useContext(ControlPanelContext);
  const [input, setInput] = useState({});
  const [specialities, setSpecialities] = useState([]);
  const [specialitiesObj, setSpecialitiesObj] = useState({});
  const [selectedSpecialities, setSelectedSpecialities] = useState([]);
  const [originalProfileData, setOriginalProfileData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (event) => {
    setInput((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  // const handleSpecialitySelection = (e, value) => {
  //   const { code } = value || {};
  //   setInput((prev) => ({ ...prev, medicalSpecialityCode: code }));
  // };

  const getProfileData = async () => {
    const url = `${DOCFYN_API_DOMAIN}${API_SECTION_API}${API_VERSION}/${API_SECTION_PROFILE}`;
    setIsLoading(true);
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          source: "website",
          Authorization: AuthorizationToken,
        },
      });
      const { data = {}, status = null } = response || {};
      const { result = {} } = data || {};
      const { specialities = [] } = result || {};
      if (status >= 200 && status < 300) {
        setInput(result);
        handleSpecialitiesObjCreation(specialities);
        setSelectedSpecialities(specialities);
        setOriginalProfileData(result);
      }
    } catch (error) {
      const { response } = error || {};
      const { status } = response || {};
      if (status === API_RESPONSE_UNAUTHORIZED_STATUS) {
        handleRefreshToken();
      } else
        setViewSnackbarMain({
          message: "Something went wrong!",
          type: "error",
        });
    } finally {
      setIsLoading(false);
    }
  };

  const getReqBody = () => {
    const reqBody = {};
    for (let i in input) {
      if (i === "name" || i === "email" || i === "profilePicture")
        reqBody[i] = input[i];
    }

    const selectedSpecialitiesCode = [];
    const obj = {};
    selectedSpecialities.forEach((speciality) => {
      const { code = null } = speciality || {};
      obj[code] = {
        code,
        isActive: true,
      };
      selectedSpecialitiesCode.push(obj[code]);
    });
    const isActiveExistingSpecialities = Object.values(specialitiesObj).map(
      (speciality) => {
        const { code = null } = speciality || {};
        if (obj[code]) return speciality;
        else return { code, isActive: false };
      }
    );
    reqBody["doctorSpecialities"] = [
      ...isActiveExistingSpecialities,
      ...selectedSpecialitiesCode,
    ];
    const jsonObject = reqBody["doctorSpecialities"].map(JSON.stringify);
    const uniqueSelectedSpecialitiesSet = new Set(jsonObject);
    const uniqueSelectedSpecialities = Array.from(
      uniqueSelectedSpecialitiesSet
    ).map(JSON.parse);
    devConsoleLog("reqBody", uniqueSelectedSpecialities);
    reqBody["doctorSpecialities"] = uniqueSelectedSpecialities;
    return reqBody;
  };

  const handleUpdateProfile = async () => {
    const url = `${DOCFYN_API_DOMAIN}${API_SECTION_API}${API_VERSION}/${API_SECTION_PROFILE}`;
    const reqBody = getReqBody();
    try {
      const response = await axios.patch(url, reqBody, {
        headers: {
          "Content-Type": "application/json",
          source: "website",
          Authorization: AuthorizationToken,
        },
      });
      const { data = {}, status = null } = response || {};
      const { result = {} } = data || {};
      if (status >= 200 && status < 300) {
        getProfileData();
      }
    } catch (error) {
      const { response } = error || {};
      const { status } = response || {};
      if (status === API_RESPONSE_UNAUTHORIZED_STATUS) {
        handleRefreshToken();
      } else
        setViewSnackbarMain({
          message: "Something went wrong!",
          type: "error",
        });
    }
  };

  const handleSpecialitySelection = (event, value) => {
    const newSpecialityAdded = value[value.length - 1] || {};
    const { code = null } = newSpecialityAdded || {};
    setSelectedSpecialities(value);
  };

  const handleSpecialitiesObjCreation = (result) => {
    const obj = {};
    result.forEach((speciality) => {
      const { code = null } = speciality || {};
      obj[code] = {
        code,
        isActive: true,
      };
    });
    setSpecialitiesObj(obj);
  };

  const getDoctorSpecialities = async () => {
    const url = `${DOCFYN_API_DOMAIN}${API_SECTION_API}${API_VERSION}/${API_SECTION_PUBLIC}${API_ENDPOINT_SPECIALITIES}`;
    try {
      const response = await axios.get(url);
      const { status = null, data = {} } = response || {};
      const { error = false, result = [] } = data || {};
      if (status >= 200 && status < 300) {
        setSpecialities(result);
      }
    } catch (err) {
      setViewSnackbarMain({ message: "Something went wrong!", type: "error" });
    }
  };

  const handleProfileImgUpload = async (event) => {
    const image = await handleImageUpload(event);
    if (image) {
      const {
        cdn_url: profilePicture = "",
        error = "",
        object_url = "",
      } = image || {};
      if (error) {
        const { response } = error || {};
        const { status } = response || {};
        if (status === API_RESPONSE_UNAUTHORIZED_STATUS) {
          handleRefreshToken();
        } else
          setViewSnackbarMain({
            message: "Photo couldn't be uploaded",
            type: "error",
          });
      } else {
        setInput((prev) => ({ ...prev, profilePicture }));
      }
    }
  };

  useEffect(() => {
    setHeaderHeading("Profile");
    getProfileData();
    getDoctorSpecialities();
  }, []);

  if (isLoading)
    return (
      <Box
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  else
    return (
      <Box style={{ display: "flex", justifyContent: "center" }}>
        <Box className={classes.myProfileBox}>
          {!mobileView && (
            <Typography
              variant="h5"
              style={{ fontWeight: "500", fontSize: "20px" }}
            >
              My Profile
            </Typography>
          )}
          <Box
            style={{ display: "flex", flexDirection: "column", gap: "24px" }}
          >
            <Box className={classes.myProfileSubSection}>
              <Typography
                variant="h6"
                className={classes.myProfileSubSectionHeading}
              >
                Profile picture
              </Typography>
              <Box
                style={{ display: "flex", gap: "1rem", alignItems: "center" }}
              >
                <Box
                  style={{
                    height: "6rem",
                    width: "6rem",
                    backgroundColor: GRAY_FOUR,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "8px",
                    cursor: "pointer",
                    overflow: "hidden",
                  }}
                  component="label"
                >
                  {input["profilePicture"] ? (
                    <img
                      src={getThumborUrl(input["profilePicture"], 0, 0)}
                      alt="profile"
                      style={{ height: "6rem", width: "6rem" }}
                    />
                  ) : (
                    <>
                      <FaceIcon style={{ fontSize: "4rem", color: GRAY_TWO }} />
                      <input
                        type="file"
                        hidden
                        onChange={handleProfileImgUpload}
                      />
                    </>
                  )}
                </Box>
                <Box
                  variant="contained"
                  style={{
                    backgroundColor: "#F2F2F2",
                    textTransform: "none",
                    fontSize: "14px",
                    fontWeight: "400",
                    padding: "8px 24px",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                  component="label"
                >
                  Update image
                  <input type="file" hidden onChange={handleProfileImgUpload} />
                </Box>
              </Box>
            </Box>
            <Box className={classes.myProfileSubSection}>
              <Typography
                variant="h6"
                className={classes.myProfileSubSectionHeading}
              >
                Information
              </Typography>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "24px",
                }}
              >
                <Box className={classes.myProfileInformationInputRowBox}>
                  <FormControl style={{ flex: 1 }}>
                    <InputLabel shrink htmlFor="phone">
                      Mobile Number
                    </InputLabel>
                    <BootstrapInput
                      value={input["phone"]}
                      id="phone"
                      disabled
                    />
                  </FormControl>
                  <FormControl style={{ flex: 1 }}>
                    <InputLabel shrink htmlFor="name">
                      Full Name
                    </InputLabel>
                    <BootstrapInput
                      name="name"
                      onChange={handleInputChange}
                      value={input["name"]}
                      id="name"
                    />
                  </FormControl>
                </Box>
                <Box>
                  <FormControl style={{ flex: 1, width: "100%" }}>
                    <InputLabel shrink htmlFor="email">
                      Email Address
                    </InputLabel>
                    <BootstrapInput
                      name="email"
                      onChange={handleInputChange}
                      value={input["email"]}
                      id="email"
                    />
                  </FormControl>
                  {/* <FormControl style={{ flex: 1 }}>
                  <InputLabel shrink htmlFor="clinic-website">
                    Clinic Website
                  </InputLabel>
                  <BootstrapInput
                    disabled
                    defaultValue=""
                    id="clinic-website"
                  />
                </FormControl> */}
                </Box>
                <Box style={{ flex: 1 }}>
                  <Autocomplete
                    multiple
                    id="tags-standard"
                    options={specialities}
                    getOptionLabel={(option) => option?.type}
                    value={selectedSpecialities}
                    style={{ flex: 1 }}
                    onChange={handleSpecialitySelection}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Medical Specialities"
                        placeholder="Select medical specialities"
                      />
                    )}
                  />
                </Box>
                {/* <Box className={classes.myProfileInformationInputRowBox}>
                <FormControl style={{ flex: 1 }}>
                  <InputLabel shrink htmlFor="address">
                    Address
                  </InputLabel>
                  <BootstrapInput defaultValue="" id="address" />
                </FormControl>
              </Box> */}
              </Box>
            </Box>
          </Box>
          <Box
            style={{ display: "flex", justifyContent: "center", gap: "32px" }}
          >
            <Button
              variant="outlined"
              color="primary"
              style={{ textTransform: "none", width: "8rem", fontSize: "1rem" }}
              onClick={() => setInput(originalProfileData)}
            >
              Discard
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ textTransform: "none", width: "8rem", fontSize: "1rem" }}
              onClick={handleUpdateProfile}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    );
};

export default MyProfile;
