import React, { useContext, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import PrimaryButton from "../Commons/PrimaryButton";
import OutlinedButton from "../Commons/OutlinedButton";
import {
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  Typography,
} from "@material-ui/core";
import BootstrapInput from "../Commons/BootstrapInput";
import useStyles from "./styles";
import Rating from "@material-ui/lab/Rating";
import StarIcon from "@material-ui/icons/Star";
import { AppContext, ControlPanelContext, isEmptyObject } from "../../utility";
import {
  DOCFYN_API_DOMAIN,
  API_SECTION_API,
  API_VERSION,
  API_ENDPOINT_ENTERPRISE,
  API_ENDPOINT_REVIEWS,
  API_RESPONSE_UNAUTHORIZED_STATUS,
} from "../../constants";
import axios from "axios";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddReview = ({ getReviews }) => {
  const classes = useStyles();
  const { setViewSnackbarMain, handleRefreshToken } = useContext(AppContext);
  const { enterprise, AuthorizationToken } = useContext(ControlPanelContext);
  const [open, setOpen] = useState(false);
  const [input, setInput] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const { enterprise: currentEnterprise = {} } = enterprise || {};
  const { code: enterpriseCode = null } = currentEnterprise || {};

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setInput({});
    setOpen(false);
  };

  const handleInputChange = (e) => {
    if (errors[e.target.name]) {
      const error = { ...errors };
      delete error[e.target.name];
      setErrors(error);
    }
    setInput((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleRatingChange = (e, newValue) => {
    if (errors["rating"]) {
      const error = { ...errors };
      delete error["rating"];
      setErrors(error);
    }
    setInput((prev) => ({ ...prev, rating: Number(newValue) }));
  };

  const handleErrors = () => {
    const error = {};
    if (!input["givenByName"]) error["givenByName"] = "Field is mandatory";
    if (!input["rating"]) error["rating"] = "Field is mandatory";
    if (!input["comment"]) error["comment"] = "Field is mandatory";
    setErrors(error);
    return !isEmptyObject(error);
  };

  const handlePostReview = async () => {
    if (!enterpriseCode) return;
    const isError = handleErrors();
    if (isError) return;
    setIsLoading(true);
    const url = `${DOCFYN_API_DOMAIN}${API_SECTION_API}${API_VERSION}/${API_ENDPOINT_ENTERPRISE}${enterpriseCode}/${API_ENDPOINT_REVIEWS}`;
    const reqBody = { data: [{ ...input }] };
    try {
      const response = await axios.post(url, reqBody, {
        headers: {
          "Content-Type": "application/json",
          source: "website",
          Authorization: AuthorizationToken,
        },
      });
      const { status = null } = response || {};
      if (status >= 200 && status < 300) {
        setViewSnackbarMain({
          message: "Review Posted Successfully",
          type: "success",
        });
        handleClose();
        getReviews();
      }
    } catch (error) {
      const { response } = error || {};
      const { status } = response || {};
      if (status === API_RESPONSE_UNAUTHORIZED_STATUS) {
        handleRefreshToken();
      } else
        setViewSnackbarMain({
          message: "Something went wrong!",
          type: "error",
        });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <PrimaryButton onClick={handleClickOpen}>Add review</PrimaryButton>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        classes={{ paper: classes.postReviewDialog }}
      >
        <DialogTitle
          id="alert-dialog-slide-title"
          style={{ fontSize: "1.4rem" }}
        >
          Post Review
        </DialogTitle>
        <DialogContent>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "20px" }}
          >
            <FormControl>
              <Rating
                name="read-only"
                precision={1}
                max={5}
                icon={<StarIcon style={{ fontSize: "1.5rem" }} />}
                value={Number(input["rating"])}
                onChange={(e, newValue) => handleRatingChange(e, newValue)}
              />
              <FormHelperText error={errors["rating"]}>
                {errors["rating"]}
              </FormHelperText>
            </FormControl>
            <FormControl>
              <InputLabel shrink htmlFor="givenByName">
                Patient Name
              </InputLabel>
              <BootstrapInput
                name="givenByName"
                id="givenByName"
                value={input["givenByName"]}
                onChange={handleInputChange}
              />
              <FormHelperText error={errors["givenByName"]}>
                {errors["givenByName"]}
              </FormHelperText>
            </FormControl>
            <FormControl>
              <InputLabel shrink htmlFor="comment">
                Comment
              </InputLabel>
              <BootstrapInput
                name="comment"
                id="comment"
                multiline
                minRows={4}
                maxRows={8}
                value={input["comment"]}
                onChange={handleInputChange}
              />
              <FormHelperText error={errors["comment"]}>
                {errors["comment"]}
              </FormHelperText>
            </FormControl>
            {/* <div
              style={{ display: "flex", flexDirection: "column", gap: "16px" }}
            >
              <Rating
                name="read-only"
                precision={1}
                max={5}
                icon={<StarIcon style={{ fontSize: "1.5rem" }} />}
                value={Number(input["rating"])}
                onChange={(e, newValue) =>
                  setInput((prev) => ({ ...prev, rating: Number(newValue) }))
                }
              />
              <FormControl>
                <InputLabel shrink htmlFor="comment">
                  Review
                </InputLabel>
                <BootstrapInput
                  name="comment"
                  id="comment"
                  multiline
                  minRows={4}
                  maxRows={8}
                  value={input["comment"]}
                  onChange={handleInputChange}
                />
                <FormHelperText></FormHelperText>
              </FormControl>
            </div> */}
          </div>
        </DialogContent>
        <DialogActions>
          <OutlinedButton onClick={handleClose}>Cancel</OutlinedButton>
          <PrimaryButton disabled={isLoading} onClick={handlePostReview}>
            {isLoading ? <CircularProgress size={24} /> : "Post"}
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddReview;
